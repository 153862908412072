import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// constants
import { callAPIConstants } from '../../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../../common/service/common.service';
import { ShowErrorService } from 'src/app/common/service/show-error.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './add-edit-faq.component.html',
  styleUrls: ['./add-edit-faq.component.scss']
})
export class EditCategoryComponent implements OnInit {

  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public submitted = false;
  faqId: any;
  faqCategoryList: any;
  saveFaqsFormData: any = {};
  showSkeletonLoader = false;

  ngOnInit(): void { }

  constructor(public commonService: CommonService, public router: Router, public showErrorService: ShowErrorService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params) => { this.faqId = params.id; });
    this.getFaqsCategoryList();
    if (this.faqId) { this.getFaqsDetails(); }
  }


  /*********************************************************************************************
  @Purpose     : Get faq category list
  @Parameter   : NA
  @Return      : NA
  /*********************************************************************************************/
  getFaqsCategoryList() {
    this.showSkeletonLoader = true;
    this.commonService.callApi(this.callAPIConstants.FaqsCategoriesSearchList, '', 'post', false, false, false).then((success) => {
      if (success.status === 1) {
        this.faqCategoryList = success.data;
        this.showSkeletonLoader = false;
      }
    });
  }
  /*********************************************************************************************/


  /*********************************************************************************************
  @Purpose     : Get faq details
  @Parameter   : NA
  @Return      : NA
  /*********************************************************************************************/
  getFaqsDetails() {
    this.commonService.callApi(this.callAPIConstants.FaqsDetails + this.faqId, '', 'get', false, false, false).then((success) => {
      if (success.status === 1) {
        this.saveFaqsFormData = success.data;
        this.saveFaqsFormData.faqId = success.data._id;
      }
    });
  }
  /*********************************************************************************************/


  /*********************************************************************************************
  @Purpose     : add/update faq
  @Parameter   : form, saveFaqsFormData
  @Return      : NA
  /*********************************************************************************************/
  saveFaqs(form, saveFaqsFormData): void {
    this.submitted = true;
    if (form.valid) {
      let requestData: any = {
        faqQuestion: saveFaqsFormData.faqQuestion,
        faqAnswer: saveFaqsFormData.faqAnswer,
        faqCategoryName: saveFaqsFormData.faqCategoryName,
      };
      if (this.faqId) { requestData.faqId = this.saveFaqsFormData.faqId; }
      this.commonService.callApi(this.callAPIConstants.AddUpdateFaqs, requestData, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.FAQS]);
        } else { this.showErrorService.popToast('error', success.message); }
      });
    }
  }
  /*********************************************************************************************/
}
