<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
  <div class="card animated fadeIn">
    <div class="card-header text-left">
      <div class="card-title">
        <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="card-body">
      <div class="container-fluid">
        <div class="form-group row">
          <div class="col-xl-2 col-sm-4">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-xl-10 co-lg-12">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-2 col-lg-4">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-xl-10 co-lg-12">
            <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-2 col-lg-4">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-xl-10 co-lg-12">
            <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card animated fadeIn">
    <div class="card-header text-left">
      <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
    </div>
    <div class="card-body">
      <div class="container-fluid">
        <div class="form-group row">
          <div class="col-lg-2 col-sm-3">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-xl-10 co-lg-12">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-2 col-lg-3">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-xl-10 co-lg-12">
            <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-8"></div>
          <div class="col-md-2">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-md-2">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END : Show Skeleton Loader -->
<div class="main-content-area" [hidden]="commonService.showSkeletonLoader">
  <div class="main-content-block">
    <!-- <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="content-header-title">Product</li>
        <li class="breadcrumb-item">
          <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{this.Productvalue}}
        </li>
      </ol>
    </nav> -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">

        <li class="content-header-title">Product</li>
        <li class="breadcrumb-item">
          <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
        </li>
        <li class="breadcrumb-item" [routerLink]="URLConstants.PRODUCT" aria-current="page">
          Product List
        </li>
        <li class="breadcrumb-item active">Add Product</li>
      </ol>
    </nav>
    <div class="card notification-card heading-card p-0" [hidden]="commonService.showSkeletonLoader">
      <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
        <div class="icon d-flex align-items-center justify-content-center mr-2"><em class='bx bx-mail-send'></em></div>
        <div class="text">
          <h5 class="mb-0 text Uppercase">{{this.Productvalue}} </h5>
        </div>
      </div>

      <form class="heading-card-body" [formGroup]="productForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-3 col-xl-2">
            <div class="form-group">
              <label for="name">Product Image </label>
              <div class="upload-btn-wrapper">
                <div class="profile-upload" *ngIf="!this.productId">
                  <img [src]="this.ImageURL? this.ImageURL:'../../../assets/images/default-upload.png'"
                    class="default-img" alt="Product img" />
                </div>
                <div class="profile-upload" *ngIf="this.productId">
                  <img [src]="this.ImageURL ? this.ImageURL : this.Image ? this.Image : '../../../assets/images/default-upload.png'"
                    class="default-img" alt="Product img" />
                </div>
                <button class="btn">Upload a Image</button>
                <input formControlName="image" id="image" type="file" name="image"
                  class="form-control" (change)="fileChangeEvent($event,template)">
                <!-- <input type="file" name="myfile" /> -->
                <!-- <div class="error-block"
                  *ngIf="(productForm.controls.image.touched || submitted) && productForm.controls.image.errors?.required">
                  Please upload image
                </div> -->
              </div>
            </div>
          </div>

          <div class="col-lg-9 col-xl-5">
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="">Product Name <sup class="bx bxs-star danger"></sup></label>
                <input type="text" class="form-control" formControlName="name" name="name" maxlength="30" required
                  id="name" placeholder="Enter product name">
                <div class="error-block"
                  *ngIf="(productForm.controls.name.touched || submitted) && productForm.controls.name.errors?.required">
                  Please enter product name
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="">Product Description</label>
                <input type="text" class="form-control" name="description" id="description"
                  formControlName="description"  maxlength="75" placeholder="Enter product description">
                <!-- <div
                  *ngIf="(productForm.get('description').touched || submitted) && productForm.get('description').errors?.required"
                  class="error-block">
                  Please enter product description
                </div> -->
                <!-- </div> -->
              </div>
              <div class="col-md-6 form-group">
                <label for="">Product Price in CAD<sup class="bx bxs-star danger"></sup></label>
                <input type="text" class="form-control" name="price" id="price" formControlName="price" required
                  placeholder="Enter price in CAD" appTwoDigitDecimaNumber
                  oninput="this.value = this.value.replace(/^[0]*/, '')">
                <div class="error-block"
                  *ngIf="(productForm.controls.price.touched || submitted) && productForm.controls.price.errors?.required">
                  Please enter price in CAD
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="">Discount Percentage</label>
                <input type="text" class="form-control" formControlName="discountPercentage" name="discountPercentage"
                  id="discountPercentage"  placeholder="Enter discount percentage"
                  (keydown)="restrictKeyPressService.AllowNumbers($event)" maxlength="2"
                  oninput="this.value = this.value.replace(/^[0]*/, '')">
                <!-- <div class="error-block"
                  *ngIf="(productForm.controls.discountPercentage.touched || submitted) && productForm.controls.discountPercentage.errors?.required">
                  Please enter discountPercentage
                </div> -->
              </div>

            </div>
          </div>

          <div class="col-lg-12 col-xl-5">
            <div class="row ">
              <div class="col-md-6 form-group">
                <label for="category">Category<sup class="bx bxs-star danger"></sup></label>
                <ng-select #selectCategory [items]="categoryList" bindLabel="name" bindValue="_id" [searchable]='true'
                  labelForId="category" [multiple]="false" [clearable]='false' name="categoryId"
                  placeholder="Select category" (change)="valuecategory($event)" formControlName="categoryId" required>
                </ng-select>
                <div class="error-block"
                  *ngIf="(productForm.controls.categoryId.touched || submitted) && productForm.controls.categoryId.errors?.required">
                  Please enter category
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="cuisine">Cuisine<sup class="bx bxs-star danger"></sup></label>
                <ng-select [items]="cuisineList" bindLabel="name" bindValue="_id" [searchable]='true' [multiple]="false"
                  labelForId="cuisine" [clearable]='false' name="cuisineId" placeholder="Select Cuisine"
                  formControlName="cuisineId" (change)="valuecusine($event)" (keyup.enter)="searchData($event)" required
                  placeholder="Select categoty">
                </ng-select>
                <div class="error-block"
                  *ngIf="(productForm.controls.cuisineId.touched || submitted) && productForm.controls.cuisineId.errors?.required">
                  Please enter cuisine
                </div>
              </div>
              <div class="col-md-6 form-group">
                <fieldset>
                  <div class="d-flex flex-column custom-radio custom-radio">
                    <label class="pl-0">Cuisine Preference <sup class="bx bxs-star danger"></sup></label>
                    <label for="treatMode">
                      <input class="form-check-input" #TreatMode (click)="displayed('Treat Mode')" type="radio"
                        formControlName="cuisinePreference" name="cuisinePreference" id="treatMode" value="Treat Mode"
                        autocomplete="off">
                      <span></span>Treat Mode
                    </label>
                    <label for="fitMode">
                      <input class="form-check-input" #FitMode (click)="displayed('Fit Mode')" type="radio"
                        formControlName="cuisinePreference" name="cuisinePreference" id="fitMode" value="Fit Mode"
                        autocomplete="off">
                      <span></span>Fit Mode
                    </label>
                    <div class="error-block"
                      *ngIf="(productForm.controls.cuisinePreference.touched || submitted) && productForm.controls.cuisinePreference.errors?.required">
                      Please enter cuisine preference
                    </div>
                  </div>

                </fieldset>
              </div>


              <div class="col-md-6 form-group">
                <fieldset>
                  <div class="d-flex flex-column custom-radio custom-radio">
                    <label class="pl-0">Is Virtual Drive Through Product?<sup class="bx bxs-star danger"></sup></label>
                    <label for="Yes">
                      <input class="form-check-input" #Yes type="radio" [(ngModel)]="selectedVirtualDrive"
                        formControlName="virtualDrivePreference" name="virtualDrivePreference" id="Yes" value="Yes"
                        autocomplete="off">
                      <span></span>Yes
                    </label>
                    <label for="No">
                      <input class="form-check-input" #No type="radio" [(ngModel)]="selectedVirtualDrive"
                        formControlName="virtualDrivePreference" name="virtualDrivePreference" id="No" value="No"
                        autocomplete="off">
                      <span></span>No
                    </label>
                    <div class="error-block"
                      *ngIf="(productForm.controls.virtualDrivePreference.touched || submitted) && productForm.controls.virtualDrivePreference.errors?.required">
                      Please enter cuisine preference
                    </div>
                  </div>

                </fieldset>
              </div>


            </div>
          </div>
        </div>

        <div *ngIf="(this.display || this.cuis) === 'Fit Mode'" class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-6 form-group">
              <label for="">Calories</label>
              <input type="text" class="form-control" formControlName="calories" name="calories" id="calories"
                (keydown)="restrictKeyPressService.AllowNumbers($event)" required placeholder="Enter calories">
              <div class="error-block"
                *ngIf="(productForm.controls.calories.touched || submitted) && productForm.controls.calories.errors?.required">
                Please enter calories
              </div>
            </div>
            <div class="col-md-6 form-group">
              <label for="">Protein</label>
              <input type="number" class="form-control" formControlName="protein" name="protein" id="protein"
                pattern="^\d*\.?\d{0,2}$" required placeholder="Enter protein">
              <div class="error-block"
                *ngIf="(productForm.controls.protein.touched || submitted) && productForm.controls.protein.errors?.required">
                Please enter protein
              </div>
            </div>
            <div class="col-md-6 form-group">
              <label for="">Fat</label>
              <input type="number" class="form-control" formControlName="fat" name="fat" id="fat"
                pattern="^\d*\.?\d{0,2}$" required placeholder="Enter fat">
              <div class="error-block"
                *ngIf="(productForm.controls.fat.touched || submitted) && productForm.controls.fat.errors?.required">
                Please enter fat
              </div>
            </div>
            <div class="col-md-6 form-group">
              <label for="">Carbohydrate</label>
              <input type="number" class="form-control" formControlName="carbohydrate" name="carbohydrate"
                id="carbohydrate" pattern="^\d*\.?\d{0,2}$" required placeholder="Enter carbohydrate">
              <div class="error-block"
                *ngIf="(productForm.controls.carbohydrate.touched || submitted) && productForm.controls.carbohydrate.errors?.required">
                Please enter carbohydrate
              </div>
            </div>

            <div class="col-md">
              <div class="custom-checkbox">
                <label>
                  <input type="checkbox" formControlName="isVegan">
                  <span></span>
                  Vegan Food
                </label>
              </div>
            </div>
          </div>

        </div>

        <div class="row" *ngIf="isPizzaRestaurant === 'false'">
          <div *ngIf="(this.display || this.cuis) === 'Treat Mode' || (this.display || this.cuis) === 'Fit Mode'"
            class="col-md-12 mt-4">

            <div class="table-responsive mb-4">
              <table aria-describedby="tableContent" class="table table-bordered" formArrayName="variant">
                <tr>
                  <th id="tableTH" colspan="2">Add Variant</th>
                  <th id="tableTH" width="200px" class="text-right"><button type="button" (click)="addvariant()"
                      class="btn btn-primary">Add More</button></th>
                </tr>
                <tr *ngFor="let quantity of variant().controls; let i=index" [formGroupName]="i">

                  <td>
                    <div class="form-group mb-0">
                      <label for="enter-name">Variant Name</label>
                      <input type="text" formControlName="name" class="form-control" id="enter-name"
                        placeholder="Enter name">
                        <div class="error-block" *ngIf="isSubmitted &&  quantity.controls['name'].hasError('required')">
                          Please enter name
                        </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <label for="enter-price">Price in CAD</label>
                      <input type="text" formControlName="price" class="form-control" id="enter-price"
                        placeholder="Enter price">

                        <div class="error-block" *ngIf="isSubmitted &&  quantity.controls['price'].hasError('required')">
                          Please enter price
                        </div>
                    </div>
                  </td>
                  <td class="text-right">
                    <button (click)="removevariant(i)" class="btn btn-outline-primary">Remove</button>
                  </td>
                </tr>

              </table>
            </div>
            <div class="row">

              <div class="col-md">
                <table aria-describedby="tableContent" class="table table-bordered" formArrayName="attribute">
                  <tr>
                    <th id="tableTH" colspan="3">Add Attribute</th>
                    <th id="tableTH" width="200px" class="text-right"><button type="button" (click)="addAttributing(temp)"
                        class="btn btn-primary">Add More</button></th>
                  </tr>
                  <tr *ngFor="let quantity of attribute().controls; let i=index" [formGroupName]="i">
                    <td class="text-center">
                      <label hidden for="">check: </label> 
                      <!-- <input type="checkbox" class="btx btx-check" formControlName="check"> -->
                      <div class="custom-checkbox">
                        <label> 
                          <input type="checkbox" class="btx btx-check" (change)="funct(quantity)" formControlName="check">
                          <span></span>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div class="form-group mb-0">
                        <label for="">Attribute Name</label>
                        <input type="text" formControlName="name" class="form-control" placeholder="Enter name">
                      </div>
                    </td>
                    <td>
                      <div class="form-group mb-0">
                        <label for="">Price in CAD</label>
                        <input type="text" formControlName="price" class="form-control" placeholder="Enter price">
                      </div>
                      <div class="error-block" *ngIf="quantity.value.price == '' && quantity.value.check">
                        Please enter price
                      </div>
                      
                    </td>
                    <td class="text-right">
                      <button (click)="removeattribute(i)" class="btn btn-outline-primary">Remove</button>
                    </td>
                  </tr>
                  <!-- <tr>
                    <div class="error-block" *ngIf="isAttribute">
                      Please Enter Price
                    </div>
                  </tr> -->
                </table>
  
              </div>

            </div>
          </div>
          <!-- <div *ngIf="(this.display || this.cuis) === 'Fit Mode'" class="col-md-12 mt-4">
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="">Calories</label>
                <input type="text" class="form-control" formControlName="calories" name="calories" id="calories"
                  (keydown)="restrictKeyPressService.AllowNumbers($event)" required placeholder="Enter calories">
                <div class="error-block"
                  *ngIf="(productForm.controls.calories.touched || submitted) && productForm.controls.calories.errors?.required">
                  Please enter calories
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="">Protein</label>
                <input type="number" class="form-control" formControlName="protein" name="protein" id="protein"
                  pattern="^\d*\.?\d{0,2}$" required placeholder="Enter protein">
                <div class="error-block"
                  *ngIf="(productForm.controls.protein.touched || submitted) && productForm.controls.protein.errors?.required">
                  Please enter protein
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="">Fat</label>
                <input type="number" class="form-control" formControlName="fat" name="fat" id="fat"
                  pattern="^\d*\.?\d{0,2}$" required placeholder="Enter fat">
                <div class="error-block"
                  *ngIf="(productForm.controls.fat.touched || submitted) && productForm.controls.fat.errors?.required">
                  Please enter fat
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="">Carbohydrate</label>
                <input type="number" class="form-control" formControlName="carbohydrate" name="carbohydrate"
                  id="carbohydrate" pattern="^\d*\.?\d{0,2}$" required placeholder="Enter carbohydrate">
                <div class="error-block"
                  *ngIf="(productForm.controls.carbohydrate.touched || submitted) && productForm.controls.carbohydrate.errors?.required">
                  Please enter carbohydrate
                </div>
              </div>

              <div class="col-md">
                <div class="custom-checkbox">
                  <label>
                    <input type="checkbox" formControlName="isVegan">
                    <span></span>
                    Vegan Food
                  </label>
                </div>
              </div>
            </div>

          </div> -->
        </div>




<!-- -------------------------------- -->


        <div class="row" *ngIf="isPizzaRestaurant === 'true'">
          <div *ngIf="(this.display || this.cuis) === 'Treat Mode' || (this.display || this.cuis) === 'Fit Mode'"
            class="col-md-12 mt-4">

            <div class="table-responsive mb-4">
              <table aria-describedby="tableContent" class="table table-bordered" formArrayName="variant">
                <tr>
                  <th id="tableTH" colspan="3">Add Variant</th>
                  <!-- <th id="tableTH" width="353px" class="text-center"><button type="button" (click)="addvariant()"
                      class="btn btn-primary">Add
                      More</button></th> -->
                </tr>
                <tr *ngFor="let quantity of variant().controls; let i=index" [formGroupName]="i">

                  <td>
                    <div class="form-group mb-0">
                      <label for="enter-name">Variant Name</label>
                      <!-- <input type="text" formControlName="name" class="form-control" id="enter-name"
                        placeholder="Enter name"> -->
                        <p>{{quantity.controls.name.value}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <label for="enter-price">Price in CAD</label>
                      <input type="text" formControlName="price" class="form-control" id="price"
                        placeholder="Enter price">
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <label for="enter-price">Serving Persons</label>
                      <input type="text" formControlName="servingPerson" class="form-control" id="servingPerson"
                        placeholder="Enter Serving Persons">
                    </div>
                  </td>
                  <!-- <td class="text-center">
                    <button (click)="removevariant(i)" class="btn btn-outline-primary">Remove</button>
                  </td> -->
                </tr>

              </table>
            </div>
            <div class="row">

              <div class="col-md">
                <table aria-describedby="tableContent" class="table table-bordered" formArrayName="attribute">
                  <tr>
                    <th id="tableTH" colspan="5">Add Attribute</th>
                    <!-- <th id="tableTH" colspan="2" class="text-center"><button type="button" (click)="addAttributing(temp)"
                        class="btn btn-primary">Add
                        More</button></th> -->
                  </tr>
                  <tr *ngFor="let quantity of attribute().controls; let j=index" [formGroupName]="j">
                    <td class="text-center">
                      <label hidden for="">check: </label>
                      <!-- <input type="checkbox" class="btx btx-check" formControlName="check"> -->
                      <div class="custom-checkbox">
                        <label>
                          <input type="checkbox" class="btx btx-check" formControlName="check">
                          <span></span>
                        </label>
                      </div>
                    </td>
                    <td width="140px" class="text-center">
                      <div class="form-group mb-0">
                        <!-- <label for="">Attribute Image</label> -->
                        <!-- <input type="text" formControlName="name" class="form-control" placeholder="Enter name"> -->
                        <img [src]="quantity.controls.image.value ? uploadImgUrl +  quantity.controls.image.value : 'assets/images/default-upload.png'" class="obeject-cover" width="85" height="85" alt="Product img"/>
                      </div>
                    </td>
                    <td>
                      <div class="form-group mb-0">
                        <label for="">Product Name</label>
                        <!-- <input type="text" formControlName="name" class="form-control" placeholder="Enter name"> -->
                        <p>{{quantity.controls.name.value}}</p> 
                      </div>
                    </td>
                    <td>
                      <div class="form-group mb-0">
                        <label for="">Price in CAD</label>
                        <input type="text" formControlName="price" class="form-control" placeholder="Enter price">
                      </div>
                    </td>
                    <!-- <td class="text-center">
                      <button (click)="removeattribute(j)" class="btn btn-outline-primary">Remove</button>
                    </td> -->
                  </tr>
                  <!-- <tr>
                    <div class="error-block" *ngIf="isAttribute">
                      Please Enter Price
                    </div>
                  </tr> -->
                </table>
                <div class="error-block" *ngIf="isAttribute">
                  Please enter price
                </div>
              </div>

            </div>
          </div>
          <!-- <div *ngIf="(this.display || this.cuis) === 'Fit Mode'" class="col-md-12 mt-4">
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="">Calories</label>
                <input type="text" class="form-control" formControlName="calories" name="calories" id="calories"
                  (keydown)="restrictKeyPressService.AllowNumbers($event)" required placeholder="Enter calories">
                <div class="error-block"
                  *ngIf="(productForm.controls.calories.touched || submitted) && productForm.controls.calories.errors?.required">
                  Please enter calories
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="">Protein</label>
                <input type="number" class="form-control" formControlName="protein" name="protein" id="protein"
                  pattern="^\d*\.?\d{0,2}$" required placeholder="Enter protein">
                <div class="error-block"
                  *ngIf="(productForm.controls.protein.touched || submitted) && productForm.controls.protein.errors?.required">
                  Please enter protein
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="">Fat</label>
                <input type="number" class="form-control" formControlName="fat" name="fat" id="fat"
                  pattern="^\d*\.?\d{0,2}$" required placeholder="Enter fat">
                <div class="error-block"
                  *ngIf="(productForm.controls.fat.touched || submitted) && productForm.controls.fat.errors?.required">
                  Please enter fat
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="">Carbohydrate</label>
                <input type="number" class="form-control" formControlName="carbohydrate" name="carbohydrate"
                  id="carbohydrate" pattern="^\d*\.?\d{0,2}$" required placeholder="Enter carbohydrate">
                <div class="error-block"
                  *ngIf="(productForm.controls.carbohydrate.touched || submitted) && productForm.controls.carbohydrate.errors?.required">
                  Please enter carbohydrate
                </div>
              </div>

              <div class="col-md">
                <div class="custom-checkbox">
                  <label>
                    <input type="checkbox" formControlName="isVegan">
                    <span></span>
                    Vegan Food
                  </label>
                </div>
              </div>
            </div>

          </div> -->
        </div>

        <div class="text-right submit-btn-wrap">
          <button type="submit" class="btn btn-primary mr-2">Submit</button>
          <button type="button" class="btn btn-outline-primary" (click)="resetform()">Cancel</button>

        </div>
      </form>
    </div>

  </div>
</div>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title">
      Crop
      <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
        <em class="la la-close"></em>
      </a>
    </h4>

  </div>
  <div class="modal-body">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
      [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #temp class="modal-dialog-centered">
  <div class="modal-header">
    <h4 class="modal-title">
      Add New Attribute
      <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
        <em class="la la-close"></em>
      </a>
    </h4>

  </div>
  <div class="modal-body">
    <form [formGroup]="myForm" (ngSubmit)="saveAttributeForm(myForm)" novalidate>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="name">Name <sup class="bx bxs-star danger"></sup></label>
            <input formControlName="name" [ngClass]="((submitted || f.name.touched) && f.name.invalid)?'has-danger':''"
              id="name" type="text" class="form-control" required>
            <div *ngIf="(submitted || f.name.touched) && f.name.invalid" class="error-blockr has-danger">
              <div *ngIf="submitted || f.name.errors.required">Name is required.</div>
              <div *ngIf="f.name.errors.minlength">Name should be 3 character.</div>
            </div>
          </div>
        </div>
      </div>


      <div class="text-right submit-btn-wrap">
        <button type="submit" class="btn btn-primary mr-2">Submit</button>
        <button type="button" class="btn btn-outline-primary" (click)="bsModalRef.hide();">Cancel</button>
      </div>
    </form>
  </div>

</ng-template>