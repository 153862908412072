<!-- BEGIN: Sub Header  -->
<!-- <app-sub-header [subHeaderData]="subHeaderData"></app-sub-header> -->
<!-- END: Sub Header  -->
<div class="main-content-area">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">Manage Roles</li>
                <li class="breadcrumb-item">
                    <a [routerLink]=""><em class="bx bx-home-alt"></em></a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Roles & Permissions</li>
            </ol>
        </nav>
        <!-- filterbar buttons -->

        <!-- filter bar buttons end -->
        <div class="m-content animated fadeIn">
            <!-- BEGIN: Responsive Data Table  -->
            <app-responsive-data-table [tableSetupData]="tableSetupData" [accessPermission]="accessPermission" #responsiveDataTable>
            </app-responsive-data-table>
            <!-- END: Responsive Data Table  -->
        </div>
    </div>
</div>