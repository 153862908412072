import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// service
import { ThemeService } from 'src/app/common/service/theme/theme.service';
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { ShowErrorService } from '../../common/service/show-error.service';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: []
})
export class ForgotPasswordComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  public errorMsg = '';
  forgotPassword: any = {};




  constructor(private themeService: ThemeService,
    private router: Router, private localStorageService: LocalStorageService, private commonService: CommonService,
    private showErrorService: ShowErrorService
  ) { }

  ngOnInit(): void {
    const isDark = this.localStorageService.getToken('theme') === 'Dark' ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark) { document.getElementById('forgotPassSwitchTheme').setAttribute('checked', ''); }
  }

  onForgotPassword(form, forgotPassword) {

    if (form.valid) {
      const data = { emailId: forgotPassword.email };
      let getMethod: any;
      if (forgotPassword.type === 'vendor') {
        getMethod = this.callAPIConstants.ForgotPasswordVendor;
      } else {
        getMethod = this.callAPIConstants.ForgotPassword;
      }
      this.commonService.callApi(getMethod, data, 'post', true, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.RESET_PASS], { queryParams: { email: forgotPassword.email, type: forgotPassword.type } });
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }

  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }
}
