import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { retry } from 'rxjs/operators';

// interfaces
import { IBreadcrumbs } from 'src/app/common/interfaces/interfaces';

// constatns
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../../common/service/common.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/show-error.service';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styles: [],
})
export class EditDetailsComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public roleId: any;
  public role: any = [];
  public roleValues: any = [];
  public roleNames: any = [];
  public roleIDArr: any = {};
  public rolePermission: any = [];
  public categoryNames: any = { cmsPagesAccess: 'Content', emailTemplateAccess: 'Email Templates', userAccess: 'User' };
  public arr = [];
  public addOrEditRole = {
    role: "",
    permissions: []
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    public showErrorService: ShowErrorService,
    public restrictKeyPressService: RestrictKeyPressService,
    private router: Router) {
    this.activatedRoute.params.subscribe((params) => {
      this.roleId = params.id;
    });
  }

  ngOnInit() {
    this.setBreadcrumbs();
    if (this.roleId) {
      this.getRoleDetail();
    } else {
      this.getPermission();
    }

  }
  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    const title = this.roleId ? 'Edit Role' : 'Add Role';
    this.breadcrumbs = [
      { localeKey: 'Manage Roles', url: this.URLConstants.MANAGE_ROLES },
      { localeKey: title, url: null },
    ];
    this.subHeaderData = {
      title,
      breadcrumbs: this.breadcrumbs,
    };
  }
  ///************************************************************* */
  getCategory() {
    let Ids: [];
    this.commonService.callApi(this.callAPIConstants.Role_Category, null, 'get', false, true, false).then((responseData) => {

      this.roleIDArr = responseData.data;
      // this.roleIDArr.forEach(element => {
      //   Ids=element._id;
      // });
      let arr = [];
      Object.keys(this.roleIDArr).map(function (key) {
        arr.push({ [key]: responseData[key] })
        return arr;
      });
      //  
      //

    });
  }

  public tableData = []
  getPermission() {
    this.commonService.callApi(this.callAPIConstants.Role_Permission, null, 'get', false, true, false).then((responseData) => {

      this.tableData = responseData.data;
    });
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Get Role Details
  @Parameter   : NA
  @Return      : role
  /*************************************************************/
  getRoleDetail() {
    this.commonService.callApi(this.callAPIConstants.GetRoleDetail + this.roleId, '', 'get', false, false).then((success) => {

      this.addOrEditRole.role = success.data.role;
      this.tableData = success.data.categoryPermissions;
      // this.role = success.data;
      //  this.role.categoryPermissions.forEach(cat => {
      //   cat.permissions.forEach(per => {
      //     this.addOrEditRole.permissions.push(per._id);

      //   });
      // });
      // 

      // Object.entries(this.categoryNames).forEach((element) => {
      //   let temp = success.data.permissions.filter(obj => obj['category'] === element[0]);
      //   this.rolePermissions.push(temp[0]);
      // });
      // Object.entries(this.roleNames).forEach((role) => {
      //   let value = success.data.permissions.every(obj => obj[''+role[1]] === true);
      //   this.roleValues[''+role[1]] = value;
      // });
    });
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Create or Update Role
  @Parameter   : form, role
  @Return      : NA
  /*************************************************************/
  public submitted = false;
  public rolePermissions: any = [];
  createUpdateRole(form) {


    if (form.valid) {
      if (this.addOrEditRole.permissions.length > 0) {
        this.commonService.callApi(this.callAPIConstants.AddUpdateRole, this.addOrEditRole, 'post', false, false).then((success) => {
          success.status === 1 ? this.showErrorService.popToast('success', 'Successfully stored!') :
            this.showErrorService.popToast('error', 'Something Went wrong!');
          this.router.navigate([this.URLConstants.MANAGE_ROLES]);
        });
      } else {
        this.showErrorService.popToast('error', 'Give atleast 1 permission');
      }
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Multiple Selection while giving permissions
  @Parameter  : data
  @Return     : NA
  /*************************************************************/
  multipleSelection(role, isSelected) {
    this.roleValues[role] = isSelected;
    if (this.rolePermissions.length) {
      this.rolePermissions.forEach(obj => {
        obj[role] = isSelected;
        obj['read'] = true;
      });
    } else {
      Object.entries(this.categoryNames).forEach((element) =>
        this.rolePermissions.push({
          category: element[0],
          read: true,
          edit: role === 'edit' ? true : false,
          create: role === 'create' ? true : false,
          delete: role === 'delete' ? true : false,
        })
      );
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Add permission to roles 
  @Parameter  : category, role, check property
  @Return     : NA
  /*************************************************************/
  public checkup;
  //not used
  checkBoxValue(cat, per) {
    this.role.categoryPermissions.forEach(element => {
      if (element.category == cat.category) {
        element.permissions.forEach(element2 => {
          if (element2._id === per._id) {
            return true;
          }
        });
      }

    });
    return false;
  }

  selectRole(per, cat, isChecked) {
    let value = isChecked ? true : false;


    if (isChecked) {
      this.addOrEditRole.permissions.push(per);
    } else {
      var index = this.addOrEditRole.permissions.indexOf(per);
      if (index !== -1) {
        this.addOrEditRole.permissions.splice(index, 1);
      }
    }





    // if(this.rolePermissions.length){
    //   //let v = this.rolePermissions.some(obj => obj.includes(category));
    //   let index =  this.rolePermissions.findIndex(e => e.category === category);
    //   if(index >= 0){
    //     this.rolePermissions[index][item] = isChecked;
    //     this.rolePermissions[index]['read'] = true;
    //   }else{
    //     this.rolePermissions.push({
    //       category: category,
    //       read: true,
    //       edit: item === 'edit' ? value : false,
    //       create: item === 'create' ? value : false,
    //       delete: item === 'delete' ? value : false,
    //     });
    //   }

    //   //remove multiple selection if anyone is unselected
    //   let checkVal = this.rolePermissions.every(obj => obj[item] === true);
    //   //
    //   this.roleValues[item] = checkVal;

    // }else{
    //   this.rolePermissions.push({
    //     category: category,
    //     read: true,
    //     edit: item === 'edit' ? value : false,
    //     create: item === 'create' ? value : false,
    //     delete: item === 'delete' ? value : false,
    //   });
    // }

    //
    // 
  }
  /*************************************************************/

}
