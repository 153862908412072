<!-- Begin: skeleton loader -->
<div class="main-content-area" [hidden]="!showSkeletonLoader">
  <div class="main-content-block">
    <ngx-skeleton-loader count="1" [theme]="{ 'width': '50%' }"></ngx-skeleton-loader>
    <div class="dashboard-card">
      <div class="row">
        <ng-container *ngIf="localStorageService.getToken('role') === 'Super Admin'">
          <div class="col-lg-6 col-xl-3 mb-4 pr-0 mb-2" *ngFor="let item of ['1', '2', '3', '4', '5']">
            <div class="card shadow-sm h-100 mb-2">
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%' }"></ngx-skeleton-loader>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="localStorageService.getToken('role') !== 'Super Admin'">
          <div class="col-lg-6 col-xl-4 mb-4 mb-xl-0 pr-0" *ngFor="let item of ['1', '2', '3']">
            <div class="card shadow-sm h-100">
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%' }"></ngx-skeleton-loader>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row mt-4" *ngIf="this.localStorageService.getToken('role') === 'Super Admin'">
        <div class="col-md-12 pr-0">
          <div class="card animated fadeIn">
            <div class="card-header">
              <div class="row">
                <ngx-skeleton-loader count="1" [theme]="{ 'width': '50%' }"></ngx-skeleton-loader>
                <div class="col-md-3 text-right">
                  <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%' }"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="card-body px-0">
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%', padding: '80px' }"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Begin: skeleton loader -->

<div class="main-content-area" [hidden]="showSkeletonLoader">
  <div class="main-content-block">
    <!-- Begin: breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="content-header-title">Dashboard</li>
        <li class="breadcrumb-item">
          <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
        </li>
        <li class="breadcrumb-item">Dashboard</li>
      </ol>
    </nav>
    <!-- End: breadcrumb -->

    <!-- Begin: Dashboard for Super Admin -->
    <ng-container *ngIf="this.localStorageService.getToken('role') === 'Super Admin'">
      <div class="dashboard-card">
        <!-- Begin: Total -->
        <div class="row">
          <div class="col-lg-6 col-xl-3 mb-4 pr-0">
            <div class="card shadow-sm h-100">
              <div class="d-flex align-item-center p-0">
                <div class="dashboard-icon">
                  <em class='bx bxs-package'></em>
                </div>
                <div class="ml-3">
                  <h5 class="card-title">Orders</h5>
                  <p class="card-text">{{Admincount.allOrderCount ? (Admincount.allOrderCount| shortNumber) : '0'}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-3 mb-4 pr-0">
            <div class="card shadow-sm h-100">
              <div class="d-flex align-item-center p-0">
                <div class="dashboard-icon">
                  <em class='bx bxs-car'></em>
                </div>
                <div class="ml-3">
                  <h5 class="card-title">Drivers</h5>
                  <p class="card-text">{{Admincount.allDriverCount ? (Admincount.allDriverCount| shortNumber) : '0'}}</p>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3 mb-4 pr-0">
            <div class="card shadow-sm h-100">
              <div class="d-flex align-item-center p-0">
                <div class="dashboard-icon">
                  <em class='bx bxs-group'></em>
                </div>
                <div class="ml-3">
                  <h5 class="card-title">Users</h5>
                  <p class="card-text">{{Admincount.allUserCount ? (Admincount.allUserCount| shortNumber) :
                    '0'}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3 mb-4 pr-0">
            <div class="card shadow-sm h-100">
              <div class="d-flex align-item-center p-0">
                <div class="dashboard-icon">
                  <em class='bx bxs-user-account'></em>
                </div>
                <div class="ml-3">
                  <h5 class="card-title">Vendors</h5>
                  <p class="card-text">{{Admincount.allVendorCount ? (Admincount.allVendorCount| shortNumber) : '0'}}
                  </p>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3 mb-4 mb-xl-0 pr-0">
            <div class="card shadow-sm h-100">
              <div class="d-flex align-item-center p-0">
                <div class="dashboard-icon">
                  <em class='bx bx-money'></em>
                </div>
                <div class="ml-3">
                  <h5 class="card-title">Revenue</h5>
                  <p class="card-text">$ {{Admincount.wallet ? (Admincount.wallet| shortNumber) : '0'}}
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- End: Total -->

        <!-- Begin: Chart for Revenue Report  -->
        <div class="row mt-4">
          <div class="col-md-12 pr-0">
            <div class="card animated fadeIn">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-9 m-auto"><strong> Report</strong></div>
                  <div class="col-md-3 text-right">
                    <ng-select class="ng-select-line" [items]="yearsArr" bindLabel="name" bindValue="value"
                      placeholder="Select Year" [clearable]='false' [searchable]='false' autocomplete="false"
                      [dropdownPosition]="'auto'" name="year" [(ngModel)]="filterData.year"
                      (change)="changeYear('revenueReport')">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="card-body px-0">
                <div id="revenueChart"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Chart for Revenue Report  -->
      </div>
    </ng-container>
    <!-- End: Dashboard for Super Admin -->

    <!-- Begin: Dashboard for Vendor -->
    <ng-container *ngIf="this.localStorageService.getToken('role') === 'vendor'">
      <div class="dashboard-card">
        <div class="row">
          <div class="col-lg-6 col-xl-4 mb-4 mb-xl-0 pr-0">
            <div class="card shadow-sm h-100">
              <div class="d-flex align-item-center p-0">
                <div class="dashboard-icon">
                  <em class='bx bxs-group'></em>
                </div>
                <div class="ml-3">
                  <h5 class="card-title">Customers</h5>
                  <p class="card-text">{{VendorCount.allCustomerCountForVendor ? (VendorCount.allCustomerCountForVendor|
                    shortNumber) : '0'}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 mb-4 mb-xl-0 pr-0">
            <div class="card shadow-sm h-100">
              <div class="d-flex align-item-center p-0">
                <div class="dashboard-icon">
                  <em class='bx bxs-package'></em>
                </div>
                <div class="ml-3">
                  <h5 class="card-title">Orders</h5>
                  <p class="card-text">{{VendorCount.allOrderCount ? (VendorCount.allOrderCount| shortNumber) : '0'}}
                  </p>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 mb-4 mb-xl-0 pr-0">
            <div class="card shadow-sm h-100">
              <div class="d-flex align-item-center p-0">
                <div class="dashboard-icon">
                  <em class='bx bx-line-chart'></em>
                </div>
                <div class="ml-3">
                  <h5 class="card-title">Revenue</h5>
                  <p class="card-text">{{VendorCount.totalRevenue ? (VendorCount.totalRevenue | currency: CUURENCY_CODE) : '0'}}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </ng-container>
    <!-- End: Dashboard for Vendor -->
  </div>
</div>