<!-- Begin: Skeleton loader -->
<div class="main-content-area" [hidden]="!showSkeletonLoader">
  <div class="main-content-block">
    <ngx-skeleton-loader count="1" [theme]="{ 'width': '50%' }"></ngx-skeleton-loader>
    <div class="card notification-card tabs-block p-0">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%', padding: '20px' }"></ngx-skeleton-loader>
          </div>
          <div class="col-md-6">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%', padding: '20px' }"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%', padding: '20px' }"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="btn-blocks text-right justify-content-end mb-md-0 mb-2">
          <ngx-skeleton-loader count="1" [theme]="{ 'width': '20%', padding: '20px' }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End: Skeleton loader -->

<div class="main-content-area" [hidden]="showSkeletonLoader">
  <div class="main-content-block">
    <!-- Begin: breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="content-header-title">{{router.url.includes(URLConstants.ADD_FAQ) ? 'Add FAQ' :
          'Edit FAQ'}}</li>
        <li class="breadcrumb-item">
          <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
        </li>
        <li class="breadcrumb-item" [routerLink]="[URLConstants.FAQS]">Contents</li>
        <li class="breadcrumb-item active">
          <a [routerLink]="[URLConstants.FAQS]" href="javascript:;">FAQ's</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{router.url.includes(URLConstants.ADD_FAQ) ? 'Add FAQ' : 'Edit FAQ'}}
        </li>
      </ol>
    </nav>
    <!-- End: breadcrumb -->

    <!-- Begin: Add/update FAQ -->
    <div class="card notification-card tabs-block p-0">
      <form (ngSubmit)="saveFaqs(saveFaqsForm, saveFaqsFormData)" #saveFaqsForm="ngForm">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group align-items-center mb-md-5 mb-3 user-details">
                <label>
                  Question <sup class="bx bxs-star danger"></sup></label>
                <input class="form-control" type="text" name="faqQuestion" [(ngModel)]="saveFaqsFormData.faqQuestion"
                  placeholder="Enter Question" id="faqQuestion" #faqQuestion="ngModel" autocomplete="on" required />
                <div *ngIf="((submitted || faqQuestion.touched || faqQuestion.dirty) && faqQuestion.invalid)"
                  class="error-block">
                  {{showErrorService.showError('question', faqQuestion.errors)}}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group align-items-center mb-md-5 mb-3 user-details">
                <label>
                  Answer <sup class="bx bxs-star danger"></sup></label>
                <input class="form-control" type="text" name="instagramUrl" [(ngModel)]="saveFaqsFormData.faqAnswer"
                  placeholder="Enter Answer" id="faqAnswer" #faqAnswer="ngModel" autocomplete="on" required />
                <div *ngIf="((submitted || faqAnswer.touched || faqAnswer.dirty) && faqAnswer.invalid)"
                  class="error-block">
                  {{showErrorService.showError('answer', faqAnswer.errors)}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group align-items-center mb-md-5 mb-3 user-details">
                <label>
                  FAQ Category <sup class="bx bxs-star danger"></sup></label>
                <ng-select [multiple]="false" [clearable]="false" [items]="faqCategoryList" name="faqCategoryName"
                  placeholder="Select FAQ Category" bindLabel="faqCategoryName" bindValue="faqCategoryName"
                  #faqCategoryName="ngModel" [(ngModel)]="saveFaqsFormData.faqCategoryName" required>
                </ng-select>
                <div
                  *ngIf="((submitted || faqCategoryName.touched || faqCategoryName.dirty) && faqCategoryName.invalid)"
                  class="error-block">
                  {{showErrorService.showError('category', faqCategoryName.errors)}}
                </div>
              </div>
            </div>
          </div>
          <div class="btn-blocks d-flex align-items-center justify-content-end mb-md-0 mb-2">
            <button class="btn btn-primary mr-2">{{router.url.includes(URLConstants.ADD_FAQ) ? 'Add' :
              'Update'}}</button>
            <button class="btn btn-outline-primary" [routerLink]="[URLConstants.FAQS]">Cancel</button>
          </div>
        </div>
      </form>
    </div>
    <!-- Begin: Add/update FAQ -->
  </div>
</div>