import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ShowErrorService } from "../../common/service/show-error.service";
import { callAPIConstants } from "../../common/constants/callAPI-constants";
import { URLConstants } from "../../common/constants/routerLink-constants";
import { ThemeService } from '../../common/service/theme/theme.service';
import { CommonService } from "../../common/service/common.service";

// reusable
import {
  CountryISO,
  SearchCountryField,
  // TooltipLabel,
} from "ngx-intl-tel-input";
import { LocalStorageService } from "src/app/common/service/local-storage.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: [],
})
export class RegisterComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  public isSettingOpen = false;
  fieldTextType: boolean;
  URLConstants = URLConstants;
  public errorMsg: string = "";
  public user: any = {};
  public submitted = false;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  // TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  constructor(
    private themeService: ThemeService,
    private router: Router,
    private commonService: CommonService,
    private showErrorService: ShowErrorService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    var isDark = this.localStorageService.getToken('theme') == "Dark" ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document.getElementById("customSwitchTheme").setAttribute('checked', '');
  }

  onSignup(form, user) {
    this.submitted = true;
    this.errorMsg = "";
    // if(form.value.number != null && form.value.number.number.replace(/ +/g, "").length != 10) {
    //   this.errorMsg = "Please enter 10 digits phone number";
    //   return;
    // }
    if (form.valid) {
      const signUpUser = {
        fullName: user.firstName,
        countryCode: form.value.number.dialCode,
        latitude: 24,
        longitude: 24,
        emailId: user.email,
        password: user.password,
        mobile:
          form.value.number != null
            ? form.value.number.number.replace(/ +/g, "")
            : null,
      };
      this.commonService
        .callApi(
          this.callAPIConstants.VendorRegister,
          signUpUser,
          "post",
          true,
          false
        )
        .then((success) => {
          if (success.status === 1) {
            this.showErrorService.popToast("success", success.message);
            this.router.navigate([this.URLConstants.LOGIN]);


          } else {
            this.showErrorService.popToast("error", success.message);
          }
        }).catch((error) => {

        });
    }
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Alphabets
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }
}
