import { environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// constatns
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
// service
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { messages } from 'src/app/common/constants/errorMessagesData';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss']
})
export class EditDetailsComponent implements OnInit {
  submitted = false;
  myForm: FormGroup;
  fileToUpload: any;
  imageUrl: any;
  uploadImageUrl = environment.uploadImgUrl;
  imageSrc: string;
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  formData = new FormData();


  categoryID: any;
  CategoryValue='Add Category';
  Image: string;
 
 constructor(private activatedRoute: ActivatedRoute, 
  private router: Router,
   public commonService: CommonService, 
   public showErrorService: ShowErrorService,
   public bsModalRef: BsModalRef,
   private modalService: BsModalService,
   private formBuilder: FormBuilder,
  private localStorageService: LocalStorageService) {
  
    this.activatedRoute.params.subscribe((params) => { this.categoryID = params.id; });
    if(this.categoryID !==undefined){
      this.getcategory();
      this.CategoryValue='Edit';
     } 
}
   
 get f(){
   return this.myForm.controls;
 }
 getcategory(){
  this.commonService.callApi(this.callAPIConstants.Category_Details+ this.categoryID,
    '', 'get', false, false).then((success) => {     
      this.myForm.controls['name'].patchValue(success.data.name);
        this.Image = this.uploadImageUrl + success.data.image;
    });
 }
 handleFileInput(file: FileList) {
  this.fileToUpload = file.item(0);

  //Show image preview
  let reader = new FileReader();
  reader.onload = (event: any) => {
    this.imageUrl = event.target.result;
  }
  reader.readAsDataURL(this.fileToUpload);
  this.formData.append('image', file[0]);
}

 onFileSelect(e: File[]){
   let fileName = e[0];
   let fileType = fileName.type;
   this.formData.append('image', e[0]);
 }
  
 onFileChange(event) {
   const reader = new FileReader();
   
   if(event.target.files && event.target.files.length) {
     const [image] = event.target.files;
     reader.readAsDataURL(image);
   
     reader.onload = () => {
  
       this.imageSrc = reader.result as string;
    
       this.myForm.patchValue({
         fileSource: reader.result
       });
  
     };
  
   }
 }
  ngOnInit(){
    this.myForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      image: new FormControl('')
  })
  }

 submit(){
  this.submitted = true;
  
  if (this.myForm.invalid) {
    return;
}
  (this.categoryID)?this.formData.append('_id',this.categoryID):''

   this.formData.append('name',this.myForm.get('name').value);
   this.commonService.callApi(this.callAPIConstants.AddUpdateCategory, this.formData,'post', false, true, false).then((success) => {    
    if (success.status === 1) {
      this.showErrorService.popToast('success', success.message);
      this.router.navigate([this.URLConstants.MENU_CATEGORY]);
    } 
    else {      
      this.formData = new FormData(); 
      this.showErrorService.popToast('error', success.message);
      this.router.navigate([this.URLConstants.MENU_CATEGORY_ADDUPDATE]);
   }
  },(error)=> { 
      if(error.status === 500){
        this.formData = new FormData();
        this.showErrorService.popToast('error', error.error.error.message);
        this.router.navigate([this.URLConstants.MENU_CATEGORY_ADDUPDATE]);
      }
  });
 }





/****************************************************************************
  @Purpose     : Uploading Image
  @Parameter   : $event
  @Return      : NA
	/****************************************************************************/
  // image
  public file: any;
  imageChangedEvent = '';
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';
  ImageURL = "";
  imageCropped(event) {
    this.croppedImage = event.base64;
    this.ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = this.ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);
  }

  imageLoaded() { /* show cropper */ }

  loadImageFailed() { /* show message */ }

  fileChangeEvent(event: any, template: TemplateRef<any>): void {
    this.imageChangedEvent = event;
    this.bsModalRef = this.modalService.show(template);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], 'uploaded_file.jpg', { type: contentType, lastModified: Date.now() });
  }

  submitcroppedImage() {
    this.bsModalRef.hide();
    const fd = new FormData();
  
    this.formData.append('image', this.file);    

  }

  openfile(event: any) {
    event.preventDefault();
    const element = document.getElementById('profile');
    element.click();
  }

  removePhoto() {

  }
  	/****************************************************************************/
}
