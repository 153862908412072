import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// constants
import { callAPIConstants } from '../constants/callAPI-constants';
import { URLConstants } from '../constants/routerLink-constants';

// service
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';
import { ShowErrorService } from './show-error.service';
@Injectable({
	providedIn: 'root',
})
export class LogoutService {
	URLConstants = URLConstants;
	callAPIConstants = callAPIConstants;
	public Role;
	constructor(public localstorage: LocalStorageService, private router: Router, private commonService: CommonService,
		private showErrorService: ShowErrorService) {

	}
	/*************************************************************
	@PURPOSE      : Logout
	@PARAMETERS   : NA
	@RETURN       : NA
	/*************************************************************/
	logout() {

		let emailId;
		let pass;
		let rememberMe;
		if (this.localstorage.getToken('emailId') && this.localstorage.getToken('pass')) {

			emailId = this.localstorage.getToken('emailId');
			pass = this.localstorage.getToken('pass');
			rememberMe = true;
		}
		if (this.localstorage.getToken('role') === 'Super Admin') {

			this.commonService.callApi(this.callAPIConstants.AdminLogout, '', 'get', false, false, false).then((success) => {
				if (success.status === 1) {
					this.showErrorService.popToast('success', success.message);
					this.router.navigate([URLConstants.LOGIN]);
					this.localstorage.clearToken();
					if (rememberMe) {
						this.localstorage.setToken('emailId', emailId);
						this.localstorage.setToken('pass', pass);
					}
				}
			})
		} else if (this.localstorage.getToken('role') === 'vendor') {

			this.commonService.callApi(this.callAPIConstants.UserLogout, '', 'get', false, false, false).then((success) => {
				if (success.status === 1) {
					this.showErrorService.popToast('success', success.message);
					this.router.navigate([URLConstants.LOGIN]);
					this.localstorage.clearToken();
					if (rememberMe) {
						this.localstorage.setToken('emailId', emailId);
						this.localstorage.setToken('pass', pass);
					}
				}
			})
		} else {
			this.showErrorService.popToast('success', 'logout sucessfully');
			this.router.navigate([URLConstants.LOGIN]);
			this.localstorage.clearToken();
		}
	}
	/*************************************************************/
}
