<div class="main-content-area">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">Category</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
                </li>

                <li class="breadcrumb-item active" aria-current="page">
                    Menu-Category
                </li>
            </ol>
        </nav>

        <div class="m-content animated fadeIn">
            <!-- END: Responsive Data Table  -->
            <div class="animated fadeInUp box-wrapper card notification-card tabbing">
                <div class="m-portlet  settings-wrapper approve-template custom-tabs">
                    <!-- BEGIN: Add new category and Faqs buttons -->

                    <!-- END: Add new category and Faqs buttons -->

                    <tabset class="tab-links tab-content" id="content">
                        <tab id="tab1" (selectTab)="onSelect('CategoryList')">
                            <ng-template tabHeading>
                                <em class="bx align-middle mr-1"></em><span class="align-middle">Category List</span>
                            </ng-template>

                            <app-responsive-data-table [tableSetupData]="tableSetupData"
                                [accessPermission]="accessPermission" *ngIf="tabs.CategoryList">
                            </app-responsive-data-table>
                        </tab>

                        <tab [active]="tabs.faqCategory" (selectTab)="onSelect('CategoryProductList')"
                            customClass="date-icon common-icon">
                            <ng-template tabHeading (selectTab)="onSelect('CategoryProductList')">
                                <em class="bx align-middle mr-1"></em><span class="align-middle">Category Product List</span>
                            </ng-template>

                            <div class="container">
                                <div class="row mt-2">
                                    <div *ngFor="let item of prodCategoryList" [ngClass]="grid ? 'col-3' : 'col-12'">
                                        <div class="my-2">{{item.name}}</div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </div>
</div>