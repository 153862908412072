import { Component, DoCheck, HostListener, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

// services
import { LogoutService } from 'src/app/common/service/logout.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { CommonService } from '../../../common/service/common.service';

// constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { ThemeService } from '../../../common/service/theme/theme.service';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';

// plugin
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { stringify } from '@angular/compiler/src/util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})

export class HeaderComponent implements OnInit, DoCheck {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  uploadImageUrl = environment.profileImgUrl;
  public showError = false;
  public notificationCount: any = 0;
  public errorText = '';
  public firstname: any;
  public lastname: any;
  public photo: any;
  public theme: string = null;
  public alignment: string = null;
  status = false;
  passwordTypeOld: boolean;
  passwordTypeNew: boolean;
  public passdata: any = {};
  public submitted = false;
  modalRef: BsModalRef;
  fieldTextType: boolean;
  public notification = 0;
  public showErr = false;
  public notificationsArray: any = [];

  constructor(public localStorageService: LocalStorageService, private themeService: ThemeService,
    public logoutService: LogoutService, private showErrorService: ShowErrorService,
    private router: Router, public translate: TranslateService, private commonService: CommonService,
    private modalService: BsModalService) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    if (this.localStorageService.getToken('role') === 'vendor') {
      this.getcount();
      this.getNotifications();
    }
    const isDark = this.localStorageService.getToken('theme') === 'Dark' ? true : false;
    const isHorizontal = this.localStorageService.getToken('menuAlignment') === 'Horizontal' ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark) {
      document.getElementById('customSwitchHeaderTheme').setAttribute('checked', '');
    }
    this.themeService.switchAlignmentAfterLogin(isHorizontal);
    if (isHorizontal) {
      document.getElementById('sidebarSwitchBtn').setAttribute('checked', '');
    }
  }


  ngDoCheck(): void {
    this.firstname = this.localStorageService.getToken('firstname');
    this.lastname = this.localStorageService.getToken('lastname');
    this.photo = this.localStorageService.getToken('photo');
    this.notification = parseInt(this.localStorageService.getToken('notification'));
  }

  @HostListener('window:scroll', [])
  onWindowScroll(event: Event) {
    // set up the div "id=nav"
    if (document.body.scrollTop > 0 ||
      document.documentElement.scrollTop > 0) {
      document.getElementById('header').classList.add('sticky');
    }
    else {
      document.getElementById('header').classList.remove('sticky');
      // this.innerWidth = 'auto';
    }
  }

  /*************************************************************
 @Purpose     :changes deetection in newPassword for validations
 @Parameter   : NA
 @Return      : NA
 /*************************************************************/
  passwordChangesDetection() { this.showError = false; }
  /*************************************************************/


  /*************************************************************
 @Purpose     : Open Change Password modal
 @Parameter   : template
 @Return      : NA
 /*************************************************************/
  openChangePasswordModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }
  /*************************************************************/

  /*************************************************************
 @Purpose     : Call API to Change Password
 @Parameter   : NA
 @Return      : NA
 /*************************************************************/
  changePassword(passwordForm) {
    this.submitted = true;
    if (passwordForm.valid) {
      const data = {
        oldPassword: passwordForm.value.oldPassword,
        newPassword: passwordForm.value.newPassword,
      };
      this.showErr = false;
      let getMethod: string;
      if (this.localStorageService.getToken('role') === 'Super Admin') {
        getMethod = this.callAPIConstants.AdminChangePassword;
      } else if (this.localStorageService.getToken('role') === 'vendor') {
        getMethod = this.callAPIConstants.UserChangePassword;
      }
      this.commonService.callApi(getMethod, data, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.modalRef.hide();
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.DASHBOARD]);
        } else {
          this.modalRef.hide();
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
  }
  /*************************************************************/

  /*************************************************************
   @Purpose     : Get Profile Image
   @Parameter   : NA
   @Return      : url
  /*************************************************************/
  openSidebar() {
    const sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>);
    sidebar[0].style.display = 'block';
  }
  /*************************************************************/

  /*************************************************************
   @Purpose     : Get Profile Image
   @Parameter   : NA
   @Return      : url
  /*************************************************************/
  getProfile() {
    const url = this.localStorageService.getToken('photo');
    if (url == null || url === ' ' || !url) {
      return 'assets/images/NoProfile.png';
    } else {
      return url;
    }
  }
  /****************************************************************************/

  /*************************************************************
   @Purpose     : Get Profile Image
   @Parameter   : NA
   @Return      : url
  /*************************************************************/
  getcount() {
    this.commonService.callApi(this.callAPIConstants.getNotificationCount,
      '', 'get', false, false).then((success) => {
        this.notificationCount = success.data;

      });
  }
  /******************************************************************** */

  /*************************************************************
   @Purpose     : Get Notifications Listing
   @Parameter   : NA
   @Return      : url
  /*************************************************************/
  getNotifications() {
    const data = { page: 1, pagesize: 3 };
    this.commonService.callApi(this.callAPIConstants.notifications, data, 'post', false, false).then(success => {
      if (success.status === 1) {

        this.notificationsArray = success.data;
        this.notificationsArray.forEach(element => {
          if (element.content) {
            element.content = element.content.replace(/<[^>]*>/g, '');
          }
        });
      }
    });
  }
  /*************************************************************/

  /*************************************************************
   @Purpose     : toggle light/dark theme
   @Parameter   : NA
   @Return      : url
  /*************************************************************/
  toggleTheme() {
    this.theme = this.themeService.switchTheme();
    this.alignment = this.localStorageService.getToken('menuAlignment') != null ? this.localStorageService.getToken('menuAlignment') : null;
    const obj = { theme: this.theme, menuAlignment: this.alignment };
    if (this.localStorageService.getToken('role') === 'Super Admin') {
      this.commonService.callApi(this.callAPIConstants.UpdateTheme, obj, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.localStorageService.setToken('theme', success.data.theme);
          this.localStorageService.setToken('menuAlignment', success.data.menuAlignment);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
  }
  /*************************************************************/

  /*************************************************************
   @Purpose     : toggle Alignment
   @Parameter   : NA
   @Return      : NA
  /*************************************************************/
  toggleAlignment() {
    this.alignment = this.themeService.switchAlignment();
    this.theme = this.localStorageService.getToken('theme') != null ? this.localStorageService.getToken('theme') : null;
    const obj = { theme: this.theme, menuAlignment: this.alignment };
    this.commonService.callApi(this.callAPIConstants.UpdateTheme, obj, 'post', false, false).then((success) => {
      if (success.status === 1) {
        this.localStorageService.setToken('theme', success.data.theme);
        this.localStorageService.setToken('menuAlignment', success.data.menuAlignment);
      } else {
        this.showErrorService.popToast('error', success.message);
      }
    });
  }
  /*************************************************************/
}

