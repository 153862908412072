import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EarningRoutingModule } from './earning-routing.module';
import { EarningComponent } from './earning.component';
import { ListComponent } from './list/list.component';
import { WithdrawlistComponent } from './withdrawlist/withdrawlist.component';
import { NgxTableComponent } from './ngx-table/ngx-table.component';


@NgModule({
  declarations: [EarningComponent, ListComponent, WithdrawlistComponent, NgxTableComponent],
  imports: [
    CommonModule,
    EarningRoutingModule,
    SharedModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class EarningModule { }
