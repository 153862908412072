export const callAPIConstants = {
    // common
    CommonColumnVisibleSettings: 'saveColumnSettings',
    deleteDownloadTemplate: 'deleteTemplateSettings/',
    // AdminsaveFilter:'saveFilter',
    SaveDownloadTemplate: 'saveTemplateSettings',
    getDownloadTemplate: 'getTemplateSettings',
    // AdminDeleteFilter :'deleteFilter​/',
    AdminStatistics: 'admin/statistics',

    // wasted Miles
    AvailableDrivers: 'admin/availableDrivers',
    SendReqToDrivers: 'admin/sendRequestToDrivers',
    WastedMilesList: 'admin/listRequests',
    // user
    UserLogin: 'users/login',
    UserLogout: 'users/logout',
    UserChangePassword: 'users/changePassword',
    UserResetpass: 'users/resetPassword',
    // VendorProfile:'users/profile',
    VendorProfile: 'users/vendorProfile',
    Dashboardcount: 'allUsersCounts',
    VendorDashboardCount: 'vendorData',
    // UpdateVendorProfile:'users/updateUserProfile',
    UpdateVendorProfile: 'vendor/updateVendorProfile',
    Add_User: 'user/addUserByAdmin',
    AddAdminUser: 'admin/addAdminUser',
    UpdateAdminUser: 'admin/updateUser',
    AdminList: 'admin/userListing',
    AdminDeleteUsers: 'admin/deleteUsers',
    AdminChangeStatus: 'admin/changeStatus',
    GetAdminDetails: 'admin/userProfile/',
    downloadAdminUserFile: 'downloadAdminFile',
    Approved_user: 'user/approveUser/',
    // admin
    // GetAdminDetails:'admin/userListing',
    GetWithdrawalsBalance: 'getWallet',
    AdminLogin: 'admin/login',
    AdminRegister: 'admin/register',
    VendorRegister: 'vendor/register',
    VerifyOTP: 'admin/verifyOTP',
    SendOTP: 'admin/sendOTP',
    ForgotPasswordVendor: 'users/forgotPassword',
    ForgotPassword: 'admin/forgotPassword',
    ResetPassword: 'admin/resetPassword',
    AdminLogout: 'admin/logout',
    AdminGetProfile: 'admin/profile',
    VenderGetProfile: 'users/vendorProfile',
    AdminEditProfile: 'admin/editProfile',
    VendorEditProfile: 'vendor/updateVendorProfile',
    AdminFileUpload: 'admin/fileUpload',
    AdminChangePassword: 'admin/changePassword',
    UpdateTheme: 'admin/updateThemeDetails',
    CountriesListing: 'countriesListing',
    getCountryDetails: 'getCountryDetails',
    getCurrencyDetails: 'getCurrencyDetails',
    getTimezoneDetails: 'getTimezoneDetails',
    getCountriesList: 'getCountriesList',
    addUpdateCountry: 'addUpdateCountry',
    addUpdateCurrency: 'addUpdateCurrency',
    addUpdateTimezone: 'addUpdateTimezone',
    changeCountriesStatus: 'changeCountriesStatus',
    deleteCountries: 'deleteCountries',
    timezoneListing: 'timezoneListing',
    changeTimezoneStatus: 'changeTimezoneStatus',
    deleteTimezones: 'deleteTimezones',
    getTimezoneList: 'getTimezoneList',
    getCurrenciesList: 'getCurrenciesList',
    currenciesListing: 'currenciesListing',
    deleteCurrencies: 'deleteCurrencies',
    changeCurrenciesStatus: 'changeCurrenciesStatus',
    // social media sdk
    getsocialMediaSDK: 'getSocialMediaSDK',
    addSocialMediaSDK: 'addSocialMediaSDK',
    // social media link
    getSocialMediaLinks: 'getSocialMediaLinks',
    addSocialMediaLinks: 'addSocialMediaLinks',
    // global setiing
    addGlobalSettings: 'addGlobalSettings',
    getGlobalSettings: 'getGlobalSettings',
    // payment setting
    getPaymentDetails: 'getPaymentDetails',
    addPaymentDetails: 'addPaymentDetails',
    // smtp setting
    addSMTPAndSMSSettings: 'addSMTPAndSMSSettings',
    getSMTPAndSMSSettings: 'getSMTPAndSMSSettings',
    // email notification
    getEmailNotificationSettings: 'getEmailNotificationSettings',
    addEmailNotificationSettings: 'addEmailNotificationSettings',

    // email-template
    EmailTemplateList: 'listEmail',
    EmailTemplateDetail: 'detailEmail/',
    EmailColumnData: 'getEmailColumnValues',
    // EmailColumnVisibleSettings: 'emailColumnSettings',
    DeleteEmailTemplate: 'deleteTemplate',
    EmailAddNewEmail: 'addUpdateEmail',
    EmailChangeStatus: 'changeTemplateStatus',
    SearchEmail: 'searchEmail',

    // Faq
    VendorFaqsListing: '/faqslisting',
    FaqsListing: 'admin/faqsListing',
    DeleteFaqs: 'deleteFAQs',
    FaqsDetails: 'getFAQDetails/',
    AddUpdateFaqs: 'addUpdateFAQ',
    FaqsCategoryListing: 'faqCategoriesListing',
    FaqsStatusChange: 'changeFAQsStatus',
    FaqsSearchList: 'getFAQsList',
    AddUpdateFaqsCategory: 'addUpdateFAQCategory',
    DeleteFaqsCategory: 'deleteFAQCategories',
    FaqsCategoryDetails: 'getFAQCategoryDetails/',
    FaqsCategoryDelete: 'deleteFAQCategories',
    FaqsCategoryStatus: 'changeFAQCategoriesStatus',
    FaqsCategoriesSearchList: 'getFAQCategoriesList',
    // cms
    cmsListing: 'cmsList',
    cmsDetail: 'cmsDetail/',
    CmsAddUpdate: 'addUpdateCMS',
    CmsDelete: 'cmsDelete',
    // role
    RoleList: 'listRole',
    GetRoleDetail: 'getRolePermission/',
    AddUpdateRole: 'addRole',
    RoleChangeStatus: 'changeRoleStatus',
    RoleGetColumnData: 'roleFieldsList',
    RoleGetaddedByColumnData: 'adminListInRoles',

    // static && Blogs
    Listing: 'listBlog',
    DeleteBlog: 'deleteBlog',
    ChangeStatus: 'changeBlogStatus',
    GetBlogDetails: 'getBlogDetails/',
    AddUpdateBlog: 'addUpdateBlog',

    // user
    UserList: 'user/userListing',
    UserFileUpload: 'users/fileUpload',
    UserDeleteUsers: 'user/deleteUsers',
    UserChangeStatus: 'user/changeStatus',
    GetUserDetails: 'user/userProfile/',
    UserCSVforDataTable: 'user/downloadCsv',
    UserEXCELforDataTable: 'user/downloadExcel',
    UserColumnVisibleSettings: 'user/columnSettings',

    downloadUserFile: 'downloadUserFile',
    UserGetColumnData: 'user/getColumnValues',
    UserGetFilters: 'user/getFilters',
    // vendor coupan manage
    CoupanList: 'listCoupons',
    CoupanChangeStatus: 'changeCouponStatus',
    AddCoupon: 'addCoupon',
    GetCoupan: 'getCouponDetails/',
    DeleteCoupan: '/deleteCoupon',
    // common
    deleteFilter: 'deleteFilter/',
    saveFilter: 'saveFilter',
    getNotifications: 'notificationListing',
    getNotificationCount: 'getNotificationCount',
    // Earning
    // earningList:'earningListing',
    earningListingVendor: 'earningListingVendor',
    withdrawList: 'withdrawalRequestListing',
    // mealDeal /vendor/addUpdateMealDeal
    AddMealDeal: 'vendor/addUpdateMealDeal',
    mealdealList: 'vendor/mealDealList',
    mealdealStatus: 'vendor/changeMealDealStatus',
    mealdealDelete: 'vendor/mealDealDelete/',
    DetailsMealdeals: 'vendor/mealDealDetail/',
    // Cuisine
    getCuisine: 'getCuisineList',
    getCuisine_Admin: 'admin/getCuisineList',
    AddCuisine: 'admin/addUpdateCuisine',
    DeleteCuisine: 'admin/deleteCuisine',
    DetailCuisine: 'admin/getCuisineDetails/',
    changeCuisineStatus: 'admin/changeCuisineStatus',
    // Vendor Attribute list
    getAttribute: 'vendor/listAttributes',
    changeAttributeStatus: 'vendor/changeAttributeStatus',
    deleteAttribute: 'vendor/deleteAttribute',
    getAttributeDetails: 'vendor/getAttributeDetails/',
    addAttribute: 'vendor/addAttribute',
    // vendor
    productList: 'vendor/listProduct',
    categoryList: 'vendor/listCategory',
    changeCategorystatus: 'vendor/changeCategoryStatus',
    AddUpdateCategory: 'vendor/addUpdateCategory',
    DeleteCategory: 'vendor/deleteCategory',
    Category_Details: 'vendor/getCategoryDetails/',
    ProductCategoryList: 'vendor/listCategoryProduct',
    AddUserByAdmin : 'user/addUser',
    // Ticket
    createTicket: 'createTicket',
    adminTicketList: 'admin/listTickets',
    vendorTicketList: 'listTickets',
    adminAnswerTicket: 'admin/answerTicket',
    // role
    Role_Category: '/getCategory',
    Role_Permission: '/getAllPermission',
    // product
    Add_Product: 'vendor/addUpdateProduct',
    Product_Detail: 'vendor/getProductDetail/',
    Delete_Product: 'vendor/deleteProduct',
    Product_Status: 'vendor/changeProductStatus',
    // order
    DownloadOrders: 'downloadOrderByAdmin',
    Orderlist: 'newOrderListForVendor',
    AdminOrderlist: 'orderListing',
    OrderStatus: 'changeOrderStatus',
    View_Order: 'getOrderDetail/',
    Admin_View_Order: 'admin/getOrderDetail/',
    CHANGE_ORDER_STATUS: 'changeOrderStatus',
    PRODUCT_PREPARE_STATUS: '/productPreparedStatus',
    // Review
    Reviewlist: 'reviewListing',
    notifications: 'notificationListing',
    SendWithdrawalRequest: 'sendWithdrawalRequest',

    // push notification
    Push_Notification: 'users/marketing',

    // Admin Attribute
    AdminAttributeListing: 'attribute/listAttribute',
    DeleteAdminAttribute: 'attribute/deleteAttribute',
    AddAttribute: 'attribute/addAttribute',
    GetAttributeDetails: 'attribute/getAttributeDetails/',
    AttributeChangeStatus: 'attribute/changeStatus',

    // Admin Variants
    DeleteAdminVariant: 'customization/deleteVariant',
    AdminVariantListing: 'customization/listVariant',
    VariantChangeStatus: 'customization/changeStatus',
    AddVariant: 'customization/addVariant',
    GetVariantDetails: 'customization/getVariantDetails/',

    GetCustomizationVriantList: 'customization/listVariant',
    GetCustomizationAttributeList: 'attribute/listAttribute',

    // Admin Payout
    PayoutVendorDriver: 'withdrawalRequestList',
    Payout_Vendor_Driver_Withdrawal_Request: 'approvalRejectWithdrawalRequest',
    DownloadPayout: 'downloadPayoutByAdmin',
    UserDetailsAdminOrderlist: 'admin/listOrderByUser',

    //walkIn customers
    walkinListing: 'vendorUser/listingWithPage',
    walkinCustomersView: 'newOrderListForVendor',
    resendEmail: 'user/resendVerificationMail/'
};

