import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ChartModule } from 'angular-highcharts';

import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NgxMaskModule } from 'ngx-mask';


// plugin
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { ResponsiveDataTableComponent } from './components/responsive-data-table/responsive-data-table.component';
import { BnDatatableModule } from '../common/service/bn-datatable/bn-datatable.module';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';


import { RatingModule } from 'ngx-bootstrap/rating';
// service

// Component
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { AssignModalComponent } from './components/modals/assign-modal/assign-modal.component';
import { CusineDelModalComponent } from './components/modals/cusine-del-modal/cusine-del-modal.component';
import { FaqsCategoryComponent } from './components/modals/faqs-category/faqs-category.component';
import { FaqsListCategoryComponent } from './components/modals/faqs-list-category/faqs-list-category.component';
import { chart } from 'highcharts';
// import { GrapesJsComponent } from './components/grapes-js/grapes-js.component';
import { AgmCoreModule } from '@agm/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TwoDigitDecimaNumberDirective } from '../common/directives/two-digit-decima-number.directive';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';



// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  imports: [
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxIntlTelInputModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule.forRoot(),
    AngularEditorModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
    TooltipModule.forRoot(),
    DragDropModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    BnDatatableModule,
    DataTablesModule,
    ReactiveFormsModule,
    ChartModule,
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCiWXQmF7JoDEed5eI9Zr9945b3t83wVbI',
      libraries: ['places']
    })

  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    SubHeaderComponent,
    AssignModalComponent,
    CusineDelModalComponent,
    FaqsCategoryComponent,
    FaqsListCategoryComponent,
    TwoDigitDecimaNumberDirective,
    // GrapesJsComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [
    // components
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    DragDropModule,
    SubHeaderComponent,
    // GrapesJsComponent,

    // modules
    FormsModule,
    CommonModule,
    BnDatatableModule,
    NgxIntlTelInputModule,
    TypeaheadModule,

    // plugin
    NgMultiSelectDropDownModule,
    NgxMaskModule,
    AngularEditorModule,
    ImageCropperModule,
    BsDatepickerModule,
    TabsModule,
    PaginationModule,
    NgSelectModule,
    TooltipModule,
    NgxSkeletonLoaderModule,
    ChartModule,
    RatingModule,
    AgmCoreModule,
    TwoDigitDecimaNumberDirective,
    TimepickerModule
  ],
  entryComponents: [],
})
export class SharedModule { }
