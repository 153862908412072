import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';

// constants
import { URLConstants } from '../../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../../common/service/common.service';
import { GetPermissionsService } from '../../../../common/service/get-permissions.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit, DoCheck {
  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  tableSetupData: any = {};
  URLConstants = URLConstants;
  public accessPermission: any;
  tabs: any = {};

  constructor(private commonService: CommonService, public getPermissionsService: GetPermissionsService) {

    // START: Role Permission //
    this.accessPermission = this.getPermissionsService.getRolePermissions('cms');
    // END: Role Permission //
  }

  ngOnInit() {
    this.tabs.faqList = true;
    this.setDTableInitialData();
  }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*********************************************************************************************
  @Purpose     : FAQ Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  setDTableInitialData() {
    const tempData = [
      { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true, columnVisibility: true, },
      { type: 'text', colName: 'Question', colFieldname: 'faqQuestion', sort: true, columnVisibility: true, isVisible: true, filter: true },
      {
        type: 'text', colName: 'Category', colFieldname: 'faqCategoryName', sort: true, columnVisibility: true,
        isVisible: true, filter: true
      },
      { type: 'text', colName: 'Answer', colFieldname: 'faqAnswer', sort: true, columnVisibility: true, isVisible: true, filter: true },
      {
        type: 'text', colName: 'Created Date', colFieldname: 'createdAt', isVisible: true, columnVisibility: true,
        filter: false, isDate: true
      },
      { type: 'switch', colName: 'Status', colFieldname: 'status', columnVisibility: true, isVisible: true, filter: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },

    ];
    if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'faqsListing';
    this.tableSetupData.saveColumnKey = 'faqsListing';
    this.tableSetupData.actions = [
      {
        id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'bx bx-edit',
        type: 'icon', permission: this.accessPermission.edit
      },
      {
        id: 3, buttonTitle: 'delete', tooltip: 'Delete', class: 'bx bx-trash-alt',
        type: 'icon', permission: this.accessPermission.delete
      },

    ];
    this.tableSetupData.params = { deleteParams: 'faqsIds', statusParams: 'faqsIds' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: false,
      model: true,
      showButton: { routerLink: this.URLConstants.ADD_FAQ, buttonName: 'Add FAQ' },
    };
  }
  /*********************************************************************************************
  @Purpose     : FAQ Category Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  setDTableInitialData1() {
    const tempData = [
      {
        type: 'text', colName: 'Category', colFieldname: 'faqCategoryName', sort: true, columnVisibility: true,
        isVisible: true, filter: true
      },
      {
        type: 'text', colName: 'Created Date', colFieldname: 'createdAt', isVisible: true, columnVisibility: true,
        filter: false, isDate: true
      },
    ];
    if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'faqsCategoryListing';
    this.tableSetupData.saveColumnKey = 'faqsCategoryListing';
    this.tableSetupData.actions = [];
    this.tableSetupData.params = { deleteParams: 'faqCategoriesIds', statusParams: 'faqCategoriesIds' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: false,
      model: true,
    };
  }
  /*********************************************************************************************/



  /*************************************************************
  @Purpose     : To Select Faq List And FaqCategory Tabs.
  @Parameter   : NA
  @Return      : Datatable config
 /*************************************************************/
  onTabsSelection(event) {
    if (event === 'FaqList') {
      this.tabs.faqList = true;
      this.tabs.faqCategory = false;
      this.setDTableInitialData();
    }
    else if (event === 'FaqCategory') {
      this.tabs.faqList = false;
      this.tabs.faqCategory = true;
      this.setDTableInitialData1();
    }
  }
  /***************************************************************** */

}
