<div class="theme-setting-block" [ngClass]="{'open': isSettingOpen}">
    <a class="theme-setting-link" (click)="openSettingBox()">
        <em class="bx bx-cog bx-flip-horizontal" [ngClass]="{'bx-spin': !isSettingOpen}"></em>
    </a>
    <div class="d-flex align-items-center">
        <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
        <div class="custom-control custom-switch theme-switch">
            <input type="checkbox" class="custom-control-input" id="forgotPassSwitchTheme">
            <label class="custom-control-label" for="forgotPassSwitchTheme"></label>
        </div>
        <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
    </div>
</div>
<!--Start: Forgot password-->
<div class="login-wrapper"
    style="background:url(assets/images/login-bg.jpg) no-repeat center center; background-size: cover;">
    <div class="login-body">
        <section class="login-container row m-0">
            <div class="col-xl-8 col-11">
                <div class="card bg-login-card">
                    <div class="row m-0">
                        <div class="col-md-6 col-12 pl-0 pr-0">
                            <div class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                                <div class="card-header">
                                    <div class="card-title text-center mb-4">
                                        <h4 class="mb-2 mt-2">Forgot Password?</h4>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="text-center mb-5">
                                            <p><small class="d-block common-small-text">Enter the email you used when
                                                    you joined and we will send you temporary password</small></p>
                                        </div>
                                        <form (ngSubmit)="onForgotPassword(forgotPassForm, forgotPassword)"
                                            #forgotPassForm="ngForm">
                                            <div class="form-group mb-2 text-center">
                                                <div class="flex-column custom-radio custom-radio">
                                                    <label for="Vendor">
                                                        <input class="form-check-input"
                                                            [(ngModel)]="forgotPassword.type" type="radio" name="type"
                                                            #type="ngModel" id="Vendor" value="vendor" required>
                                                        <span></span>Vendor
                                                    </label>
                                                    <label for="Admin">
                                                        <input class="form-check-input"
                                                            [(ngModel)]="forgotPassword.type" type="radio" name="type"
                                                            #type="ngModel" id="Admin" value="admin" required>
                                                        <span></span>Admin
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group mb-4">
                                                <label>Email address<sup class="bx bxs-star danger"></sup></label>
                                                <input [(ngModel)]="forgotPassword.email" type="text" name="email"
                                                    id="EmailAddress" aria-describedby="emailHelp"
                                                    placeholder="Enter email address" class="form-control"
                                                    #email="ngModel"
                                                    [ngClass]="{ 'is-invalid': forgotPassForm.submitted && email.invalid }"
                                                    required email>
                                                <div *ngIf="forgotPassForm.submitted && email.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="email.errors.required">Please enter email address</div>
                                                    <div *ngIf="email.errors.email">Please enter valid email address
                                                    </div>
                                                </div>

                                            </div>
                                            <button type="submit"
                                                class="btn btn-primary glow position-relative btn-block">
                                                Send Email<em class="icon-arrow bx bx-right-arrow-alt"></em>
                                            </button>
                                        </form>
                                        <hr>
                                        <div
                                            class=" d-flex flex-md-row flex-column justify-content-between align-items-center mt-4 mb-3">
                                            <div class="text-left common-small-text">
                                                <small><a routerLink="/login">Sign in</a></small>
                                            </div>
                                            <div class="text-right common-small-text">
                                                <small>Don’t have an account? <a
                                                        [routerLink]="[URLConstants.SIGNUP]">Sign up</a></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
                            <div class="card-content">
                                <picture>
                                    <source srcset="assets/images/forgot-password-2.webp" type="image/webp">
                                    <source srcset="assets/images/forgot-password-2.png" type="image/png">
                                    <img class="img-fluid" src="assets/images/forgot-password-2.png"
                                        alt="forgot-password" width="300">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<!--End: Forgot password-->