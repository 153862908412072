<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <div class="card-title">
                <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-xl-2 col-sm-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-lg-2 col-sm-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-content-area" [hidden]="commonService.showSkeletonLoader">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">

                <li class="content-header-title">Meal Deal</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
                </li>
                <li class="breadcrumb-item" [routerLink]="[URLConstants.MEAL_DEALS]" aria-current="page">
                    Meal deal list
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    {{MealValue}}
                </li>
            </ol>
        </nav>

        <div class="card notification-card heading-card p-0">
            <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                <div class="icon d-flex align-items-center justify-content-center mr-2"><em
                        class='bx bx-mail-send'></em>
                </div>
                <div class="text">
                    <h5 class="mb-0 text Uppercase">{{MealValue}} </h5>
                </div>
            </div>
            <div class="content-wrapper heading-card-body" [hidden]="commonService.showSkeletonLoader">
                <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label for="file">Image</label>
                            <div class="upload-btn-wrapper">
                                <div class="profile-upload" *ngIf="!this.mealID">
                                    <img [src]="ImageURL ? ImageURL:'../../../assets/images/default-upload.png'"
                                        class="default-img" alt="Profile img" />
                                </div>
                                <div class="profile-upload" *ngIf="this.mealID">
                                    <img [src]="this.Image ? ImageURL||Image :'../../../assets/images/default-upload.png'"
                                        class="default-img" alt="Profile img" />
                                </div>
                                <button class="btn">Image</button>
                                <input id="image" type="file" name="image" (change)="fileChangeEvent($event,template)"
                                    #imageID>
                                <!-- <div class="error-block"
                                    *ngIf="(productForm.controls.image.touched || submitted) && productForm.controls.image.errors?.required">
                                    Please upload image
                                </div> -->
                            </div>

                        </div>
                        <div class="col-md-9 pb-3">
                            <div class="row ">
                                <div class="col-md-6 form-group">
                                    <label for="">Name<sup class="bx bxs-star danger"></sup></label>
                                    <input type="text" class="form-control" formControlName="name" name="name" id="name"
                                        placeholder="Enter name">
                                    <div class="error-block"
                                        *ngIf="(productForm.controls.name.touched || submitted) && productForm.controls.name.errors?.required">
                                        Please enter name
                                    </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="">Serving People<sup class="bx bxs-star danger"></sup></label>
                                    <input type="text" class="form-control" name="servingPeople" id="servingPeople"
                                        formControlName="servingPeople" placeholder="Enter serving people"
                                        (keydown)="restrictKeyPressService.AllowNumbers($event)"
                                        oninput="this.value = this.value.replace(/^[0]*/, '')">
                                    <div class="error-block"
                                        *ngIf="(productForm.controls.servingPeople.touched || submitted) && productForm.controls.servingPeople.errors?.required">
                                        Please enter serving people
                                    </div>
                                </div>

                                <div class="col-md-6 form-group">
                                    <label for="">Price<sup class="bx bxs-star danger"></sup></label>
                                    <input type="text" class="form-control" formControlName="price" name="price"
                                        id="price" placeholder="Enter price" required appTwoDigitDecimaNumber
                                        oninput="this.value = this.value.replace(/^[0]*/, '')">
                                    <div class="error-block"
                                        *ngIf="(productForm.controls.price.touched || submitted) && productForm.controls.price.errors?.required">
                                        Please enter price
                                    </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="">Discount Percentage</label>
                                    <input type="text" class="form-control" name="discountPercentage"
                                        id="discountPercentage" formControlName="discountPercentage"
                                        placeholder="Enter discount percentage"
                                        (keydown)="restrictKeyPressService.AllowNumbers($event)" maxlength="2"
                                        oninput="this.value = this.value.replace(/^[0]*/, '')">
                                    <div class="error-block"
                                        *ngIf="(productForm.controls.discountPercentage.touched || submitted) && productForm.controls.discountPercentage.errors?.required">
                                        Please enter discount percentage
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-4">
                        <div class="col-md">
                            <!-- <table class="table table-bordered" formArrayName="categoryProducts">
                                <tr>
                                    <th>Category:</th>
                                    <th width="150px"><button type="button" (click)="addcategoryproduct()"
                                            class="btn btn-primary">Add More</button></th>
                                </tr>
                                <tr *ngFor="let teacher of categoryProduct().controls; let ti=index">
                                    <div class="row align-items-center" [formGroupName]="ti">
                                        <div class="col-md-6 form-group">
                                            <label for="">Category Name</label>
                                            <input type="text" class="form-control" formControlName="catgeory">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="">Max items user can add</label>
                                            <input type="text" class="form-control"
                                                formControlName="maximumSelectableProducts">
                                        </div>
                                        <div class="col-md-12" [formGroupName]="ti">
                                            <button type="button" class="btn btn-primary" (click)="addproductas(ti)">Add
                                                Product</button>
                                        </div>
                                    </div>
                                    <div class="row pt-4 mt-4" formArrayName="products">
                                        <div class="col-md-4 mb-3"
                                            *ngFor="let batch of productas(ti).controls; let bi=index">
                                            <div [formGroupName]="bi">
                                                <div class="form-group">
                                                    <label for="name">Name</label>
                                                    <input type="text" class="form-control" formControlName="name">
                                                </div>
                                                <button class="btn btn-outline-primary"
                                                    (click)="removeproductas(ti,bi)">Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                    <td>
                                        <button (click)="removecategoryproduct(ti)"
                                            class="btn btn-outline-primary">Remove</button>
                                    </td>

                                </tr>
                            </table> -->

                            <table aria-describedby="tableContent" class="table table-bordered"
                                formArrayName="categoryProducts">
                                <tr>
                                    <th scope="col" id="tableTH" colspan="2">Category</th>
                                    <th scope="col" id="tableTH" width="160px"><button type="button" (click)="addcategoryproduct()"
                                            class="btn btn-primary">Add More</button></th>
                                </tr>
                                <tr *ngFor="let teacher of categoryProduct().controls; let ti=index">
                                    <td>
                                        <div [formGroupName]="ti">
                                            <div class="row">
                                                <div class="col-md-5 form-group mb-0">
                                                    <label for="catgeory">Category Name</label>
                                                    <input type="text" class="form-control" formControlName="catgeory"
                                                        id="catgeory" placeholder="Enter category">
                                                </div>
                                                <div class="col-md-5 form-group mb-0">
                                                    <label for="max-item">Items user can add</label>
                                                    <input type="text" class="form-control"  (keydown)="restrictKeyPressService.AllowNumbers($event)"
                                                        formControlName="maximumSelectableProducts" id="max-item"
                                                        placeholder="Enter max items">
                                                </div>
                                                <div class="col-md-2 text-right">
                                                    <button type="button" class="btn btn-primary mt-4 add-btn"
                                                        (click)="addproductas(ti)">Add Product</button>
                                                      
                                                </div>
                                                
                                            </div>
                                            <div formArrayName="products" class="row">
                                                <div class="col-md-4 mt-3"
                                                    *ngFor="let batch of productas(ti).controls; let bi=index">
                                                    <div [formGroupName]="bi">
                                                        <label for="name">Name</label>
                                                        <div class="d-flex align-content-center">
                                                            <input type="text" class="form-control"
                                                                formControlName="name" id="name"
                                                                placeholder="Enter Name">
                                                            <button class="btn btn-outline-primary ml-2"
                                                                (click)="removeproductas(ti,bi)">Remove</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td colspan="2">
                                        <button class="btn btn-outline-primary ml-2"
                                        (click)="categoryProduct().controls.splice(ti,1)">Remove</button> 
                                    </td>
                                    <!-- <td>
                                        <table width="100%" cellspacing="0" cellpadding="0">
                                        </table>
                                    </td> -->

                                <!-- <div [formGroupName]="ti">
                                    Category Name: 
                                    <input type="text" formControlName="catgeory" placeholder="Enter category">
                                    Max items user can add
                                    <input type="text" formControlName="maximumSelectableProducts" placeholder="Enter max items">
                                    <button type="button" class="btn btn-primary" (click)="addproductas(ti)">Add Product</button>

                                    <div formArrayName="products">
                                        <div *ngFor="let batch of productas(ti).controls; let bi=index">
                                            <div [formGroupName]="bi">Name :
                                                <input type="text" formControlName="name" placeholder="Enter Name">
                                                <button class="btn btn-outline-primary" (click)="removeproductas(ti,bi)">Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <td class="text-right" colspan="2">
                                    <button (click)="removecategoryproduct(ti)"
                                        class="btn btn-outline-primary">Remove</button>
                                </td> -->
                                </tr>


                            </table>
                            <div class="error-block" *ngIf="isCategory">Please add category</div>
                            <div class="error-block" *ngIf="isEmptyCategoty">Please fill correct fields</div>
                            <div class="error-block" *ngIf="isEmptyDynamicProduct">Please fill name</div>
                           
                            <div class="error-block" *ngIf="isMaxuser">Max items can not be greather than item added!</div>
                        </div>
                    </div>
                    <div class="d-inline-block w-100 text-right submit-btn-wrap mt-4 mb-4">
                        <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        <button type="button" class="btn btn-outline-primary"
                            [routerLink]="[URLConstants.MEAL_DEALS]">Cancel</button>
                    </div>
                </form>



            </div>
        </div>

    </div>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">
            Crop
            <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                <em class="la la-close"></em>
            </a>
        </h4>

    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
            [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>