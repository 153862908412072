import { ViewComponent } from './view/view.component';
import { ListComponent } from './list/list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminListComponent } from './admin-list/admin-list.component';


const routes: Routes = [
  {
    path: 'list',
    component: ListComponent
  },
  {
    path: 'view/:id',
    component: ViewComponent,
    pathMatch: 'full',
  },
  {
    path: 'viewOrder/:id',
    component: ViewComponent,
    pathMatch: 'full',
  },
  {
    path: 'adminlist',
    component: AdminListComponent,
    pathMatch: 'full'
  },
  {
    path: '', redirectTo: 'list', pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderRoutingModule { }
