<div class="theme-setting-block" [ngClass]="{'open': isSettingOpen}">
    <a class="theme-setting-link" (click)="openSettingBox()">
        <em class="bx bx-cog bx-flip-horizontal" [ngClass]="{'bx-spin': !isSettingOpen}"></em>
    </a>
    <div class="d-flex align-items-center">
        <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
        <div class="custom-control custom-switch theme-switch">
            <input type="checkbox" class="custom-control-input" id="resetPassSwitchTheme" (click)="toggleTheme()">
            <label class="custom-control-label" for="resetPassSwitchTheme"></label>
        </div>
        <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
    </div>
</div>

<!--Start Login Wrapper-->
<div class="login-wrapper"
    style="background:url(assets/images/login-bg.jpg) no-repeat center center; background-size: cover;">
    <div class="login-body">
        <section class="login-container row m-0">
            <div class="col-xl-8 col-11">
                <div class="card bg-login-card">
                    <div class="row m-0">
                        <div class="col-md-6 col-12 pl-0 pr-0">
                            <div class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                                <div class="card-header">
                                    <div class="card-title text-center mb-4">
                                        <h4 class="mb-2 mt-2">Reset Your Password</h4>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <form class="example-form" #passwordForm="ngForm"
                                            (ngSubmit)="onSubmitPassword(passwordForm, password)"
                                            content-type="application/x-www-form-urlencoded">
                                            <div class='form-group'>
                                                <label htmlFor="password">Token</label>
                                                <input class="form-control" placeholder="Enter token send in email"
                                                    type="text" name="code" autocomplete="off"
                                                    [(ngModel)]="password.code" required #code="ngModel" />
                                                <div class="error-block text-left"
                                                    *ngIf="((submitted || code.touched) && code.invalid)">
                                                    Please enter token
                                                </div>
                                            </div>
                                            <div class='form-group'>
                                                <label htmlFor="password">New password</label>
                                                <input class="form-control" placeholder="Enter a new password"
                                                    type="password" name="password" autocomplete="off"
                                                    [(ngModel)]="password.password"
                                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                                    value="" id="password" required #newPassword="ngModel" />
                                                <div class="error-block text-left"
                                                    *ngIf="((submitted || newPassword.touched) && newPassword.invalid)">
                                                    {{showErrorService.showError('newPassword', newPassword.errors)}}
                                                </div>
                                            </div>
                                            <div class='form-group'>
                                                <label htmlFor="Password">Confirm Password</label>
                                                <input class="form-control" placeholder="Confirm your new password"
                                                    type="password" name="confirmpassword"
                                                    [(ngModel)]="password.confirmpassword"
                                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                                     value="" autocomplete="off" id="confirmpassword" required
                                                    #confirmPassword="ngModel" />
                                                <div class="error-block text-left"
                                                    *ngIf="((submitted || confirmPassword.touched) && !confirmPassword.value)">
                                                    {{showErrorService.showError('confirmPassword',
                                                    confirmPassword.errors)}}
                                                </div>
                                                <div class="error-block text-left"
                                                    *ngIf="password.password && password.confirmpassword && password.password !== password.confirmpassword">
                                                    <p>New password and confirm password must be same</p>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <button class='btn btn-block btn-primary' type='submit'> Reset my
                                                    password <span><em class="fa fa-arrow-right"
                                                            aria-hidden="true"></em></span></button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
                            <div class="card-content">
                                <picture>
                                    <source srcset="assets/images/forgot-password.webp" type="image/webp">
                                    <source srcset="assets/images/forgot-password.png" type="image/png">
                                    <img class="img-fluid" src="assets/images/forgot-password.png" alt="forgot-password"
                                        width="300">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<!--End Login Wrapper-->