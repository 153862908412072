import { environment } from './../../../../environments/environment.staging';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// interface
import { IBreadcrumbs } from 'src/app/common/interfaces/interfaces';

// constatns
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
// service
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { GetPermissionsService } from 'src/app/common/service/get-permissions.service';
import { isDefined } from '@angular/compiler/src/util';
@Component({
  selector: 'app-answer-ticket',
  templateUrl: './answer-ticket.component.html',
  styleUrls: ['./answer-ticket.component.scss']
})
export class AnswerTicketComponent implements OnInit {
  submitted=false;
  vendorID:any;
  imageSrc: string;
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  formData = new FormData();

  title: any;
  isVerify: any;
  role: any;
  public answerResponse :any=[];
 constructor(private activatedRoute: ActivatedRoute, 
  public commonService: CommonService, private router: Router,
  public showErrorService: ShowErrorService,
  private localStorageService: LocalStorageService) {
    this.activatedRoute.params.subscribe((params) => { this.vendorID = params.id; });   
}
 
  ngOnInit(){
   //this.getUser();
  }
 /*************************************************************
  @Purpose     : Get Role Details
  @Parameter   : NA
  @Return      : role
  /*************************************************************/
  public AnswerFormData: any ={
    answer: '',
  }
  AnswerCall(form, formData): void{
    this.submitted =true;
    if(form.valid){
    let obj = {
      answer: formData.answer,
      ticketId: this.vendorID
    }
    this.commonService.callApi(this.callAPIConstants.adminAnswerTicket,obj, 'post', false, false, false).then((success) => {
      if (success.status === 1) {    
        this.answerResponse = success.data;
        this.showErrorService.popToast('success', success.message);
        this.router.navigate(['/ticket-management/list']);
      }
    }); 
  }
 }
}