<div class="main-content-area">
  <div class="main-content-block">
    <app-sub-header [subHeaderData]="subHeaderData"></app-sub-header>
    <!-- filter bar buttons end -->
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="content-header-title">Menu Cuisine</li>
          <li class="breadcrumb-item">
            <a [routerLink]="[]"><em class="bx bx-home-alt"></em></a>
          </li>
          <li class="breadcrumb-item">Cuisine</li>
          <li class="breadcrumb-item active" aria-current="page">
            List
          </li>
        </ol>
      </nav>
    </div>
    <div class="m-content animated fadeIn">
      <!-- BEGIN: Responsive Data Table  -->
      <app-responsive-data-table [tableSetupData]="tableSetupData" #responsiveDataTable>
      </app-responsive-data-table>
      <!-- END: Responsive Data Table  -->
    </div>
  </div>
</div>