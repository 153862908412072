<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <div class="card-title">
                <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-xl-2 col-sm-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-lg-2 col-sm-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-content-area">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">

                <li class="content-header-title">Product</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
                </li>
                <li [routerLink]="[URLConstants.PRODUCT]" class="breadcrumb-item">Product Management
                </li>
                <li class="breadcrumb-item active">View</li>

                <!-- <li class="breadcrumb-item active" aria-current="page"></li> -->
            </ol>
        </nav>
        <div class="card profile-card">
            <div class="user-detail-wrap">
                <div class="user-detail-img-wrap">
                    <figure>
                        <a [href]="objectImage?this.uploadImageUrl + objectImage:'../../../../assets/images/default-upload.png'" data-fancybox
                            class="fancybox">
                            <img [src]="objectImage?this.uploadImageUrl + objectImage:'../../../../assets/images/default-upload.png'"
                                [ngStyle]="{'font-size.px':24,'height.px':200,'width.px':200}" class="img-thumbnail"
                                alt="no img" /></a>
                    </figure>
                </div>
                <div class="user-detail-text-wrap">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="user-details">
                                <label>
                                    Create : </label>
                                <span class="inner-details">{{ (Detail?.createdAt) ? (Detail.createdAt|
                                    date:DATE_PATTERN) : ' -- ' }}</span>

                            </div>
                            <div class="user-details">
                                <label>
                                    Name :
                                </label>
                                <span class="inner-details">{{ (Detail?.name) ? (Detail.name) : '--'}}</span>

                            </div>
                            <div class="user-details">
                                <label>
                                    Status :
                                </label>
                                <div class="custom-control custom-switch light d-inline-block">
                                    <input type="checkbox" class="custom-control-input" [(ngModel)]="Detail.status"
                                        [disabled]="true" name="status" #status="ngModel" id="customSwitch2">
                                    <label class="custom-control-label" for="customSwitch2"> </label>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="user-details">
                                <label>Carbohydrate :</label>
                                <span class="inner-details">{{ (Detail?.carbohydrate) ? (Detail.carbohydrate ) :
                                    '--'}}</span>
                            </div>
                            <div class="user-details">
                                <label>Protein:</label>
                                <span class="inner-details">{{ (Detail?.protein|json) }}</span>
                            </div>
                            <div class="user-details">
                                <label>Description :</label>
                                <span class="inner-details">{{ (Detail?.description) ? Detail.description :
                                    '--'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>