import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { CUURENCY_CODE } from '../../common/constants/categoryType-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { ShowErrorService } from 'src/app/common/service/show-error.service';

// plugins
import * as Highcharts from 'highcharts';

@Component({
   selector: 'app-dashboard',
   templateUrl: './dashboard.component.html',
   styleUrls: []
})
export class DashboardComponent implements OnInit {
   CUURENCY_CODE = CUURENCY_CODE;
   public revenueOptions: any = {
      chart: {
         type: 'column'
      },
      title: {
         text: ''
      },
      xAxis: {
         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
         // crosshair: true,
         title: {
            text: ''
         },
      },
      yAxis: {
         min: 0,
         title: {
            text: ''
         }, labels: {
            formatter() {
               return this.value;
            }
         },
      },
      tooltip: {
         headerFormat: '',
         pointFormat: 'Total : {point.y}',
      },
      credits: {
         enabled: false
      },
      legend: {
         align: 'center',
      },
      lang: {
         noData: 'Data not found'
      },
      noData: {
         style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#000000'
         }
      },
      plotOptions: {
         column: {
            pointPadding: 0.2,
            borderWidth: 0
         }
      },
      series: [
         {
            name: 'Orders',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            color: '#007bff',

         }, {
            name: 'Drivers',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            color: '#dc3545'
         },
         {
            name: 'Users',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            color: '#ff9900'
         },
         {
            name: 'Vendors',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            color: '#00ff80'
         }],
   };
   currentYear = new Date().getFullYear();
   showSkeletonLoader = false;
   public highcharts = Highcharts;
   public yearsArr: any[] = [];
   public filterData: any = {};
   callAPIConstants = callAPIConstants;
   public URLConstants = URLConstants;
   public currentDate = new Date();
   tokenaccess: any;
   currentRole: any = {};
   VendorCount: any = {};
   Admincount: any = {
      driverCount: '',
      allUserCount: '',
      allVendorCount: '',
      totalOrder: '',
      wallet: '',
   };
   isProfileCompleted: any;

   constructor(public commonService: CommonService,
      private router: Router, public showErrorService: ShowErrorService,
      public localStorageService: LocalStorageService) {
      this.currentRole = this.localStorageService.getToken('role');
      // this.accessPermission = JSON.parse(this.tokenaccess.rolesAccess);'
   }

   ngOnInit(): void {
      this.filterData.year = JSON.stringify(this.currentYear);
      for (let index = 2020; index <= new Date().getFullYear(); index++) {
         this.yearsArr.push(JSON.stringify(index));
      }

      if (this.localStorageService.getToken('role') === 'vendor') {
         this.getvendorDashboardCount();
         this.getvendorProfile();
      }
      if (this.localStorageService.getToken('role') === 'Super Admin') {
         this.getDashboardCount();
         this.changeYear('revenueReport');
      }
   }

   /************************************************************ */
   // checking vendor profile completing or not
   getvendorProfile() {
      this.commonService.callApi(this.callAPIConstants.VendorProfile,
         '', 'get', false, false).then((success) => {
            if (success.status === 1) {
               this.isProfileCompleted = success.data.isProfileCompleted;
               if (this.isProfileCompleted === false) {
                  this.router.navigate([this.URLConstants.VENDOR_PROFILE]);
               }
            }
         });
   }

   /*********** dashboard count for vendor **************************/
   getvendorDashboardCount() {
      this.showSkeletonLoader = true;
      this.commonService.callApi(this.callAPIConstants.VendorDashboardCount,
         '', 'get', false, false).then((success) => {
            if (success.status === 1) {
               this.VendorCount.allCustomerCountForVendor = success.data.allCustomerCountForVendor;
               this.VendorCount.allOrderCount = success.data.allOrderCount;
               this.VendorCount.totalRevenue = success.data.totalRevenue;
               this.showSkeletonLoader = false;
            }
         });
   }

   /*********** dashboard count for Admin **************************/
   getDashboardCount() {
      this.showSkeletonLoader = true;
      this.commonService.callApi(this.callAPIConstants.Dashboardcount,
         '', 'get', false, false).then((success) => {
            if (success.status === 1) {
               this.Admincount = success.data;
               this.showSkeletonLoader = false;
            }
         });
   }

   /******************************************************/

   /*************************************************************
 @Purpose     : Show Subheader with Breadcrumbs
 @Parameter   : NA
 @Return      : Breadcrumbs
 /*************************************************************/
   total: any;
   reloadUser = true;
   reloadRevenue = true;
   changeYear(type?) {
      this.commonService.callApi(this.callAPIConstants.AdminStatistics, this.filterData, 'post').then((res) => {
         if (res.status === 1) {
            const monthsArr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            monthsArr.forEach((y, i) => {
               if (this.reloadRevenue) {
                  if (res.data.length) {
                     res.data.map(x => {
                        if (y == x._id) {
                           if (x.order) { this.revenueOptions.series[0].data[i] = x.order; }
                           if (x.driver) { this.revenueOptions.series[1].data[i] = x.driver; }
                           if (x.user) { this.revenueOptions.series[2].data[i] = x.user; }
                           if (x.vendor) { this.revenueOptions.series[3].data[i] = x.vendor; }
                           return this.revenueOptions;
                        }
                     });
                  } else {
                     this.revenueOptions.series[0].data[i] = [];
                     this.revenueOptions.series[1].data[i] = [];
                     this.revenueOptions.series[2].data[i] = [];
                     this.revenueOptions.series[3].data[i] = [];
                  }
                  this.highcharts.chart('revenueChart', this.revenueOptions);
               }
            });
         }
      });
      // this.reloadRevenue = false;
   }
   /*************************************************************/


}
