// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration='staging' && node server.js` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    environmentName: 'staging',
    port: 4050,
    // apiUrl: 'https://eatozee-api.indianic.dev/api/',
    //    uploadImgUrl: 'https://eatozee-api.indianic.dev',
    apiUrl: 'https://eatozee-api.inic.cloud/api/',
    uploadImgUrl: 'https://eatozee-api.inic.cloud',
    profileImgUrl: 'https://eatozee-api.inic.cloud/public/upload/images/',
};