import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from '../../common/service/theme/theme.service';
import { ShowErrorService } from '../../common/service/show-error.service';
// environment

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: []
})
export class LoginComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  passwordType: boolean;
  public user: any = {};
  public users: any = {};
  uploadImageUrl = environment.uploadImgUrl;
  profileImgUrl = environment.profileImgUrl;
  Rolecast: any = {};
  vendor = false;
  superAdmin = false;
  formcheck;

  constructor(private themeService: ThemeService, private router: Router,
    private localStorageService: LocalStorageService,
    private commonService: CommonService, private showErrorService: ShowErrorService
  ) {


  }
  ngOnInit(): void {
    if (this.localStorageService.getToken('emailId') != null && this.localStorageService.getToken('pass') != null) {
      let pass = '';
      pass = this.localStorageService.getToken('pass'),
        this.users = {
          emailOrMobile: this.localStorageService.getToken('emailId'),
          password: window.atob(pass),
          rememberMe: true
        }
    }


    var isDark = this.localStorageService.getToken('theme') == "Dark" ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document.getElementById("loginSwitchTheme").setAttribute('checked', '');
  }
  selectChangeHandler(tabName: string) {
    this.formcheck = tabName;
    //update the ui
    this.vendor = false;
    this.superAdmin = false;
    if (tabName === 'superAdmin') {
      this.superAdmin = true
    } else if (tabName === 'vendor') {
      this.vendor = true
    }
    // this.Rolecast = event.target.value;
  }



  onLogin(form, users) {
    var numbers = /^[0-9]+$/;
    var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (users.rememberMe) {
      this.localStorageService.setToken('emailId', form.value.emailOrPhone);
      this.localStorageService.setToken('pass', window.btoa(form.value.password));
    } else {
      this.localStorageService.removeToken('emailId');
      this.localStorageService.removeToken('pass');
    }

    if ((form.value.emailOrPhone.match(email)) || (form.value.emailOrPhone.match(numbers) && form.value.emailOrPhone.length == 10)) {

      if (form.value.emailOrPhone === 'seed_admin@grr.la' || form.value.emailOrPhone === 'admin_test@grr.la' || form.value.emailOrPhone === 'dhara.shah@grr.la' || form.value.emailOrPhone === 'shubhamkheni@grr.la') {
        this.user = {
          authName: form.value.emailOrPhone,
          password: form.value.password
        }
        // if(users.rememberMe){
        //   this.localStorageService.setToken('emailId',form.value.emailOrPhone);
        //   this.localStorageService.setToken('pass',window.btoa(form.value.password)); 
        // } else{
        //   this.localStorageService.removeToken('emailId');
        //   this.localStorageService.removeToken('pass');
        // }

        this.commonService.callApi(this.callAPIConstants.AdminLogin, this.user, 'post', true, false).then((success) => {
          if (success.status === 1) {
            this.showErrorService.popToast('success', success.message);
            this.localStorageService.setToken('accessToken', success.access_token);
            this.localStorageService.setToken('firstname', success.data.firstname);
            this.localStorageService.setToken('lastname', success.data.lastname);
            this.localStorageService.setToken('role', success.data.role.role);
            if (success.data.photo) {
              this.localStorageService.setToken('photo', this.profileImgUrl + success.data.photo);
            }
            this.localStorageService.setToken('theme', success.data.theme);
            this.localStorageService.setToken('menuAlignment', success.data.menuAlignment);
            var isDark = success.data.theme == "Dark" ? true : false;
            this.themeService.switchThemeAfterLogin(isDark);
            this.localStorageService.setToken('role_permission',
              this.commonService.encrypt(success.data.rolePermission));
            if (success.data.role.role == 'Super Admin') {
              this.localStorageService.setToken('AdminId',
                this.commonService.encrypt(success.data.role._id));
            }
            //this.showErrorService.popToast('success', success.message);
            this.router.navigate([this.URLConstants.DASHBOARD]);
          } else {
            this.showErrorService.popToast('error', success.message);
          }
        });
      } else {
        this.users = {
          emailOrMobile: form.value.emailOrPhone,
          password: form.value.password
        }

        // if(users.rememberMe){
        //   this.localStorageService.setToken('emailId',form.value.emailOrPhone);
        //   this.localStorageService.setToken('pass',window.btoa(form.value.password)); 
        // } else{
        //   this.localStorageService.removeToken('emailId');
        //   this.localStorageService.removeToken('pass');
        // }

        this.commonService.callApi(this.callAPIConstants.UserLogin, this.users, 'post', true, false).then((success) => {
          if (success.status === 1 && success.data.role != 'user') {
            this.showErrorService.popToast('success', success.message);
            this.localStorageService.setToken('profileComplete', success.data.isProfileCompleted)
            this.localStorageService.setToken('lat', success.data.latitude);
            this.localStorageService.setToken('lng', success.data.longitude);
            this.localStorageService.setToken('accessToken', success.extra_meta.token);
            this.localStorageService.setToken('firstname', success.data.fullName);
            this.localStorageService.setToken('lastname', success.data.lastname);
            this.localStorageService.setToken('role', success.data.role);
            this.localStorageService.setToken('isPizzaResturant', success.data.isPizzaResturant);

            if (success.data.restaurantLogo) {
              this.localStorageService.setToken('photo', this.uploadImageUrl + success.data.restaurantLogo);
            }
            this.localStorageService.setToken('theme', success.data.theme);
            let Vertical = 'Vertical';
            this.localStorageService.setToken('menuAlignment', Vertical);
            var isDark = success.data.theme == "Dark" ? true : false;
            this.themeService.switchThemeAfterLogin(isDark);
            this.localStorageService.setToken('role_permission',

              this.commonService.encrypt(success.data.rolePermission));
            if (success.data.role === 'vendor') {
              this.localStorageService.setToken('VendorId', success.data._id);
            }
            //this.showErrorService.popToast('success', success.message);
            this.router.navigate([this.URLConstants.DASHBOARD]);
          }
          if (success.status === 1 && success.data.role == 'user') {
            this.showErrorService.popToast('error', 'Not Authorized');
          }
          // else {
          //   this.showErrorService.popToast('error', success.message);
          // }
        });
      }

    }

  }

  /*************************************************************/
  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleFieldTextType() {
    this.passwordType = !this.passwordType;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }

}
