import { Component, OnInit } from '@angular/core';
import { URLConstants } from '../../common/constants/routerLink-constants';
import { CommonService } from '../../common/service/common.service';
import { GetPermissionsService } from '../../common/service/get-permissions.service';
import { MouseEvent } from '@agm/core';
import { LocalStorageService } from '../../common/service/local-storage.service';
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
@Component({
  selector: 'app-agm-map',
  templateUrl: './agm-map.component.html',
  styleUrls: ['./agm-map.component.scss']
})
export class AgmMapComponent implements OnInit {
  URLConstants = URLConstants;
  public accessPermission: any;
  public lats: number = 0;
  public lngs: any = 0;

  constructor(private commonService: CommonService,
    public getPermissionsService: GetPermissionsService, private localstorage: LocalStorageService) {
    // START: Role Permission //
    this.accessPermission = this.getPermissionsService.getRolePermissions('role');
    this.lats = parseFloat(this.localstorage.getToken('lat'));
    this.lngs = parseFloat(this.localstorage.getToken('lng'));

    // END: Role Permission //
  }


  ngOnInit(): void {
  }
  zoom: number = 8;

  // initial center position for the map
  lat: number = 20.673858;
  lng: number = 79.815982;

  clickedMarker(label: string, index: number) {

  }

  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  }

  markerDragEnd(m: marker, $event: MouseEvent) {

  }

  markers: marker[] = [
    {
      lat: this.lats,
      lng: this.lngs,
      label: 'vendor',
      draggable: true
    },
    {
      lat: 51.373858,
      lng: 7.215982,
      label: 'B',
      draggable: false
    },
    {
      lat: 51.723858,
      lng: 7.895982,
      label: 'C',
      draggable: true
    }
  ]
}
