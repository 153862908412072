<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <div class="card-title">
                <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-xl-2 col-sm-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-lg-2 col-sm-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END : Show Skeleton Loader -->
<div class="main-content-area">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">Attribute</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    {{this.attributeValue}}
                </li>
            </ol>
        </nav>
        <div class="card notification-card">

            <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                <div class="icon d-flex align-items-center justify-content-center mr-2"><em class='bx bx-mail-send'></em>
                </div>
                <div class="text">
                    <h5 class="mb-0 text Uppercase">{{this.attributeValue}}</h5>
                </div>
            </div>
            <div class="content-wrapper " [hidden]="commonService.showSkeletonLoader">
                <form [formGroup]="myForm" (ngSubmit)="saveProduct(myForm)" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="name">Name</label>
                                <input formControlName="name"
                                    [ngClass]="((submitted || f.name.touched) && f.name.invalid)?'has-danger':''"
                                    id="name" type="text" class="form-control" required>
                                <div *ngIf="f.name.touched && f.name.invalid" class="error-blockr has-danger">
                                    <div *ngIf="f.name.errors.required">Name is required.</div>
                                    <div *ngIf="f.name.errors.minlength">Name should be 3 character.</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="text-right submit-btn-wrap">
                        <button type="submit" class="btn btn-primary submit-btn mr-2">Submit</button>
                        <button type="button" class="btn btn-outline-primary submit-line-btn"
                            [routerLink]="[URLConstants.ATTRIBUTE]">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>