<aside class="sidebar">
    <script>

    </script>
    <div class="sidebar-inner-wrapper">

        <div class="sidebar-heading d-flex align-items-end justify-content-between w-100 ">
            <a class="navbar-brand">
                <div class="brand-logo" [routerLink]="[URLConstants.DASHBOARD]">
                    <img src='../../../../assets/images/brand-logo.svg' class="header-logo" alt="Eatozee Logo" />
                </div>
            </a>
            <span (click)="closeSidebar()" class="sidebar-close-icon"><em class="bx bx-x"></em></span>
        </div>
     <!-- ** Super Admin ** -->
        <ng-container *ngIf="roles==='Super Admin'">
            <div class="sidebar-menu">
                <ul class="sidebar-menu-list">
                    <li class="menu-list-item has-child-submenu level1">
                        <a (click)="closeSidebar()" href="#" class="menu-link">
                            <em class='bx bx-home-alt'></em>
                            <span class="menu-title">Dashboard</span>
                        </a>
                    </li>

                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html"
                            [routerLink]="[URLConstants.MANAGE_ORDER_ADMIN]" class="menu-link">
                            <em class='bx bx-shopping-bag'></em>
                            <span class="menu-title">Manage Order</span>
                        </a>
                    </li>
                    <li class="settign-menu menu-list-item has-child-submenu level1"
                        (click)="openCloseSubMenu('subContentMenu')" [ngClass]="subContentMenu ? 'submenu-open' : null">
                        <a style="cursor: pointer;" class="menu-link">
                            <em class='bx bx-check-circle'></em>
                            <span class="menu-title">Contents</span>
                            <em class="bx bxs-chevron-right"></em>
                        </a>
                        <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subContentMenu ? 'show' : null">
                            <li class="menu-list-item">
                                <a id="cms" (click)="closeSidebarMenuItem('cms');" [routerLink]="[URLConstants.CMS]"
                                    class="menu-link">
                                    <em class='bx bxs-right-arrow-alt'></em>
                                    <span class="menu-title">CMS</span>
                                </a>
                            </li>
                            <li class="menu-list-item">
                                <a (click)="closeSidebar()" [routerLink]="[URLConstants.FAQS]" class="menu-link">
                                    <em class='bx bxs-right-arrow-alt'></em>
                                    <span class="menu-title">FAQ's</span>
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.USERS]" class="menu-link">
                            <em class='bx bxs-group'></em>
                            <span class="menu-title">User Management</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.PAYOUTS]" class="menu-link">
                            <em class='bx bx-money'></em>
                            <span class="menu-title">Payout</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.CUISINE]" class="menu-link">
                            <em class='bx bx-heart-circle'></em>
                            <span class="menu-title">Cuisine</span>
                        </a>
                    </li>
                    <!-- <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.MANAGE_ROLES]" class="menu-link">
                            <i class='bx bx-note'></i>
                            <span class="menu-title">Roles</span>
                        </a>
                    </li> -->
                    <li class="menu-list-item">
                        <a id="master" (click)="openCloseSubMenu('master');" [routerLink]="[URLConstants.MASTER]"
                            class="menu-link">
                            <em class='bx bx-data'></em>
                            <span class="menu-title">Master</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html"
                            [routerLink]="[URLConstants.EMAIL_TEMPLATE_list]" class="menu-link">
                            <em class='bx bx-message-detail'></em>
                            <span class="menu-title">Manage Email</span>
                        </a>
                    </li>

                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html"
                            [routerLink]="[URLConstants.WASTE_MANAGEMENT]" class="menu-link">
                            <em class='bx  bx-trash-alt'></em>
                            <span class="menu-title">Wasted Mile</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html"
                            [routerLink]="[URLConstants.ADMIN_TICKET_LIST]" class="menu-link">
                            <em class='bx bx-purchase-tag-alt'></em>
                            <span class="menu-title">Support Ticket</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="javascript:void;"
                            [routerLink]="[URLConstants.PUSH_NOTIFICATION]" class="menu-link">
                            <em class='bx bx-notification'></em>
                            <span class="menu-title">Push Notifications</span>
                        </a>
                    </li>

                    <li class="settign-menu menu-list-item has-child-submenu level1"
                        (click)="openCloseSubMenu('subSettingsMenu')"
                        [ngClass]="subSettingsMenu ? 'submenu-open' : null">
                        <a style="cursor: pointer;" class="menu-link">
                            <em class='bx bx-cog'></em>
                            <span class="menu-title">Settings</span>
                            <em class="bx bxs-chevron-right"></em>
                        </a>
                        <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subSettingsMenu ? 'show' : null">
                            <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subSettingsMenu ? 'show' : null">
                                <li class="menu-list-item">
                                    <a id="generalSettings" (click)="closeSidebarMenuItem('generalSettings');"
                                        [routerLink]="[URLConstants.SETTING_GENERAL]" class="menu-link">
                                        <em class='bx bxs-right-arrow-alt'></em>
                                        <span class="menu-title">General Settings</span>
                                    </a>
                                </li>
                                <li class="menu-list-item">
                                    <a id="amountSettings" (click)="closeSidebarMenuItem('amountSettings');"
                                        [routerLink]="[URLConstants.AMOUNT_SETTINGS]" class="menu-link">
                                        <em class='bx bxs-right-arrow-alt'></em>
                                        <span class="menu-title">Amount Settings</span>
                                    </a>
                                </li>
                                <!-- <li class="menu-list-item child-menu level2">
                                    <a id="socialMedia" (click)="closeSidebarMenuItem('socialMedia');" [routerLink]="[URLConstants.SETTING_SOCIALMEDIA]" class="menu-link">
                                        <em class='bx bxs-right-arrow-alt'></em>
                                        <span class="menu-title">Social Media</span>
                                    </a>
        
                                </li> -->
                                <!-- <li class="menu-list-item">
                                    <a id="paymentGateway" (click)="closeSidebarMenuItem('paymentGateway');" [routerLink]="[URLConstants.SETTING_PAYMENTGATEWAY]" class="menu-link">
                                        <em class='bx bxs-right-arrow-alt'></em>
                                        <span class="menu-title">Payment Gateway SDK Details</span>
                                    </a>
                                </li> -->
                                <li class="menu-list-item">
                                    <a id="smtp" (click)="closeSidebarMenuItem('smtp');"
                                        [routerLink]="[URLConstants.SETTING_SMTPSMS]" class="menu-link">
                                        <em class='bx bxs-right-arrow-alt'></em>
                                        <span class="menu-title">SMTP Details</span>
                                    </a>
                                </li>
                                <!-- <li class="menu-list-item">
                                    <a id="notification" (click)="closeSidebarMenuItem('notification');" [routerLink]="[URLConstants.SETTING_EMAILNOTIFICATION]" class="menu-link">
                                        <em class='bx bxs-right-arrow-alt'></em>
                                        <span class="menu-title">Email Notification Settings</span>
                                    </a>
                                </li> -->
                            </ul>
                        </ul>
                    </li>
                </ul>
            </div>
        </ng-container>

        <!-- ** Vendor ** -->
        <ng-container *ngIf="roles==='vendor'">
            <div class="sidebar-menu">
                <ul class="sidebar-menu-list">
                    <li class="menu-list-item has-child-submenu level1">
                        <a (click)="closeSidebar()" (click)="openCloseSubMenu('dashboard')" href="#" class="menu-link">
                            <em class='bx bx-home-alt'></em>
                            <span class="menu-title">Dashboard</span>
                        </a>

                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html"
                            [routerLink]="[URLConstants.MENU_CATEGORY]" class="menu-link">
                        <em class='bx bx-check-circle'></em>
                            <span class="menu-title">Menu-Category</span>
                        </a>
                    </li>

                    <!-- <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subManageMenu')" [ngClass]="subManageMenu ? 'submenu-open' : null">
                        <a style="cursor: pointer;" class="menu-link">
                            <i class='bx bx-link-alt'></i>
                            <span class="menu-title">Menu Category</span>
                            <i class='bx bxs-chevron-right'></i>
                        </a>
                        <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subManageMenu ? 'show' : null">
                            
                            <li class="menu-list-item">
                                <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.MENU_CATEGORY]"class="menu-link">
                                    <i class='bx bx-check-circle'></i>
                                    <span class="menu-title">Category</span>
                                </a>
                            </li>
                        
                       </ul>
                    </li> -->
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.PRODUCT_MANAGEMENT]"
                            (click)="openCloseSubMenu('subProductMenu')"
                            [ngClass]="subProductMenu ? 'submenu-open' : null" class="menu-link">
                            <em class='bx bx-cube '></em>
                            <span class="menu-title">Product Management</span>
                        </a>
                    </li>

                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.MEAL_DEALS]"
                            (click)="openCloseSubMenu('subProductMenu')"
                            [ngClass]="subProductMenu ? 'submenu-open' : null" class="menu-link">
                            <em class='bx bx-message-square-check'></em>
                            <span class="menu-title">Meal Deals</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.COUPAN]" class="menu-link">
                            <em class='bx bx-badge-check'></em>
                            <span class="menu-title">Loyalty</span>
                        </a>
                    </li>
                    <li class="menu-list-item has-child-submenu level1">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.ORDER]" href="#" class="menu-link">
                            <em class='bx bx-store-alt'></em>
                            <span class="menu-title">Orders</span>
                        </a>

                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.REVIEW]"
                            class="menu-link">
                            <em class='bx bx-target-lock'></em>
                            <span class="menu-title">Reviews </span>
                        </a>
                    </li>

                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.NOTIFY]"
                            class="menu-link">
                            <em class='bx bx-notification'></em>
                            <span class="menu-title">Notifications </span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.EARNING]"
                            class="menu-link">
                            <em class='bx  bx-line-chart  '></em>
                            <span class="menu-title">Earnings </span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html"
                            [routerLink]="[URLConstants.CREATE_TICKET]" class="menu-link">
                            <em class='bx bx-wrench'></em>
                            <span class="menu-title">Support </span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.FAQS_VENDOR]" class="menu-link">
                            <em class='bx bxs-right-arrow-alt'></em>
                            <span class="menu-title">FAQ's</span>
                        </a>
                    </li>

                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html"
                            [routerLink]="[URLConstants.VENDOR_PROFILE]" class="menu-link">
                            <em class='bx bx-user'></em>
                            <span class="menu-title">My Profile </span>
                        </a>
                    </li>

                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.WALKIN_CUSTOMER]" class="menu-link">
                            <em class='bx bxs-user-circle'></em>
                            <span class="menu-title">Walk-in customer</span>
                        </a>
                    </li>
                </ul>
            </div>
        </ng-container>
    </div>

</aside>