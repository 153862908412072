<div class="main-content-area">
  <div class="main-content-block">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">

        <li class="content-header-title">Support</li>
        <li class="breadcrumb-item">
          <a [routerLink]="URLConstants.DASHBOARD"><em class="bx bx-home-alt"></em></a>
        </li>
        <!-- <li class="breadcrumb-item" [routerLink]="URLConstants.VIEW_TICKET" aria-current="page">
          Support list
        </li> -->
        <li class="breadcrumb-item active" aria-current="page">
          Create Ticket
        </li>
      </ol>
    </nav>
    <div class="card notification-card heading-card p-0">

      <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
        <div class="icon d-flex align-items-center justify-content-center mr-2"><em class='bx bxs-coupon'></em></div>
        <div class="text">
          <h5 class="mb-0 text Uppercase">Create Ticket</h5>
        </div>
      </div>
      <form class="heading-card-body" (ngSubmit)="CreateTicket(CreateTicketForm,ticketFormData)" #CreateTicketForm="ngForm" novalidate>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group align-items-center mb-md-5 mb-3 user-details">
              <label class="mb-0">
                Subject <sup class="bx bxs-star danger"></sup></label>
              <input class="form-control" type="text" name="subject" [(ngModel)]="ticketFormData.subject" id="subject"
                #subject="ngModel" autocomplete="off" required  placeholder="Enter subject"/>
              <div *ngIf="submitted && subject.invalid" class="error-block">
                <div *ngIf="subject.errors.required">
                  Please enter subject
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group align-items-center mb-md-5 mb-3 user-details">
              <label class="mb-0">
                question <sup class="bx bxs-star danger"></sup></label>
              <input class="form-control" type="text" name="question" [(ngModel)]="ticketFormData.question"
                id="question" #question="ngModel" autocomplete="off" minlength="3" maxlength="50" placeholder="Enter question" required />
              <div *ngIf="submitted && question.invalid" class="error-block">
                <div *ngIf="question.errors.required">
                  Please enter question
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="text-right mt-3 mb-4">
          <button class="btn btn-primary mr-2" [disabled]="!CreateTicketForm">Save</button>
          <button type="button" class="btn btn-outline-primary" [routerLink]="URLConstants.DASHBOARD">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>