<div class="main-content-area">
  <div class="main-content-block">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="content-header-title">Cuisine Details</li>
        <li class="breadcrumb-item">
          <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
        </li>
        <li class="breadcrumb-item" [routerLink]="[URLConstants.CUISINE]">Cuisine</li>

        <li class="breadcrumb-item active" aria-current="page">
          View
        </li>
      </ol>
    </nav>
    <div class="card cuisine-card mb-3">
      <h3 class="mb-4">View Details</h3>
      <div class="row">
        <div class="col-md-4">
          <a [href]="cmsDetail.image?cmsDetail.image:'../../../assets/images/default-upload.png'" data-fancybox
            class="fancybox">
            <img [src]="cmsDetail.image?cmsDetail.image:'../../../assets/images/default-upload.png'"
              class="img-thumbnail p-3" alt="..."></a>
        </div>
        <div class="col-md-8">
          <div class="card-body p-0">
            <div>
              <p class="card-text" style="font-size: 17px;">Name:&nbsp;{{cmsDetail.name|uppercase}}</p>
              <p class="card-text" style="font-size: 17px;">Created On: &nbsp; {{cmsDetail.createdAt |
                date:DATE_PATTERN}}</p>
            </div>
            <p class="card-text"></p>
            <p class="card-text"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right submit-btn-wrap mt-3 mb-4">
      <button type="submit" class="btn btn btn-outline-primary" [routerLink]="[URLConstants.CUISINE]">Cancel</button>
    </div>

  </div>
</div>