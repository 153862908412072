// environment
import { environment } from 'src/environments/environment';
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';

// interface
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { DATE_PATTERN } from '../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss']
})
export class ViewDetailsComponent implements OnInit {
  DATE_PATTERN = DATE_PATTERN;
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  uploadImageUrl = environment.uploadImgUrl;
  public CmsId: any;
  //uploadImgUrl = environment.uploadImgUrl;
  constructor(public commonService: CommonService, public getPermissionsService: GetPermissionsService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params) => { this.CmsId = params.id; });

    this.setBreadcrumbs();
    this.getPageDetails();

  }

  ngOnInit() { }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'View Details', url: null },
    ];
    this.subHeaderData = {
      title: 'View Details',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Get CMS Page Details
  @Parameter   : NA
  @Return      : cmsDetail
  /*************************************************************/
  public cmsDetail: any = {
    createdAt: '',
    image: '',
    isDeleted: '',
    name: '',
    updatedAt: '',
    userId: '',
    _id: ''
  };
  getPageDetails() {
    this.commonService.callApi(this.callAPIConstants.DetailCuisine + this.CmsId, '', 'get', false, false).then((success) => {
      if (success.status === 1) {
        this.cmsDetail = success.data;
        this.cmsDetail.image = this.uploadImageUrl + success.data.image;
      }
    });
  }
  /*************************************************************/


}
