import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// routing
import { FaqRoutingModule } from './faq-routing.module';

// shared
import { SharedModule } from './../../../shared/shared.module';

// component
import { ListComponent } from './list/list.component';
import { EditDetailsComponent } from './add-edit-faq-category/add-edit-faq-category.component';
import { EditCategoryComponent } from './add-edit-faq/add-edit-faq.component';


@NgModule({
  declarations: [ListComponent, EditDetailsComponent, EditCategoryComponent],
  imports: [
    CommonModule,
    FaqRoutingModule, SharedModule
  ]
})
export class FaqModule { }
