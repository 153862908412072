import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Auth Guard
import { CanLoginActivate, CanAuthActivate, isProfileCompleted } from './common/service/auth-guard';

// Component
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from './public/login/login.component';
import { RegisterComponent } from './public/register/register.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { LoginWithMobileComponent } from './public/login-with-mobile/login-with-mobile.component';
import { LoginWithEmailComponent } from './public/login-with-email/login-with-email.component';
import { SocialLoginComponent } from './public/social-login/social-login.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { MasterComponent } from './modules/master/master.component';

// module
import { SharedModule } from './shared/shared.module';
import { MealDealModule } from './modules/meal-deal/meal-deal.module';
import { ProductsModule } from './modules/products/products.module';
import { FaqModule } from './modules/content-management/faq/faq.module';
import { VendorProfileModule } from './modules/vendor-profile/vendor-profile.module';
import { CategoryModule } from './modules/category/category.module';
import { CuisineModule } from './modules/cuisine/cuisine.module';
import { ManageRolesModule } from './modules/manage-roles/manage-roles.module';
import { AttributeModule } from './modules/attribute/attribute.module';
import { TicketManagementModule } from './modules/ticket-management/ticket-management.module';
import { NotificationModule } from './modules/notification/notification.module';
import { ManageOrderModule } from './modules/manage-order/manage-order.module';
import { ReviewModule } from './modules/review/review.module';
import { OrderModule } from './modules/order/order.module';
import { EarningModule } from './modules/earning/earning.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [CanLoginActivate],
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'LoginWithMobile',
    canActivate: [CanLoginActivate],
    component: LoginWithMobileComponent,
    pathMatch: 'full',
  },
  {
    path: 'register',
    canActivate: [CanLoginActivate],
    component: RegisterComponent,
    pathMatch: 'full',
  },
  {
    path: 'forgot-password',
    canActivate: [CanLoginActivate],
    component: ForgotPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'reset-password',
    canActivate: [CanLoginActivate],
    component: ResetPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'admin-reset-password',
    canActivate: [CanLoginActivate],
    component: ResetPasswordComponent,
    pathMatch: 'full',

  },
  {
    path: 'social-login',
    canActivate: [CanLoginActivate],
    component: SocialLoginComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [isProfileCompleted],
        loadChildren: () => DashboardModule,
      },
      {
        path: 'master',
        loadChildren: () =>
          import('./modules/master/master.module').then(
            (mod) => mod.MasterModule
          ),
      },
      { path: 'walkin-customer',
      canActivate: [isProfileCompleted],
       loadChildren: () =>
        import('./modules/walkin-customer/walkin-customer.module').then(
          m => m.WalkinCustomerModule
          ) 
        },

      {
        path: 'users',
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import('./modules/user-management/users/users.module').then(
            (mod) => mod.UsersModule
          ),
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile.module')
          .then((m) => m.MyProfileModule),
      },
      {
        path: 'setting',
        loadChildren: () => import('./modules/setting/setting.module')
          .then(m => m.SettingModule)
      },
      {
        path: 'content',
        loadChildren: () =>
          import('./modules/content-management/content-management.module').then(
            (m) => m.ContentManagementModule
          ),
      },
      {
        path: 'email-template',
        // canActivate: [CanViewListActivate],
        data: { role: 'EmailTemplate' },
        loadChildren: () => import('./modules/email-management/email-templates/email-templates.module')
          .then((m) => m.EmailTemplatesModule),
      },
      { path: 'v-profile', loadChildren: () => import('./modules/v-profile/v-profile.module').then(m => m.VProfileModule) },
      { path: 'vprofile', loadChildren: () => import('./modules/vprofile/vprofile.module').then(m => m.VprofileModule) },
      { path: 'review', canActivate: [isProfileCompleted], loadChildren: () => ReviewModule },
      { path: 'order', canActivate: [isProfileCompleted], loadChildren: () => OrderModule },
      { path: 'notifications', canActivate: [isProfileCompleted], loadChildren: () => NotificationModule },
      { path: 'mealDeal', canActivate: [isProfileCompleted], loadChildren: () => MealDealModule },
      { path: 'earning', canActivate: [isProfileCompleted], loadChildren: () => EarningModule },
      { path: 'cuisine', canActivate: [isProfileCompleted], loadChildren: () => CuisineModule },
      { path: 'category', canActivate: [isProfileCompleted], loadChildren: () => CategoryModule },
      { path: 'products', canActivate: [isProfileCompleted], loadChildren: () => ProductsModule },
      { path: 'attribute', canActivate: [isProfileCompleted], loadChildren: () => AttributeModule },
      { path: 'faq', canActivate: [isProfileCompleted], loadChildren: () => FaqModule },
      {
        path: 'push-notification', canActivate: [isProfileCompleted],
        loadChildren: () => import('./modules/push-notification/push-notification.module').then(m => m.PushNotificationModule)
      },
      { path: 'manage-order', canActivate: [isProfileCompleted], loadChildren: () => ManageOrderModule },
      {
        path: 'users', canActivate: [isProfileCompleted],
        loadChildren: () => import('./modules/user-management/users/users.module').then((m) => m.UsersModule)
      },
      { path: 'coupon-management', canActivate: [isProfileCompleted], loadChildren: () => import('./modules/coupon-management/coupon-management.module').then(m => m.CouponManagementModule) },
      { path: 'cms', loadChildren: () => import('./modules/content-management/cms/cms.module').then(m => m.CmsModule) },
      { path: 'manage-roles', loadChildren: () => ManageRolesModule },
      {
        path: 'admin-users',
        loadChildren: () => import('./modules/user-management/admin-users/admin-users.module').then((m) => m.AdminUsersModule)
      },
      { path: 'vendors', loadChildren: () => import('./modules/vendors/vendors.module').then(m => m.VendorsModule) },
      { path: 'drivers', loadChildren: () => import('./modules/drivers/drivers.module').then(m => m.DriversModule) },
      { path: 'ticket-management', canActivate: [isProfileCompleted], loadChildren: () => TicketManagementModule },
      {
        path: 'waste-management',
        loadChildren: () => import('./modules/waste-management/waste-management.module').then(m => m.WasteManagementModule)
      },
      { path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule) },
      { path: 'payouts', loadChildren: () => import('./modules/payouts/payouts.module').then(m => m.PayoutsModule) },
      { path: 'vendor-profile', loadChildren: () => VendorProfileModule },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
 
 
  
  

  { path: 'agm-map', loadChildren: () => import('./modules/agm-map/agm-map.module').then(m => m.AgmMapModule) },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },

];

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginWithMobileComponent,
    LoginWithEmailComponent,
    SocialLoginComponent,
    MainComponent,
    MasterComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    // NgOtpInputModule,
    // NgxIntlTelInputModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
  providers: [
    CanAuthActivate,
    CanLoginActivate,
  ],
})
export class AppRoutingModule { }

export const AppRoutingComponents = [
];
