import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';

// interfaces
import { IBreadcrumbs, ITableSetupData } from '../../../common/interfaces/interfaces';

// constants
// import { defaultPermissionConstants } from '../../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import{ResponsiveDataTableComponent} from '../../../shared/components/responsive-data-table/responsive-data-table.component'
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  URLConstants = URLConstants;
  public accessPermission: any;

  constructor(private getPermissionsService: GetPermissionsService, private commonService: CommonService) {
   
    // START: Role Permission //
    // this.accessPermission = defaultPermissionConstants;
    // END: Role Permission //
  }

  ngOnInit() { this.setDTableInitialData(); }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
 
  /*************************************************************/

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };
  setDTableInitialData() {
    const tempData = [
      // { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true },
      { type: 'image', colName: 'Image', colFieldname: 'userInfo', isObj:'photo', isVisible: true, columnVisibility: true },
      { type: 'text', colName: 'Name', colFieldname: 'userInfo', isObj:'fullName', isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'text', colName: 'comment', colFieldname: 'comment', isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'rating', colName: 'Rating', colFieldname: 'rating', sort: true, columnVisibility: true, isVisible: true, filter: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: false },
    ];
    // if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type ='reviewlist';
    this.tableSetupData.actions = [
      {
        id: 5, buttonTitle: 'add', tooltip: 'View', class: 'bx bx-show-alt',
        type: 'icon',
      },
      
    ];
    this.tableSetupData.params = { deleteParams: '_id', statusParams: '_id' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: false,
      showExport: false,
      showColumnHide: true,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: true,
    };
  }
  /*********************************************************************************************/



}
