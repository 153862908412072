import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ThemeService } from 'src/app/common/service/theme/theme.service';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: []
})
export class ResetPasswordComponent implements OnInit {
  // Variable declarations
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  fieldTextType: boolean;
  public type;
  public email;
  public resetPassword: any = {};
  public password: any = {};
  public submitted = false;

  constructor(private themeService: ThemeService,
    private localStorageService: LocalStorageService, private router: Router,
    private commonService: CommonService, public showErrorService: ShowErrorService
  ) {
    this.type = this.router.parseUrl(this.router.url).queryParams['type'];
    this.email = this.router.parseUrl(this.router.url).queryParams['email'];
  }

  ngOnInit(): void {
    const isDark = this.localStorageService.getToken('theme') == "Dark" ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark) { document.getElementById("resetPassSwitchTheme").setAttribute('checked', ''); }
  }
  /*************************************************************
 @Purpose     : reset password
 @Parameter   : NA
 @Return      : url
 /*************************************************************/
  onSubmitPassword(passwordForm, user) {
    this.submitted = true;
    if (passwordForm.valid) {
      if (user.password !== user.confirmpassword) { return false; }
      let getMethod: any;
      if (this.type === 'admin') {
        getMethod = this.callAPIConstants.ResetPassword;
      }
      if (this.email && this.type === 'vendor') {
        getMethod = this.callAPIConstants.UserResetpass;
      }
      const data = { email: this.email, password: user.password, token: user.code };
      this.commonService.callApi(getMethod, data, 'post', true, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
          this.showErrorService.popToast('error', 'Invalid Token!');
        }
      });
    }
  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}


