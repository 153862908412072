<style>
    .color-squre {
        height: 40px;
        width: 40px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }

    .star {
        position: relative;
        display: inline-block;
        font-size: 3rem;
        color: #d3d3d3;
    }

    .full {
        color: red;
    }

    .half {
        position: absolute;
        display: inline-block;
        overflow: hidden;
        color: red;
    }

    .color-squre-mini {
        height: 10px;
        width: 10px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }

    .color-primary {
        position: relative;
    }

    .color-primary .color-squre {
        background: #5A4FC4;
    }

    .color-secound .color-squre {
        background: #F19A64;
    }

    .example-list {
        width: 500px;
        max-width: 100%;
        border: solid 1px #ccc;
        min-height: 60px;
        display: block;
        background: white;
        border-radius: 4px;
        overflow: hidden;
    }

    .example-box {
        padding: 20px 10px;
        border-bottom: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        background: white;
        font-size: 14px;
    }

    .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .cdk-drag-placeholder {
        opacity: 0;
    }

    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .example-box:last-child {
        border: none;
    }

    .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .download-dropdown .download-dropdown-menu {
        width: 100%;
        color: var(--dt-text-color);
        top: 3rem !important;
        position: absolute;
        right: 0;
        z-index: 1000;
        float: left;
        min-width: 660px;
        margin: .125rem 0 0;
        font-size: 1rem;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: .25rem;
        animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
        border-radius: 5px;
        box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
        background: var(--input-bg);
        display: none;
    }

    .left-col {
        flex: 0 0 20%;
        min-width: 175px;
        height: 350px;
        overflow-y: auto;
    }

    .left-col .list-group-item {
        border: none;
        border-radius: 0;
        align-items: center;
        padding: 15px 10px 15px 20px;
        background: var(--input-bg);
    }

    .left-col .list-group-item .handle {
        margin-right: 10px;
    }

    .left-col .list-group-item .handle .custom-checkbox label {
        margin: 0;
        font-size: 0.875rem !important;
        line-height: 20px !important;
        padding-left: 38px;
        color: var(--input-color) !important;
        font-weight: 600 !important;
    }

    .right-col .col-left {
        flex: 0 0 65%;
        margin-right: 1rem;
    }

    .right-col .col-right {
        flex: 0 0 auto;
    }

    .right-col .col-right .right-col-list {
        max-height: 200px;
        overflow: auto;
    }

    .template-list-outer {
        height: 220px;
        overflow-y: auto;
    }

    .template-list-outer .template-list .list-group-item {
        padding-top: 9px;
        padding-bottom: 9px;
        border: 0;
    }

    .download-dropdown.open .download-dropdown-menu {
        display: block;
    }

    .bottom-buttons button {
        min-width: 90px;
        color: var(--text-color);
        border-color: var(--border-color);
        background: var(--bg-white);
    }

    .list-group-item {
        border: none;
        border-radius: 0;
        align-items: center;
        padding: 15px 10px;
        background: var(--input-bg)
    }

    .list-group-item label {
        margin: 0;
        font-size: 0.875rem !important;
        line-height: 20px !important;
        padding-left: 38px;
        color: var(--input-color) !important;
    }

    .filter-form {
        position: relative;
    }

    .field-form {
        width: calc(100% - 100px);
    }

    .filter-form .row-add {
        position: absolute;
        right: 10px;
        bottom: 17px;
    }

    .filter-form .row-add .bx {
        font-size: 28px;
    }

    .save-filter {
        display: flex;
        align-items: center;
    }

    .caption-image {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        display: inline-block;
    }

    .caption-image img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
        top: 21px;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before {
        top: 50%;
        transform: translateY(-50%);
    }
</style>
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!showSkeletonLoader1">
    <div class="card animated fadeIn">
        <div class="card-header">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="card-body">
            <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END : Show Skeleton Loader -->

<div class="content-wrapper dataTables_wrapper" [hidden]="showSkeletonLoader1">
    <!-- Begin: TABLE HEADER (filter/download/search buttons)-->
    <div *ngIf="this.conditions.showTableHeader" class="user-listing-filterOptions mb-4 d-block">
        <div class="row mb-3">
            <div class="col-sm-8 position-static">
                <div class="row">
                    <div class="col-sm-9 position-static">
                        <div class="left-buttons d-flex search-input-section">
                            <!-- Begin: search -->
                            <label class="mr-2 mb-0" *ngIf="this.conditions.search">
                                <div class="search-input-wrapper position-relative">
                                    <form>
                                        <div class="form-group position-relative mb-0">
                                            <em class="bx bx-search"></em>
                                            <input type="text" class="form-control icon-search" placeholder="Search"
                                                name="search" #search="ngModel" [(ngModel)]="searchText"
                                                autocomplete="off" (keyup)="searchData($event)">
                                            <div class="remove-icon"><em class="bx bx-x" *ngIf="searchText"
                                                    (click)="removeSearch()"></em></div>
                                        </div>
                                    </form>
                                </div>
                            </label>
                            <!-- End: search -->

                            <!-- Begin: Filter -->
                            <div *ngIf="this.conditions.showFilter "
                                class="custom-dropdown filter-data-dropdown position-static mx-0 mr-2 px-0"
                                [ngClass]="status ? 'open' : ''">
                                <button (click)="openFilter()"
                                    class="btn btn-default dropdown-toggle minW-md-0 btn-bg-white mx-0" type="button">
                                    <em class="bx bx-filter d-lg-none"></em>
                                    <span class="d-none d-sm-none d-lg-inline-block">Filter Data</span>
                                </button>
                                <div class="custom-dropdown-menu filter-dropdwon w-100 dropdown-icon"
                                    (click)="$event.stopPropagation()">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12 mb-3">
                                                        <!-- saved Filters -->
                                                        <div *ngFor="let i of savedFilters"
                                                            class="save-filter col-md-12 mt-2">
                                                            <span [style.background]="i.color" class="color-squre-mini"
                                                                [(colorPicker)]="i.color" [disabled]="true"
                                                                [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                            <span class="ml-2"
                                                                (click)="applySavedFilter('',i)">{{i.description}}
                                                            </span>
                                                            <span
                                                                class="bx bx-trash-alt mr-1 cursor-pointer text-danger ml-2"
                                                                aria-hidden="true" tooltip="Delete" placement="bottom"
                                                                (click)="CallAPIdeleteSaveFilter(i)"></span>
                                                            <span class="bx bx-edit cursor-pointer ml-2"
                                                                aria-hidden="true" tooltip="Edit" placement="bottom"
                                                                (click)="editSavedFilter(i)"></span>
                                                        </div>
                                                        <div *ngIf="!savedFilters?.length">
                                                            There are no saved filters
                                                        </div>
                                                        <!-- saved Filters -->

                                                    </div>
                                                    <form #filterForm="ngForm" class="filter-form">
                                                        <div class="col-md-12 pb-3">

                                                            <div class="filter-details-form"
                                                                *ngFor="let i of filteredArr; let index=index">
                                                                <div class="field-form">
                                                                    <div class="and-or-data mt-4 mb-4">
                                                                        <button
                                                                            [ngClass]="condition =='$and'?'btn btn-primary':'btn btn-outline-primary'"
                                                                            (click)="filterConditionChange('$and')">And</button>
                                                                        <button
                                                                            [ngClass]="condition =='$or'?'btn btn-primary':'btn btn-outline-primary'"
                                                                            (click)="filterConditionChange('$or')">Or</button>
                                                                    </div>
                                                                    <div class="target-form">
                                                                        <div class="target-details">
                                                                            <div class="row select-field-wrap">
                                                                                <div class="select-field-block">
                                                                                    <ng-select [items]="filterCols"
                                                                                        [(ngModel)]="i.key"
                                                                                        bindLabel="colName"
                                                                                        bindValue="colFieldname"
                                                                                        [ngClass]="((submitted || colFieldname.touched) && colFieldname.invalid)?'has-danger':''"
                                                                                        name="colFieldname{{index}}"
                                                                                        placeholder="Select field"
                                                                                        #colFieldname="ngModel"
                                                                                        [multiple]="false" required
                                                                                        [hideSelected]="true">
                                                                                    </ng-select>
                                                                                    <div class="error-block text-left"
                                                                                        *ngIf="((submitted || colFieldname.touched) && colFieldname.invalid)">
                                                                                        Please select field
                                                                                    </div>
                                                                                </div>
                                                                                <div class="select-field-block">
                                                                                    <ng-select class="ng-select-line"
                                                                                        [items]="filterContentList"
                                                                                        bindValue="value"
                                                                                        bindLabel="name"
                                                                                        placeholder="Select type"
                                                                                        [clearable]='true'
                                                                                        [multiple]="false"
                                                                                        [dropdownPosition]="'auto'"
                                                                                        [closeOnSelect]="true"
                                                                                        name="contains{{index}}"
                                                                                        #contains="ngModel" required
                                                                                        [(ngModel)]="i.type"
                                                                                        (change)="filterContentListChanges(i)">
                                                                                    </ng-select>
                                                                                    <div class="error-block text-left"
                                                                                        *ngIf="((submitted || contains.touched) && contains.invalid)">
                                                                                        Please select type
                                                                                    </div>
                                                                                </div>
                                                                                <!-- Contains -->
                                                                                <div class="select-field-block"
                                                                                    *ngIf="i.type !== 'date'">

                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        placeholder="Enter search value"
                                                                                        name="value{{index}}"
                                                                                        [(ngModel)]="i.value"
                                                                                        autocomplete="off"
                                                                                        (keydown)=" (i.type == 'greaterThan' || i.type=='lessThan' ? restrictKeyPressService.AllowNumbers($event) : '')"
                                                                                        #value="ngModel" required>
                                                                                    <div class="error-block text-left"
                                                                                        *ngIf="((submitted || value.touched) && value.invalid)">
                                                                                        Please enter search value
                                                                                    </div>

                                                                                </div>
                                                                                <div class="select-field-block"
                                                                                    *ngIf="i.type === 'date'">

                                                                                    <input
                                                                                        placeholder="Select date range"
                                                                                        type="text" class="form-control"
                                                                                        bsDaterangepicker
                                                                                        [(ngModel)]="i.dateRange"
                                                                                        name="daterange{{index}}"
                                                                                        #daterange="ngModel"
                                                                                        (bsValueChange)="filterDateRangeSelection($event, index)"
                                                                                        required
                                                                                        [bsConfig]="{ selectWeek: false, showWeekNumbers: false, containerClass: 'theme-red'}">
                                                                                    <div class="error-block text-left"
                                                                                        *ngIf="((submitted || daterange?.touched) && daterange?.invalid)">
                                                                                        Please select date range
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="row-add">
                                                                    <em class="bx bxs-plus-circle  text-success"
                                                                        (click)="addObjForFilterArray()"></em>
                                                                    <em class="bx bxs-minus-circle mr-1 text-danger"
                                                                        *ngIf="filteredArr.length > 1"
                                                                        (click)="removeObjFromFilterArray(index)"></em>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-md-12 mb-2 mt-4">
                                                            <div class="d-flex align-items-start flex-wrap">
                                                                <button type="button" class="btn btn-primary mx-0 mr-2"
                                                                    (click)="applySavedFilter(filterForm)"
                                                                    [disabled]="!filteredArr[0].value">Search</button>
                                                                <button class="btn btn-outline-primary"
                                                                    (click)="clearFilter(true)">Clear</button>
                                                                <div class="form-group d-flex flex-column mx-sm-2 mb-0">

                                                                    <input type="text" class="filtersave form-control"
                                                                        placeholder="Enter name to save filter"
                                                                        name="description" #description="ngModel"
                                                                        [(ngModel)]="options.description1"
                                                                        autocomplete="off" required>
                                                                    <div class="error-block text-left"
                                                                        *ngIf="((filterSaveSubmitted || description.touched || description.dirty) && description.invalid)">
                                                                        Please enter name to save filter
                                                                    </div>

                                                                </div>

                                                                <button class="btn btn-primary"
                                                                    (click)="toSaveFilter(filterForm, options.description1, filterColor)">Save
                                                                    & Search</button>
                                                                <!-- <span [style.background]="filterColor" [cpPosition]="'bottom-left'" class="color-squre mb-2" [(colorPicker)]="filterColor" [cpOKButton]="true" [cpSaveClickOutside]="false" [cpOKButtonClass]="'btn btn-primary btn-md'"></span> -->
                                                                <!-- <div class="dropdown colorbox m-0">
                                                                <button (click)=" statusFilterColorPicker = !statusFilterColorPicker" class="btn dropdown-toggle dropdown-btn" type="button" id="dropdownMenuButton2" data-toggle="dropdown1" aria-haspopup="true" aria-expanded="false">
                                                                    <span class="color-box"></span>
                                                                </button>
                                                                <ul class="dropdown-menu dropdown-menu-right dropdown-icon" [ngClass]="statusFilterColorPicker ? 'show' : ''" aria-labelledby="dropdownMenuButton2">
                                                                    <li (click)="selectColor('yellow')"><a class="dropdown-item"><span class="color-shade bg-yellow"></span><span
                                                                                class="color-text">Yellow</span></a></li>
                                                                    <li (click)="selectColor('green')"><a class="dropdown-item"><span class="color-shade bg-green"></span><span
                                                                                class="color-text">Green</span></a></li>
                                                                    <li (click)="selectColor('purple')"><a class="dropdown-item"><span class="color-shade bg-purple"></span><span
                                                                                class="color-text">Purple</span></a></li>
                                                                    <li (click)="selectColor('rose')"><a class="dropdown-item"><span class="color-shade bg-rose"></span><span
                                                                                class="color-text">Rose</span></a></li>
                                                                    <li (click)="selectColor('grey')"><a class="dropdown-item"><span class="color-shade bg-grey"></span><span
                                                                                    class="color-text">Grey</span></a></li>
                                                                </ul>
                                                            </div> -->
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End: Filter -->

                            <!-- Begin: Bulk change status and delete -->
                            <div *ngIf="this.conditions.showApplyStatus && this.selectedUserList.length" class="d-flex">
                                <button type="button" class="btn btn-default delete-btn minW-0 btn-bg-white ml-0 mr-2"
                                    (click)="bulkChangeStatusAndDelete('delete')">
                                    <em class='bx bx-trash-alt'></em>
                                </button>
                                <button type="button" class="btn btn-default switch-btn minW-0 mx-0 btn-bg-white mr-2"
                                    (click)="bulkChangeStatusAndDelete('status')">
                                    <em class='bx bx-toggle-left'></em>
                                </button>
                            </div>
                            <!-- End: Bulk change status and delete -->

                            <!-- Begin: Seperate filter according to list -->
                            <div class="mr-2" *ngIf="this.type ==='userlist'">
                                <ng-select class="edit-filter" [multiple]="false" [clearable]="true" [items]="roleList"
                                    (change)="searchOnRoleSelected($event)" bindLabel="name" bindValue="value"
                                    placeholder="Select Role">
                                </ng-select>
                            </div>
                            <ng-container *ngIf="this.type ==='faqsListing'">
                                <ng-select class="edit-filter" [multiple]="false" [clearable]="true"
                                    [items]="faqCategoryList" (change)="searchOnSelectionOfFaqCategory($event)"
                                    placeholder="Select Category">
                                </ng-select>
                            </ng-container>
                            <ng-container *ngIf="this.type ==='adminorderlist'">
                                <ng-select class="edit-filter mr-2 dropdown-w-100" [items]="userFilterlist | async"
                                    bindLabel="fullName" bindValue="_id" placeholder="Search User" [clearable]='true'
                                    [multiple]="false" [dropdownPosition]="'auto'" [closeOnSelect]="true"
                                    (change)="onSelectUserOnSearch($event)" [(ngModel)]="APIparameters.userId"
                                    [notFoundText]="'Data not found'" required [typeaheadMinLength]="10000000"
                                    [typeahead]="userTypeAheadSource" [loading]="userLoader">
                                </ng-select>
                                <ng-select class="edit-filter mr-2 dropdown-w-100" [items]="vendorFilterlist | async"
                                    bindLabel="businessName" bindValue="_id" placeholder="Search Vendor"
                                    [clearable]='true' [multiple]="false" [dropdownPosition]="'auto'"
                                    [closeOnSelect]="true" (change)="onSelectvendorOnSearch($event)"
                                    [(ngModel)]="APIparameters.vendorId" [notFoundText]="'Data not found'" required
                                    [typeaheadMinLength]="10000000" [typeahead]="vendorTypeAheadSource"
                                    [loading]="vendorLoader">
                                </ng-select>
                                <ng-select class="edit-filter dropdown-w-100" [items]="orderListArr"
                                    bindLabel="statusName" bindValue="value" placeholder="Select Status"
                                    [clearable]='true' [multiple]="false" [dropdownPosition]="'auto'"
                                    [closeOnSelect]="true" (change)="onSelectStatusInOrderListing($event)"
                                    [(ngModel)]="APIparameters.status">
                                </ng-select>
                            </ng-container>
                            <!-- End: Seperate filter according to list -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="right-buttons d-flex justify-content-end">
                    <!-- Begin: Download -->
                    <div *ngIf="this.conditions.showExport" class="download-btn download-dropdown"
                        [ngClass]="statusDownload ? 'open' : ''">
                        <button (click)="statusDownload = !statusDownload"
                            class="btn btn-default dropdown-toggle minW-md-0 btn-bg-white mr-2" type="button"
                            id="downloadDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <em class="bx bx-download d-lg-none"></em>
                            <span class="d-none d-sm-none d-lg-inline-block">Download</span>
                        </button>
                        <div class="download-dropdown-menu pt-3 pr-3 pb-3 pl-2 dropdown-menu-right dropdown-icon"
                            aria-labelledby="downloadDropdown">
                            <div class="container-fluid">
                                <div class="row flex-nowrap">
                                    <div class="left-col p-0">
                                        <ul class="list-group handle-list border-left-0 mr-2"
                                            *ngFor="let i of tableConfig?.cols" [ngClass]="(i.columnVisibility)?'':''">
                                            <li *ngIf="i.columnVisibility" class="list-group-item">
                                                <div class="custom-checkbox">
                                                    <label>
                                                        <input type="checkbox" name="status"
                                                            (change)="selectColumns(tableConfig?.cols)"
                                                            [(ngModel)]="i.isSelected">
                                                        <span></span>
                                                        {{i.colName}}
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="right-col p-0 border-top-0 border-bottom-0 border-right-0 border pl-3">
                                        <div class="row m-0">
                                            <!-- <div class="col-left">
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control" placeholder="Save Template"
                                                        [(ngModel)]="options.templateName" #templateName="ngModel">
                                                    <div class="input-group-append">
                                                        <button (click)="saveDownloadTemplate(tableConfig?.cols)"
                                                            class="btn btn-primary" type="button">Save
                                                            Template</button>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-right">
                                                <div class="dropdown colorbox m-0">
                                                    <button (click)="openCloseColorDropdown()"
                                                        class="btn dropdown-toggle dropdown-btn" type="button"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <span id="downloadDropdownColor" class="color-box"></span>
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-right dropdown-icon"
                                                        [ngClass]="statusColorPicker ? 'show' : ''"
                                                        aria-labelledby="dropdownMenuButton2">
                                                        <li (click)="selectColor('#FDAC41','yellow')"><a
                                                                class="dropdown-item"><span
                                                                    class="color-shade bg-yellow"></span><span
                                                                    class="color-text">Yellow</span></a></li>
                                                        <li (click)="selectColor('#39DA8A','green')"><a
                                                                class="dropdown-item"><span
                                                                    class="color-shade bg-green"></span><span
                                                                    class="color-text">Green</span></a></li>
                                                        <li (click)="selectColor('#b87ee5','purple')"><a
                                                                class="dropdown-item"><span
                                                                    class="color-shade bg-purple"></span><span
                                                                    class="color-text">Purple</span></a></li>
                                                        <li (click)="selectColor('#FF5B5C','rose')"><a
                                                                class="dropdown-item"><span
                                                                    class="color-shade bg-rose"></span><span
                                                                    class="color-text">Rose</span></a></li>
                                                        <li (click)="selectColor('#b3c0ce','gray')"><a
                                                                class="dropdown-item"><span
                                                                    class="color-shade bg-gray"></span><span
                                                                    class="color-text">Gray</span></a></li>
                                                        <li (click)="selectColor('#00cfdd','blue')"><a
                                                                class="dropdown-item"><span
                                                                    class="color-shade bg-skyblue"></span><span
                                                                    class="color-text">Sky Blue</span></a></li>
                                                    </ul>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row m-0 flex-column">
                                            <div class="scrollable template-list-outer right-col-list">
                                                <ul class="list-group template-list">
                                                    <li class="list-group-item" *ngFor="let temp of tempSettingsData">
                                                        {{temp.description}}
                                                        <span class="d-inline-flex align-items-center">
                                                            <a (click)="CallAPIdeleteDownloadTemplate(temp)"><em
                                                                    class='bx bx-trash-alt'></em></a>
                                                            <span class="bx bx-edit cursor-pointer ml-2"
                                                                aria-hidden="true" tooltip="Edit" placement="bottom"
                                                                (click)="editTemplate(temp)"></span>
                                                        </span>
                                                        <div *ngIf="!tempSettingsData?.length">
                                                            No Templates Found
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div
                                                class="bottom-buttons border-left-0 border-bottom-0 border-right-0 border mt-2 mb-2 pt-3">
                                                <button (click)="downloadTemplate('excel')" type="button"
                                                    class="btn border mr-2" id="ExportReporttoExcel">
                                                    <span class="mr-2">
                                                        <img class="img-fluid" src="assets/images/icon/excel-icon.svg"
                                                            alt="Excel" />
                                                    </span>
                                                    Excel
                                                </button>
                                                <button (click)="downloadTemplate('csv')" type="button"
                                                    class="btn border" id="ExportReporttoCSV">
                                                    <span class="mr-2">
                                                        <img class="img-fluid" src="assets/images/icon/csv-icon.svg"
                                                            alt="Excel" />
                                                    </span>
                                                    CSV
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- End: Download -->


                    <!-- Begin: Import -->
                    <div *ngIf="this.conditions.showImport" class="dropdown importFileDropdown custom-dropdown"
                        dropdown>
                        <button class="btn glow-secondary btn-secondary dropdown-toggle pr-4 minW-md-0" type="button"
                            aria-controls="dropdown-animated">
                            <em class="bx bxs-file-import d-lg-none"></em>
                            <span class="d-none d-sm-none d-lg-inline-block">Import File</span>
                        </button>
                        <ul class="dropdown-menu p-0 dropdown-menu-right dropdown-icon " *dropdownMenu
                            class="dropdown-menu" role="menu" aria-labelledby="dropdown-animated">
                            <li><a class="dropdown-item" href="#"><em class='bx bx-trash-alt  mr-2 icon-btn'></em>CSV
                                    File</a></li>
                            <li><a class="dropdown-item" href="#"><em class='bx bx-notepad  mr-2 icon-btn'></em>Excel
                                    File</a></li>
                        </ul>
                    </div>
                    <!-- End: Import -->

                    <!-- Begin: button without modal -->
                    <ng-container *ngIf="this.conditions.showButton && !conditions.modal">
                        <button type="button" class="nav-link  btn btn-primary listlink"
                            [routerLink]="[this.conditions.showButton.routerLink]">
                            <em class="fa fa-plus" aria-hidden="true"></em>
                            <span>{{this.conditions.showButton.buttonName}}</span>
                        </button>
                    </ng-container>
                    <!-- End: button without modal -->

                    <!-- Begin: button with modal -->
                    <ng-container *ngIf="this.conditions.showButton  && conditions.modal">
                        <button type="button" class="btn btn-primary listlink"
                            (click)="openModalForHeaderButton(WithdrawlModelTemplate)">
                            <em class="fa fa-plus" aria-hidden="true"></em>
                            <span>{{this.conditions.showButton.buttonName}}</span>
                        </button>
                    </ng-container>
                    <!-- End: button with modal -->
                </div>
            </div>
        </div>
    </div>
    <!-- End: TABLE HEADER (filter/download/search buttons)-->

    <!-- Begin: TABLE-->
    <div class="table-outer-container">
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <!-- Data Table Start -->
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" sortable-table
                        (sorted)="onSorted($event)" class="custom-table table" id="table" *ngIf="tableData?.data"
                        aria-describedby="table" width="100%">
                        <thead>
                            <tr>
                                <!-- responsive button -->
                                <!-- <th></th> -->
                                <!-- responsive button -->
                                <th [hidden]="!i.isVisible" [sortable-column]="i" [displaySortIcon]="i.sort"
                                    *ngFor="let i of tableConfig?.cols" id="th1"
                                    [ngClass]="{'text-truncate': i.type == 'html', 'align-center':i.type == 'symbol' }">
                                    <ng-container
                                        *ngIf="i.type!=='multipleSelection' && i.type!=='switch' && i.type !='columnSettings'">
                                        {{i.colName}}
                                    </ng-container>
                                    <ng-container *ngIf="i.type =='columnSettings' ">
                                        <div class=" btn-group" dropdown [insideClick]="true">
                                            <em dropdownToggle class=" fa fa-cog">
                                            </em>
                                            <ul *dropdownMenu class="dropdown-menu" role="menu">
                                                <div class="card">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="row" cdkDropList class="example-list"
                                                                (cdkDropListDropped)="cdkDropListDropped($event)">
                                                                <div *ngFor="let setting of colSettingCols"
                                                                    class="example-box" cdkDrag
                                                                    [ngClass]="(setting.columnVisibility)?'col-md-12':''">
                                                                    <ng-container *ngIf="setting.drag">
                                                                        <div class="custom-checkbox">
                                                                            <label>
                                                                                <input type="checkbox" name="status"
                                                                                    [(ngModel)]="setting.isVisible"
                                                                                    [disabled]="setting.disable">
                                                                                <span></span>
                                                                                {{setting.colName}}
                                                                            </label>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="text-right filter-btn">
                                                                    <div class="col-md-12">
                                                                        <button type="submit"
                                                                            class="nav-link btn btn-primary ml-2"
                                                                            (click)="saveColumnSettings(colSettingCols)">Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngif="this.type === 'productlist'">
                                        <div>
                                            <input type="text" class="form-control" (keyup.enter)="OnInput($event)">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="i.type=='switch'">{{i.colName}}</ng-container>
                                    <div *ngIf="i.type=='multipleSelection'" class="custom-checkbox">

                                        <label>
                                            <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                (change)="selectAllUser(i)">
                                            <span></span>
                                        </label>
                                    </div>

                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-container>

                                <tr class="animated fadeIn" *ngFor="let i of tableData?.data;let y=index;">
                                    <!-- <td></td> -->
                                    <td [ngClass]="j.type=='action'?' ':''" [hidden]="!j.isVisible"
                                        *ngFor="let j of tableConfig?.cols">

                                        <!-- multipleSelection -->
                                        <div *ngIf="j.type=='multipleSelection'" class="custom-checkbox">
                                            <ng-container *ngIf="type === 'promoCodeList';else basicMultipleSelection">
                                                <label *ngIf="i['usedInOrders'].length == 0">
                                                    <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                        (change)="selectMultipleUser(i)"><span></span>
                                                </label>
                                            </ng-container>
                                            <ng-template #basicMultipleSelection>
                                                <label><input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                        (change)="selectMultipleUser(i)"><span></span></label>
                                            </ng-template>
                                        </div>
                                        <!-- multipleSelection -->

                                        <!-- rating -->
                                        <div *ngIf="j.type=='rating'">
                                            <rating name="rating" [(ngModel)]="i[j.colFieldname]" [max]="5"
                                                [readonly]="true">
                                            </rating>
                                        </div>
                                        <!-- rating -->

                                        <!-- switch -->
                                        <div *ngIf="j.type=='switch'">
                                            <label class="switch ">
                                                <input type="checkbox" class="success" [(ngModel)]="i[j.colFieldname]"
                                                    (click)="changeStatus(i)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <!-- switch -->

                                        <!-- TicketStatus -->
                                        <div *ngIf="j.type=='TicketStatus'">
                                            <ng-select name="TicketStatus" class="ng-select-line" appendTo="body"
                                                (change)="changeStatus(i,$event)" [items]="ticketStatusList"
                                                placeholder="Select" bindLabel="name" bindValue="value"
                                                [(ngModel)]="i[j.colFieldname]" [clearable]='false' [searchable]='false'
                                                [dropdownPosition]="'auto'">
                                            </ng-select>
                                        </div>
                                        <!-- TicketStatus -->

                                        <!-- image -->
                                        <div *ngIf="j.type=='image'" class="thumb-img">

                                            <span class="caption-image" *ngIf="!j.isObj">
                                                <img [src]="i[j.colFieldname] ?  uploadImageUrl + i[j.colFieldname] : 'assets/images/default-upload.png'"
                                                    alt="no img">
                                            </span>
                                            <span class="caption-image" *ngIf="j.isObj">
                                                <img [src]="i[j.colFieldname][j.isObj] ?  uploadImageUrl + i[j.colFieldname][j.isObj] : 'assets/images/default-upload.png'"
                                                    alt="no img">
                                            </span>
                                        </div>
                                        <!-- image -->

                                        <!-- action -->
                                        <ng-container *ngIf="j.type=='action'">
                                            <div class="d-flex w-100">
                                                <div *ngFor="let k of tableConfig?.actions" class="action-icon ">
                                                    <ng-container *ngIf="k.type === 'icon'">
                                                        <span class="cursor">
                                                            <em [class]="k.class" aria-hidden="true"
                                                                [tooltip]="k.tooltip" (click)="performAction(k,i)">
                                                            </em>
                                                        </span>
                                                    </ng-container>
                                                    <div *ngIf="k.type === 'button'">
                                                        <ng-container>
                                                            <button type="button" [class]="k.class"
                                                                (click)="performAction(k,i)">{{k.buttonTitle}}</button>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- action -->

                                        <!-- symbol -->
                                        <div *ngIf="j.type =='symbol'" class="align-center">
                                            <span *ngIf="i[j.colFieldname] === true">&#10003;</span>
                                            <span *ngIf="i[j.colFieldname] === false">&#9747;</span>
                                        </div>
                                        <!-- symbol -->

                                        <!-- online -->
                                        <div *ngIf="j.type =='online'" class="align-center">
                                            <span *ngIf="i[j.colFieldname] === true">Yes</span>
                                            <span *ngIf="i[j.colFieldname] === false">No</span>
                                        </div>
                                        <!-- online -->

                                        <!-- text -->
                                        <div *ngIf="j.type=='text'">
                                            {{j.isObj ? i[j.colFieldname][j.isObj] : (j.isDate ? (i[j.colFieldname] |
                                            date:DATE_PATTERN) : i[j.colFieldname] ? (i[j.colFieldname]) :
                                            '--')}}
                                        </div>
                                        <!-- text -->

                                        <div *ngIf="j.type == 'button'">
                                            <!-- <ng-template *ngIf="i.isVerifyByAdmin"> -->
                                                <button [disabled]="i.emailVerificationStatus" class="btn btn-outline-primary" (click)="resendEmail(i)">Resend Email</button>
                                            <!-- </ng-template> -->
                                          </div>
                                        <!-- Slice text -->
                                        <div *ngIf="j.type=='slicetext'">
                                        {{ i[j.colFieldname] | slice:0:200}} {{"..."}}
                                         </div>
                                          <!-- Slice text -->   

                                        <!-- text -->
                                        <div *ngIf="j.type=='discount'">
                                            {{i[j.colFieldname] ? (i[j.colFieldname])+'%' : '--'}}
                                        </div>
                                        <!-- text -->

                                        <!-- router link text -->
                                        <div *ngIf="j.type=='routerLinkText'"> 
                                            <a class="text-secondary"
                                                [routerLink]="[j.routerLink, i[j.colFieldname]._id]">
                                                {{j.isObj ? i[j.colFieldname][j.isObj] : (i[j.colFieldname] ?
                                                i[j.colFieldname] :
                                                '--')}}
                                            </a>
                                        </div>
                                        <!-- router link text -->

                                          <!-- router link text New -->
                                        <div *ngIf="j.type=='routerLinkTextNew'"> 
                                            <a class="text-secondary"
                                                [routerLink]="[j.routerLink, i._id]">
                                                {{j.isObj ? i[j.colFieldname][j.isObj] : (i[j.colFieldname] ?
                                                i[j.colFieldname] :
                                                '--')}}
                                            </a>
                                        </div>
                                        <!-- router link text New-->

                                        <!-- textWithTitleCase -->
                                        <div *ngIf="j.type=='textWithTitleCase'">
                                            {{j.isObj ? i[j.colFieldname][j.isObj] : (j.isDate ? (i[j.colFieldname] |
                                            date:DATE_PATTERN) : i[j.colFieldname] ? (i[j.colFieldname] | titlecase) :
                                            '--')}}
                                        </div>
                                        <!-- textWithTitleCase -->

                                        <!-- mobileWithCountryCode -->
                                        <div *ngIf="j.type=='mobileWithCountryCode'">
                                            {{i[j.colFieldname] ? (i[j.colFieldname1]+'-'+i[j.colFieldname]) : '--'}}
                                        </div>
                                        <!-- mobileWithCountryCode -->

                                        <!-- emailWithVerification -->
                                        <div *ngIf="j.type=='emailWithVerification'"> 
                                                <em *ngIf="i.emailVerificationStatus" class="bx bx-check-circle text-success"
                                                    ></em>
                                                <em *ngIf="!i.emailVerificationStatus" class="bx bx-x-circle text-danger"
                                                    ></em>
                                                {{i[j.colFieldname]}}
                                        </div>
                                        <!-- emailWithVerification -->

                                        <!-- textBusinessCheck -->
                                        <div *ngIf="j.type=='textBusinessCheck' && i.businessName">
                                            <!-- {{j.isObj ? i[j.colFieldname][j.isObj] : (j.isDate ? (i[j.colFieldname] | date:DATE_PATTERN) : i[j.colFieldname] ? j.isVisible == 'true' : '--')}} -->
                                        </div>
                                        <!-- textBusinessCheck -->

                                        <!-- textForStatus or requestStatus or pending-->
                                        <div *ngIf="j.type=='textForStatus'" [ngClass]="getColor(i[j.colFieldname])">
                                            {{i[j.colFieldname] ? IstatusTypes[i[j.colFieldname]] : '--'}}
                                        </div>
                                        <!-- textForStatus or requestStatus or pending -->

                                        <!-- orderStatus or status or not pending-->
                                        <div *ngIf="j.type=='orderStatus' && ((type === 'adminorderlist' || type === 'userDetailAdminorderlist') ? true : i[j.colFieldname]!='pending')"
                                            [ngClass]="getColor(i[j.colFieldname])">
                                            {{i[j.colFieldname] ? IstatusTypes[i[j.colFieldname]] : '--'}}
                                        </div>
                                        <!-- orderStatus or status or not pending-->

                                        <!-- orderStatus or status or pending-->
                                        <div
                                            *ngIf="j.type=='orderStatus' && i[j.colFieldname]=='pending' && type !== 'adminorderlist' && type !== 'userDetailAdminorderlist'">
                                            <button type="button" class="btn btn-outline-primary btn-sm mx-0 mr-2"
                                                (click)="acceptRejectOrder('accept', i._id)">Accept</button>
                                            <button type="button" class="btn btn-outline-primary btn-sm mx-0 mr-2"
                                                (click)="acceptRejectOrder('reject', i._id)">Reject</button>
                                        </div>
                                        <!-- orderStatus or status or pending-->

                                        <!-- amount-->
                                        <div *ngIf="j.type=='amount'">{{i[j.colFieldname] ? (i[j.colFieldname] |
                                            currency: CUURENCY_CODE): '-'}}</div>
                                        <!-- amount-->

                                        <!-- html-->
                                        <div *ngIf="j.type=='html'" [innerHTML]="i[j.colFieldname]"></div>
                                        <!-- html-->
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <!-- Data Table End -->
                </div>
            </div>
        </div>
    </div>
    <!-- End: TABLE-->

    <!-- Begin: TABLE Footer-->
    <div class="row" *ngIf="this.conditions.showTableFooter">
        <div class="col-md-12">
            <!-- Pagination Start -->
            <div class="custom-pagination d-flex align-items-center justify-content-between"
                *ngIf="tableData.data.length">
                <div class="d-flex dataTables_length align-items-center">
                    <span class="record-count mr-1">Showing</span>
                    <ng-select class="ng-select-line" (change)="selectItemPerPage(itemsPerPage)"
                        [items]="itemsPerPageList" placeholder="Select" [(ngModel)]="itemsPerPage" [clearable]='false'
                        [searchable]='false' [dropdownPosition]="'auto'" [labelForId]="tableData.type">
                    </ng-select>
                    <span class="record-count ml-2">out of {{total}} entries</span>
                </div>
                <div class="dataTables_paginate pagination-list">
                    <pagination [itemsPerPage]='itemsPerPage' [(ngModel)]="currentPage" #customPagination
                        [totalItems]="total" (pageChanged)="pagination($event)" [maxSize]="5" previousText="Prev"
                        nextText="Next" firstText="&laquo;" lastText="&raquo;">
                    </pagination>
                </div>
            </div>
            <!-- Pagination End -->
        </div>
    </div>
    <!-- End: TABLE Footer-->
</div>


<!-- Begin: Withdraw Reuest Modal -->
<ng-template #WithdrawlModelTemplate>
    <div class="modal-header">
        <h5 class="modal-title pull-left">Request For Withdrawal</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <input type="text" id="amount" name="amount" #amount="ngModel" [(ngModel)]="withdrawlData.amount"
            (keypress)="withdrawlChangeDetection()" class="form-control validate" placeholder="Enter Amount"
            appTwoDigitDecimaNumber required>
        <div *ngIf="submitted && amount.invalid" class="error-block">
            <div *ngIf="amount.errors.required">
                Please enter amount
            </div>
        </div>
        <div *ngIf="((amount.value && submitted) && showError)" class="error-block">
            {{errortext}}
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="requestWithdrawl()" class="btn btn-primary">Send
            Request</button>
    </div>
</ng-template>
<!-- End: Withdraw Reuest Modal -->