import { SharedModule } from './../../shared/shared.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderRoutingModule } from './order-routing.module';
import { OrderComponent } from './order.component';
import { ListComponent } from './list/list.component';
import { ViewComponent } from './view/view.component';
import { AdminListComponent } from './admin-list/admin-list.component';


@NgModule({
  declarations: [OrderComponent, ListComponent, ViewComponent, AdminListComponent],
  imports: [
    CommonModule,
    OrderRoutingModule,
    SharedModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class OrderModule { }
