export const messages = {
    ERROR: {
        REQUIRED: {
            Email: 'Please enter email address',
            Password: 'Please enter password',
            Username: 'Please enter user name',
            currentPassword: 'Please enter current password',
            newPassword: 'Please enter new password',
            confirmPassword: 'Please enter confirm password',
            firstname: 'Please enter firstnName',
            lastname: 'Please enter last name',
            firstName: 'Please enter first name',
            lastName: 'Please enter last name',
            userName: 'Please enter user name',
            companyName: 'Please enter company name',
            mobile: 'Please enter mobile number',
            emailId: 'Please enter email',
            roleName: 'Please enter role',
            role: 'Please enter role',
            PhoneNo: ' Enter phone number',
            Amount: ' Enter amount',
            filterName: 'Please enter name of filter',
            emailKey: 'Please enter title',
            subject: 'Please enter subject',
            fromEmailId: 'Please enter from email address',
            tag: 'Please add tag',
            emailTemplateId: 'Please select event',
            host: 'Please enter host',
            port: 'Please enter port',
            defaultFromEmail: 'Please enter from email',
            defaultAdminEmail: 'Please enter default admin email',
            metaTitle: 'Please enter site meta title',
            metaDescription: 'Please enter meta description',
            metaKeyword: 'Please enter meta keyword',
            pageTitle: 'Please enter page title',
            currency: 'Please select currency',
            mainCurrency: 'Please enter currency',
            dateFormat: 'Please select date format',
            templateName: 'Please enter template name',
            countryName: 'Please enter country name',
            countryId: 'Please enter country name',
            countryCode: 'Please enter country code',
            phoneCode: 'Please enter phone code',
            facebookAppId: 'Please enter facebook app id',
            googleToken: 'Please enter google OAuth token',
            pKey: 'Please enter publishable key',
            sKey: 'Please enter secret key',
            mode: 'Please enter mode',
            clientSecret: 'Please  enter client secret',
            clientId: 'Please enter client id',
            timezone: 'Please enter timezone',
            twitterLink: 'Please enter twitter link',
            facebookLink: 'Please enter facebook link',
            googleLink: 'Please enter google link',
            linkedinLink: 'Please enter linkedin link',
            instagramLink: 'Please enter instagram link',
            websiteLink: 'Please enter website link',
            dob: 'Please select date of birth',
            country: 'Please select country',
            address: 'Please enter address',
            languages: 'Please select language',
            footerSnippet: 'Please enter footer snippet',
            headerSnippet: 'Please enter header snippet',
            analyticsSnippet: 'Please enter analytics snippet',
            authToken: 'Please enter auth token',
            accountSid: 'Please enter account SID',
            question: 'Please enter question',
            answer: 'Please enter answer',
            category: 'Please select faq category',
            PushNotificationRole: 'Please select role',
            description: 'Please enter description',
            encryption: 'Please enter encryption',
            smtpPassword: 'Please enter smtp password',
            fromName: 'Please enter from name',
            siteName: 'Please enter site name',
            perMileCharges: 'Please enter per mile charges',
            eatozeeCommisionOnOrder: 'Please enter comission on order',
            eatozeeCommisionOnDelivery: 'Please enter comission on delivery',
            wastedMileChargesPerMile: 'Please enter wasted mile charges'
        },
        PATTERN: {
            Email: 'Please enter valid email address',
            Contactno: 'Please enter valid contact number',
            emailId: 'Please enter valid email address',
            fromEmailId: 'Please enter valid email address',
            adminEmail: 'Please enter valid email address',
            newPassword: 'Please enter atleast 8 characters with one uppercase, one digit and one special character',
            confirmPassword: 'Please enter valid password',
            link: 'Please enter url which contains https://'
        },
        MINLENGTH: {
            mobile: 'Mobile Number should b e 10 digits',
            PhoneNo: 'Mobile Number should be 10 digits',
            confirmPassword: 'Require atleast 6 characters',
            newPassword: 'Require atleast 6 characters',
            port: 'Minimum 3 didgit required',
        },
        CUSTOM: {
            ConfirmPassword: 'Confirm password does not match!',
        },
    },
};
