import { environment } from './../../../../environments/environment';
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// constatns
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
// service
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service'
import { Template } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-vproduct',
  templateUrl: './vproduct.component.html',
  styleUrls: ['./vproduct.component.scss']
})
export class VproductComponent implements OnInit {
  @ViewChild('TreatMode', { static: false }) TreatMode: ElementRef<HTMLElement>;
  @ViewChild('FitMode', { static: false }) FitMode: ElementRef<HTMLElement>;
  fileToUpload: any;
  imageUrl: any;
  imageSrc: string;
  ImageURL = "";
  uploadImgUrl = environment.uploadImgUrl + '/public/upload/images/';
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  productForm: FormGroup;
  formData = new FormData();
  myForm: FormGroup;
  categoryList: any[] = [];
  cuisineList: any[] = [];
  attributeList: any[] = [];
  foodTypeList: any[] = ['Veg', 'Non-Veg', 'Eggetarian'];
  productId: any;
  VendorId: string;
  Productvalue: any = 'Add Product';
  editProductListShow = false;
  selectedVirtualDrive: any = 'No';
  public isPizzaRestaurant;
  public attributeImage = '';
   public isSubmitted: boolean = false;
  // public variantData =[];

  constructor(private activatedRoute: ActivatedRoute,
    public commonService: CommonService, private fb: FormBuilder,
    public showErrorService: ShowErrorService, private router: Router,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService, public restrictKeyPressService: RestrictKeyPressService) {
    this.activatedRoute.params.subscribe((params) => { this.productId = params.id; });
    this.VendorId = this.localStorageService.getToken('VendorId');
     this.isPizzaRestaurant = this.localStorageService.getToken('isPizzaResturant');
    //(this.productId === undefined)? null :this.getProduct();
    if (this.productId !== undefined) {
      this.getProduct(); 
      this.editProductListShow = true;           
      this.Productvalue = 'Edit Product';

      //       if(this.isPizzaRestaurant === "false") {
      //           this.getAttribute();
      // }

    } else {
      this.editProductListShow = false;     
      // this.getAttribute();

      if(this.isPizzaRestaurant === "false") {
                this.getAttribute();
      }

    }
  }

  addAttributing(temp: TemplateRef<any>) {
    this.submitted = false;
    this.myForm.reset();
    this.bsModalRef = this.modalService.show(temp, { class: 'modal-dialog-centered' })
  }
  ngOnInit() {
    if(this.isPizzaRestaurant === "true"){
      let ListData = { 
        "page": 1,
        "pagesize": 10
      }
      this.commonService.callApi(this.callAPIConstants.GetCustomizationVriantList,ListData,'post','false','false').then((success) => {
          // this.variantData = success.data.listing;
          if (success.data.listing.length) {
            success.data.listing.forEach(element => {

              const variantFormGroup = this.newPizzaVariant(element);


              (<FormArray>this.productForm.get('variant')).push(variantFormGroup);

            });
          }
      })
      this.commonService.callApi(this.callAPIConstants.GetCustomizationAttributeList,ListData,'post','false','false').then((success) => {
        if (success.data.listing.length) {
          success.data.listing.forEach(element => {
            
            const attributeFormGroup = this.newPizzaAttribute(element);


            (<FormArray>this.productForm.get('attribute')).push(attributeFormGroup);

          });
        }
    })
    }

    this.getCategoryList();
    this.getCusineList();
    this.myForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]]
    })
    this.productForm = this.fb.group({
      name: ['', Validators.required],
      image: [null],
      description: [''],
      price: ['', Validators.required],
      servingPerson:[''],
      discountPercentage: [''],
      categoryId: [null, Validators.required],
      cuisineId: [null, Validators.required],
      foodType: [''],
      variant: this.fb.array([]),
      attribute: this.fb.array([]),
      calories: [''],
      fat: [''],
      protein: [''],
      carbohydrate: [''],
      cuisinePreference: ['Treat Mode', Validators.required],
      virtualDrivePreference:['NO',Validators.required],
      isVegan: ['']
    })
  }

  // {
  //   'masterId': [''],
  //   'name': [''],
  //   'price': [''],
  //   'servingPeople': ['']
  // }
  /*******************GetProduct Details************************************************/
  public cuis: any;
  Image: any;
  getProduct() {

    this.commonService.callApi(this.callAPIConstants.Product_Detail + this.productId + '/' + this.VendorId,
      '', 'get', false, false).then((success) => {
               if(this.isPizzaRestaurant === "false") {
                this.getAttribute(success.data.attribute);
      }
        this.ImageURL = this.uploadImgUrl + success.data.image;
        if(this.productId){
          this.ImageURL = '';
        }
        this.cuis = success.data.cuisinePreference;
        this.productForm.controls['categoryId'].patchValue(success.data.categoryId)
        this.productForm.controls['cuisineId'].patchValue(success.data.cuisineId._id)
        this.productForm.controls['name'].patchValue(success.data.name);
        this.productForm.controls['price'].patchValue(success.data.price);
        this.productForm.controls['discountPercentage'].patchValue(success.data.discountPercentage);
        this.productForm.controls['cuisinePreference'].patchValue(success.data.cuisinePreference);
        this.productForm.controls['description'].patchValue(success.data.description);
        if(success.data.image){
          this.Image = 'https://api.eatozee.com' + success.data.image;
        }
        
        if (success.data.variant != null) {
          let frmArray = this.productForm.get('variant') as FormArray;
                frmArray.clear();
          success.data.variant.forEach(element => {
            this.addExistingvariant(element.name,element.price)
          });
        }

        if (success.data.cuisinePreference === 'Treat Mode') {
          if (success.data.attribute != null) {
            success.data.attribute.forEach(element => {

              const attributeFormGroup = this.newattribute(element._id, element.name, element.price);

              (<FormArray>this.productForm.get('attribute')).push(attributeFormGroup);

            });
          }
          
          // if (success.data.variant != null) {
          //   success.data.attribute.forEach(element => {

          //     const variantFormGroup = this.newvariant(element.name, element.price);


          //     (<FormArray>this.productForm.get('attribute')).push(variantFormGroup);

          //   });
          // }

        } else if (success.data.cuisinePreference === 'Fit Mode') {
          this.productForm.controls['calories'].patchValue(success.data.calories);
          this.productForm.controls['fat'].patchValue(success.data.fat);
          this.productForm.controls['protein'].patchValue(success.data.protein);
          this.productForm.controls['carbohydrate'].patchValue(success.data.carbohydrate);
          (this.productForm.value.isVegan != null) ? this.formData.append('isVegan', JSON.stringify(this.productForm.value.isVegan)) : ''
        }

      });
  }

  /*****************Search****************************************/
  public searchText;
  searchData(event) {
    let page = 1;
    if (event.target && event.target.value) {
      this.searchText = event.target.value;
    }
    else {
      delete this.searchText;
    }
  }

  get f() {
    return this.myForm.controls;
  }
  /********************form array************************************/
  /**********************Variant***************************** */
  variant(): FormArray {
    return this.productForm.get("variant") as FormArray
  }
  newvariant(name = '', price = ''): FormGroup {
    return this.fb.group({
      name: [name,Validators.required],
      price: [price,Validators.required]
    })
  }

  existingVariant(name, price): FormGroup {
    return this.fb.group({
      name: [name,Validators.required],
      price: [price,Validators.required]
    })
  }
  addvariant() {
    this.variant().push(this.newvariant());
  }
  addExistingvariant(name,price) {
    this.variant().push(this.existingVariant(name,price));
  }
  removevariant(i: number) {
    this.variant().removeAt(i);
  }
  /****************************************************** */

  //***************new pizza variant***************
  newPizzaVariant(element): FormGroup {
    return this.fb.group(
  {
    'masterId': element ? [element._id] : [''],
    'name': element ? [element.name] : [''],
    'price': [''],
    'servingPerson': ['']
  }
    )
  }
    /****************************************************** */

  //***************new pizza attribute ***************
  newPizzaAttribute(element): FormGroup {
    return this.fb.group(
  {
    'check': [false],
    'masterId': element ? [element._id] : [''],
    'name': element ? [element.name] : [''],
    'price': [''],
    'image': element ? [element.image] : [''],
  }
    )
  }
    /****************************************************** */


  /*******************************attribute */
  attribute(): FormArray {
    return this.productForm.get("attribute") as FormArray
  }
  newattribute(id = '', name = '', price = '',check = false): FormGroup {
    return this.fb.group({
      check: [check],
      id: [id],
      name: [name],
      price: [price]
    })
  }
  addattribute() {
    this.attribute().push(this.newattribute());
  }
  removeattribute(i: number) {
    this.attribute().removeAt(i);
  }

  /********** fetch default data**************************************/
  valuecategory(event) {
    if (event._id != null) {
      this.formData.append('categoryId', event._id)
    }
  }
  valuecusine(event) {


    if (event._id != null) {
      this.formData.append('cuisineId', event._id)
    }
  }
  /***********List of Category ***************/
  getCategoryList() {
    let CategoryList = {
      "page": 1
    }
    this.commonService.callApi(this.callAPIConstants.categoryList, CategoryList, 'post', false, true, false).then((success) => {
      if (success.status === 1) {
        this.categoryList = success.data;
      }
    });
  }
  /***********List of Cuisine ***************/
  getCusineList() {
    let Cuisinelist = {
      page: 1,
      searchQuery: this.searchText
    }
    this.commonService.callApi(this.callAPIConstants.getCuisine, Cuisinelist, 'post', false, true, false).then((success) => {
      if (success.status === 1) {
        this.cuisineList = success.data;
      }
    });
  }
  /***********List of Attribute ***************/

  getAttribute(attribute?) {
    let availabiltyFormGroup;
    let AttributeList = {
      page: 1,
      pagesize: 200,
    }
    this.commonService.callApi(this.callAPIConstants.getAttribute, AttributeList, 'post', false, true, false).then((success) => {
      if (success.status === 1) {          
        this.attributeList = success.data;
        (<FormArray>this.productForm.get('attribute')).clear();
        success.data.forEach(element => {

          if(attribute?.length){
              const index = attribute.findIndex((a)=> a.id === element._id);
              if(index > -1){
                element.check = true;
                element.price = attribute[index].price;
              }
          }
          
          availabiltyFormGroup = this.newattribute(element._id, element.name,element.price,element.check);
          (<FormArray>this.productForm.get('attribute')).push(availabiltyFormGroup);
        })

      }
    });
  }
  /***********Add Product ***************/
  resetform() {
    this.productForm.reset();
    this.router.navigate([this.URLConstants.PRODUCT]);
  }
  responseData: any = {
    name: ''
  }
  /******************** attribute form******************************** */
  saveAttributeForm(form) {
    this.submitted = true;

    this.responseData.name = this.myForm.value.name
    if (this.myForm.valid) {
      this.commonService.callApi(this.callAPIConstants.addAttribute, this.responseData, 'post', false, true, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          
          if(this.productId === undefined){
            this.getAttribute();
          }
          if(this.productId){
            this.getProduct();
          }
          this.bsModalRef.hide();
          // this.myForm.reset();
        } else {
          this.showErrorService.popToast('error', success.message);
          // this.myForm.reset();
        }
      },(error)=> { 
        if(error.status === 500){
          this.showErrorService.popToast('error', error.error.error.message);
          this.modalService.hide();
        }
    });
    }

  }
  display: any = 'Treat Mode';

  displayed(e: string) {

    this.display = e;
  }
  public submitted = false;
  public checkedlist = [];
  public isAttribute: boolean = false;
  onSubmit() {
    this.isSubmitted = true;
    // if(this.isPizzaRestaurant === "true"){
    //     this.formData.append('variant[]',this.productForm.value.variant)
    // }

    if(this.selectedVirtualDrive === 'Yes'){
      this.formData.append('isVirtualDriveThrough','true')
    }else{
      this.formData.append('isVirtualDriveThrough','false')
    }
    this.submitted = true;

    (this.productId) ? this.formData.append('_id', this.productId) : '';

    if (this.display === 'Fit Mode') {
      this.formData.append('calories', this.productForm.value.calories);
      this.formData.append('fat', this.productForm.value.fat);
      this.formData.append('protein', this.productForm.value.protein);
      this.formData.append('carbohydrate', this.productForm.value.carbohydrate);
      (this.productForm.value.isVegan != null) ? this.formData.append('isVegan', JSON.stringify(this.productForm.value.isVegan)) : ''
    } else if (this.display === 'Treat Mode') {
      // (this.productForm.value.variant != null) ? this.formData.append('variant', JSON.stringify(this.productForm.value.variant)) : ''
      // let finalResult = this.productForm.value.attribute.map(obj => {
      //   return obj.price === "";
      // });

      // let isSelectPrice = finalResult.some(v => v === true);
      // if (isSelectPrice) {
      //   this.isAttribute = true;
      //   return
      // } else {
      //   this.isAttribute = false
      // }

      // if (this.productForm.value.attribute != null) {
      //   this.productForm.value.attribute.forEach(element => {
      //     if (element.check === true) {
      //       this.checkedlist.push(element);
      //     }
      //   });
      //   this.formData.append('attribute', JSON.stringify(this.checkedlist))
      // }

    }

    (this.productForm.value.variant != null) ? this.formData.append('variant', JSON.stringify(this.productForm.value.variant)) : ''
    let finalResult = this.productForm.value.attribute.map(obj => {
      
      return obj.check && obj.price === "" ;
    });


    let isSelectPrice = finalResult.some(v => v === true);
    if (isSelectPrice) {
      this.isAttribute = true;
      return
    } else {
      this.isAttribute = false
    }

    if (this.productForm.value.attribute != null) {
      this.productForm.value.attribute.forEach(element => {
        if (element.check === true) {
          this.checkedlist.push(element);
        }
      });
      this.formData.append('attribute', JSON.stringify(this.checkedlist))
    }
    // this.formData.append('name', this.productForm.value.name);
    // this.formData.append('description', this.productForm.value.description);
    // this.formData.append('price', this.productForm.value.price);
    // this.formData.append('discountPercentage', this.productForm.value.discountPercentage);
    // this.formData.append('variant', JSON.stringify(this.productForm.value.variant));
    // this.formData.append('attribute', JSON.stringify(this.productForm.value.attribute));
    // this.formData.append('cuisinePreference', this.productForm.value.cuisinePreference);
    // this.formData.append('image', this.ImageURL);

    if (this.productForm.valid) {
      this.formData.append('name', this.productForm.value.name);
      if(this.productForm.value.description){
        this.formData.append('description', this.productForm.value.description);
      }
      this.formData.append('price', this.productForm.value.price);
      if(this.productForm.value.discountPercentage){
        this.formData.append('discountPercentage', this.productForm.value.discountPercentage);
      }
      // this.formData.append('variant', JSON.stringify(this.productForm.value.variant));
      this.formData.append('attribute', JSON.stringify(this.productForm.value.attribute));
      this.formData.append('cuisinePreference', this.productForm.value.cuisinePreference);
      // this.formData.append('image', this.ImageURL);
      this.commonService.callApi(this.callAPIConstants.Add_Product, this.formData, 'post', false, true, false).then((success) => {
        this.router.navigate([this.URLConstants.PRODUCT]);
        if (success.status === 1) {
          this.isSubmitted = false;
          this.showErrorService.popToast('success', success.message);
        } else {
          this.formData = new FormData();
          this.showErrorService.popToast('error', success.message);
        }
      },(error)=> { 
        if(error.status === 500){
          this.formData = new FormData();
          this.showErrorService.popToast('error', error.error.error.message);
          this.router.navigate([this.URLConstants.ADD_PRODUCT]);
        }
    });
    }

  }

  /****************************************************************************
    @Purpose     : Uploading Image
    @Parameter   : $event
    @Return      : NA
    /****************************************************************************/
  // image
  public file: any;
  imageChangedEvent = '';
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';

  imageCropped(event) {
    this.croppedImage = event.base64;
    this.ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = this.ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);
  }
  imageLoaded() { /* show cropper */ }
  loadImageFailed() { /* show message */ }
  fileChangeEvent(event: any, template: TemplateRef<any>): void {
    this.imageChangedEvent = event;
    this.bsModalRef = this.modalService.show(template);
  }
  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], 'uploaded_file.jpg', { type: contentType, lastModified: Date.now() });
  }

  submitcroppedImage() {
    this.bsModalRef.hide();
    const fd = new FormData();
    this.formData.append('image', this.file);
  }
  openfile(event: any) {
    event.preventDefault();
    const element = document.getElementById('profile');
    element.click();
  }
  removePhoto() {
  }

  funct(val){
  }
}

