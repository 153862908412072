<div class="main-content-area">
      <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">Attribute</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
                </li>
                <li class="breadcrumb-item">List</li>

                <li class="breadcrumb-item active" aria-current="page">
                    Attribute list
                 </li>
            </ol>
        </nav>
          <!-- filter bar buttons end -->
          <div class="m-content animated fadeIn">
              <!-- BEGIN: Responsive Data Table  -->
              <app-responsive-data-table [tableSetupData]="tableSetupData" #responsiveDataTable>
              </app-responsive-data-table>
              <!-- END: Responsive Data Table  -->
          </div>
      </div>
  </div>