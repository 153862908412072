<div class="main-content-area">
    <div class="main-content-block">

        <!-- Begin: breadcrumbs -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">{{tabs.faqList? "FAQ's" : 'FAQ Category'}}</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
                </li>
                <li class="breadcrumb-item" [routerLink]="[URLConstants.FAQS]">Contents</li>

                <li class="breadcrumb-item active" aria-current="page">
                    {{tabs.faqList? "FAQ's" : 'FAQ Category'}}
                </li>
            </ol>
        </nav>
        <!-- End: breadcrumbs -->

        <!-- Begin: Tabs -->
        <div class="m-content animated fadeIn">
            <div class="animated fadeInUp box-wrapper card notification-card tabbing">
                <div class="m-portlet settings-wrapper approve-template custom-tabs">
                    <tabset class="tab-links tab-content" id="content">
                        <!-- Begin: FAQ's -->
                        <tab id="tab1" (selectTab)="onTabsSelection('FaqList')">
                            <ng-template tabHeading>
                                <em class="bx align-middle mr-1"></em><span class="align-middle">FAQ's</span>
                            </ng-template>

                            <app-responsive-data-table [tableSetupData]="tableSetupData"
                                [accessPermission]="accessPermission" *ngIf="tabs.faqList">
                            </app-responsive-data-table>
                        </tab>
                        <!-- End: FAQ's -->

                        <!-- Begin: FAQ Category -->
                        <tab [active]="tabs.faqCategory" (selectTab)="onTabsSelection('FaqCategory')"
                            customClass="date-icon common-icon">
                            <ng-template tabHeading (selectTab)="onTabsSelection('FaqCategory')">
                                <em class="bx align-middle mr-1"></em><span class="align-middle">FAQ Category</span>
                            </ng-template>

                            <app-responsive-data-table [tableSetupData]="tableSetupData"
                                [accessPermission]="accessPermission" *ngIf="tabs.faqCategory">
                            </app-responsive-data-table>
                        </tab>
                        <!-- End: FAQ Category -->
                    </tabset>
                </div>
            </div>
        </div>
        <!-- End: Tabs -->
    </div>
</div>