<div class="theme-setting-block" [ngClass]="{'open': isSettingOpen}">
    <a class="theme-setting-link" (click)="openSettingBox()">
        <em class="bx bx-cog bx-flip-horizontal" [ngClass]="{'bx-spin': !isSettingOpen}"></em>
    </a>
    <div class="d-flex align-items-center">
        <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
        <div class="custom-control custom-switch theme-switch">
            <input type="checkbox" class="custom-control-input" id="loginSwitchTheme" (click)="toggleTheme()">
            <label class="custom-control-label" for="loginSwitchTheme"></label>
        </div>
        <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
    </div>
</div>
<!--Start Login Wrapper-->
<div class="login-wrapper" style="background:url(assets/images/login-bg.jpg) no-repeat center center; background-size: cover;">
    <div class="login-body">
        <section class="login-container row m-0">
            <div class="col-xl-8 col-11">
                <div class="card bg-login-card">
                    <div class="row m-0">
                        <div class="col-md-6 col-12 pl-0 pr-0">
                            <div class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                                <div class="card-header">
                                    <div class="card-title text-center mb-4">
                                        <h4 class="mb-2">Welcome</h4>
                                    </div>
                                    <!-- <nav class="pl-5 pt-3 nav nav-tabs ">
                                        <button #tab type="button" class="btn btn-primary" [style.opacity] (click)="selectChangeHandler('superAdmin')">SuperAdmin</button>
                                        <button type="button" class="btn btn-primary"(click)="selectChangeHandler('vendor')">Vendor</button>
                                  </nav> -->
                                    <!-- <select (click)="selectChangeHandler($event)" ngClass>
                                        <option [ngValue]="null" disabled>Choose your profile</option>
                                        <option  value="superAdmin">Admin</option>
                                        <option value="vendor">Vendor</option>
                                        
                                    </select> -->
                                    <!-- [ngClass]="((submitted || emailTemplateId.touched) && emailTemplateId.invalid)?'has-danger':''" -->
                                    
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                            <form (ngSubmit)="loginForm.form.valid && onLogin(loginForm,users)" #loginForm="ngForm">
                                                <div class="form-group mb-2">
                                                    <label>Email address or Phone <sup class="bx bxs-star danger"></sup></label>
                                                    <input pattern="^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$" ngModel type="text" name="emailOrPhone" id="EmailAddress" aria-describedby="emailHelp" placeholder="Enter email address or phone" class="form-control" #emailOrPhone="ngModel"
                                                        [ngClass]="{ 'is-invalid':(loginForm.submitted || loginForm.touched) && emailOrPhone.invalid  }" [(ngModel)]="users.emailOrMobile"  required>
                                                    <div *ngIf="(loginForm.submitted || loginForm.touched) && emailOrPhone.invalid" class="invalid-feedback">
                                                        <div *ngIf="emailOrPhone.errors.required">Please enter email address or phone</div>
                                                        <div *ngIf="emailOrPhone.errors.pattern">Please enter valid email address or phone</div>
                                                    </div>
                                                        <!-- {{emailOrPhone.errors.required}} -->
                                                </div>
                                                <div class="form-group mb-2 position-relative">
                                                    <label>Enter Password<sup class="bx bxs-star danger"></sup></label>
                                                    <input [type]="passwordType ? 'text' : 'password'" ngModel id="password-field" type="password" name="password" class="form-control" #password="ngModel" placeholder="Enter password" [ngClass]="{ 'is-invalid': (loginForm.submitted || loginForm.touched)  && password.invalid }"
                                                       required [(ngModel)]="users.password">
                                                    <span [tooltip]="passwordType ? 'Hide Password' : 'Show Password'" toggle="#password-field" class="bx bx-hide field-icon toggle-password" [ngClass]="{
                                                        'bx-bx-hide': !passwordType,
                                                        'bx-show': passwordType
                                                      }" (click)="toggleFieldTextType()"></span>
    
                                                    <div *ngIf="(loginForm.submitted || loginForm.touched) && password.invalid" class="invalid-feedback">
                                                        <div *ngIf="password.errors.required">Please enter password</div>
                                                        <!-- <div *ngIf="password.errors.pattern">Password should contain atleast a special character (@, $, !, %, *, ?, & or #), a capital letter and a number and length should be between 6-20</div> -->
                                                    </div>
                                                    <!-- {{password.errors.required}} -->

                                                </div>

                                                <div class="form-group d-flex flex-md-row flex-column justify-content-between align-items-center mb-3">
                                                    <div class="text-left">
                                                        <div class="custom-checkbox sm">
                                                            <label for="check1" class="mb-0">
                                                                <input type="checkbox" name="checkbox" id="check1" [(ngModel)]="users.rememberMe">
                                                                <span></span>Keep me logged in
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <a routerLink="/forgot-password" class="card-link">
                                                            Forgot password?
                                                        </a>
                                                    </div>
                                                </div>
                                                <button class="btn btn-primary glow position-relative btn-block">
                                                    Login<em class="icon-arrow bx bx-right-arrow-alt"></em>
                                                </button>
                                            </form>
                                        <div class="text-center common-small-text mt-3 mb-4"><small>Don’t have an account? <a routerLink="/register" >Signup as vendor</a></small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
                            <div class="card-content login-brand-img">
                                <picture>
                                    <source srcset="assets/images/new-logo.webp" type="image/webp">
                                    <source srcset="assets/images/new-logo.png" type="image/png">
                                    <img class="img-fluid" src="assets/images/new-logo.svg" alt="login">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>