// environment
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { DATE_PATTERN, CUURENCY_CODE } from '../../../common/constants/categoryType-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ShowErrorService } from 'src/app/common/service/show-error.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  URLConstants = URLConstants;
  DATE_PATTERN = DATE_PATTERN;
  CUURENCY_CODE = CUURENCY_CODE;
  callAPIConstants = callAPIConstants;
  uploadImageUrl = environment.uploadImgUrl;
  public OrderId: any;
  public orderStatus: any;
  public orderDetail: any = {};
  public mealDealInfo: any = {};
  public productItems = [];
  public allStatus = ['delivered', 'pending', 'ongoing'];
  public itemsofProducts = [];
  public driverInfo: any;
  public virtualDriveInfo: any;
  public finalValues = [];
  public cartData: any;
  public orderDelivery: any;
  public categoryProducts = [];
  getDetailsMethod: string;

  constructor(public commonService: CommonService,
    public router: Router,
    public showErrorService: ShowErrorService,
    private sanitizer: DomSanitizer, public getPermissionsService: GetPermissionsService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params) => { this.OrderId = params.id; });
    if (this.router.url.includes(this.URLConstants.ADMIN_ORDER_VIEW)) {
      this.getDetailsMethod = this.callAPIConstants.Admin_View_Order;
    } else {
      this.getDetailsMethod = this.callAPIConstants.View_Order;
    }
  }
  ngOnInit() {
    this.getOrderDetails();
  }

  /*************************************************************
  @Purpose     : Get Order Details
  @Parameter   : NA
  @Return      : Detail
  /*************************************************************/
  getOrderDetails() {
    this.commonService.callApi(this.getDetailsMethod + this.OrderId, '', 'get', false, false).then((success) => {
      if (success.status === 1) {
        this.orderDelivery = success.data[0].isDelivery;

        this.orderStatus = success.data[0].status;
        this.orderDetail = success.data[0];
        this.mealDealInfo = success.data[0].cartdata[0].mealDealInfo;

        this.cartData = success.data[0].cartdata;
        const productInfoName = [];
        this.orderDetail.cartdata.forEach(element => {
          if (element.productInfo) {
            productInfoName.push(element.productInfo.name);
          }
        });

        const mealDealInfoName = [];
        this.orderDetail.cartdata.forEach(element => {
          if (element.mealDealInfo) {
            mealDealInfoName.push(element.mealDealInfo.name);
          }
        });

        this.finalValues = productInfoName.concat(mealDealInfoName);

        this.orderDetail.cartdata.forEach(element => {
          if (element.categoryProducts) {
            this.categoryProducts.push(element.categoryProducts);
          }
        });
        this.driverInfo = success.data[0].driverInfo;
        this.virtualDriveInfo =success.data[0].carDetails;        
      }
    });
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Change Order Status
  @Parameter   : deliveryMode
  @Return      : NA
  /*************************************************************/
  orderStatusChange(deliveryMode) {
    let orderData: any = {};
    if (deliveryMode == 'Delivery') {
      orderData['orderId'] = this.OrderId;
      orderData.orderStatus = 'prepared';
    }
    if (deliveryMode == 'Pickup') {
      orderData.orderId = this.OrderId;
      orderData.orderStatus = 'delivered';
    }
    this.commonService.callApi(this.callAPIConstants.CHANGE_ORDER_STATUS, orderData, 'post', false, true, false).then((success) => {
      if (success.status === 1) {
        this.getOrderDetails();
        this.showErrorService.popToast('success', success.message);
      }
    }, (error) => {
      if (error.status == 404) {
        this.showErrorService.popToast('error', 'Driver Not Found');
      }
    });
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Prepare Status
  @Parameter   : val
  @Return      : NA
  /*************************************************************/
  prepareStatus(val) {
    let data: any;
    data.isPrepared = true;
    data.cartId = this.OrderId;
    if (val?.productInfo?._id) {
      data.productId = val?.productInfo?._id;
    }
    if (val?._id) {
      data.productId = val?._id;
    }
    this.commonService.callApi(this.callAPIConstants.PRODUCT_PREPARE_STATUS, data, 'post', false, true, false).then((success) => {
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        this.router.navigate([this.URLConstants.ORDER]);
      }
    });
  }
  /*************************************************************/


}
