import { environment } from 'src/environments/environment';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// interface
import { IBreadcrumbs } from 'src/app/common/interfaces/interfaces';

// constatns
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

// service
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GetPermissionsService } from 'src/app/common/service/get-permissions.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss']
})
export class EditDetailsComponent implements OnInit {
  public submitted: boolean = false;
  cuisineID: any;
  imageSrc: string;
  fileToUpload: any;
  imageUrl: any;
  cuisineValue: any = 'Add Cusine';
  uploadImgUrl = environment.uploadImgUrl;
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  formData = new FormData();
  public cuisineData = {
    image: '',
    name: ''
  }
  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    image: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  constructor(private activatedRoute: ActivatedRoute,
    public commonService: CommonService, public router: Router,
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService) {
    this.activatedRoute.params.subscribe((params) => { this.cuisineID = params.id; });
    // START: Role Permission //
    // this.accessPermission = defaultPermissionConstants;
    // END: Role Permission //

    if (this.cuisineID !== undefined) {
      this.getCuisineDetail();
      this.cuisineValue = 'Edit Cusine';
    }
  }

  get f() {
    return this.myForm.controls;
  }

  onFileSelect(e: File[]) {

    let fileName = e[0];
    let fileType = fileName.type;
    this.formData.append('image', e[0]);
  }

  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.myForm.patchValue({
      image: file
    });
    this.myForm.get('image').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageUrl = reader.result as string;
    }
    reader.readAsDataURL(file)
  }


  ngOnInit() {
    //this.getCuisineDetail();
  }
  /*************************************************************
   @Purpose     : Get Role Details
   @Parameter   : NA
   @Return      : role
   /*************************************************************/
  getCuisineDetail() {
    this.commonService.callApi(this.callAPIConstants.DetailCuisine + this.cuisineID,
      '', 'get', false, false).then((success) => {
        this.cuisineData.name = success.data.name;
        this.cuisineData.image = this.uploadImgUrl + success.data.image;
      });
  }
  addCuisine(form, cuisine) {    
    this.submitted = true;
    if (this.cuisineData.name) {
      (this.cuisineID) ? this.formData.append('_id', this.cuisineID) : ''

      // (this.cuisineID)?this.formData.append('_id',this.cuisineID):''
      this.formData.append('name', cuisine.name);
      this.commonService.callApi(this.callAPIConstants.AddCuisine, this.formData, 'post', false, true, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.CUISINE]);
        } else {
          this.formData = new FormData();
          this.showErrorService.popToast('error', success.message);
          this.router.navigate([this.URLConstants.DASHBOARD]);
        }
      },(error)=> { 
        if(error.status === 500){
          this.formData = new FormData();
          this.showErrorService.popToast('error', error.error.error.message);
          this.router.navigate([this.URLConstants.ADD_CUISINE]);
        }
    });
    }
  }
  /****************************************************************************
    @Purpose     : Uploading Image
    @Parameter   : $event
    @Return      : NA
    /****************************************************************************/
  // image
  public file: any;
  imageChangedEvent = '';
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';
  ImageURL = "";
  imageCropped(event) {
    this.croppedImage = event.base64;
    this.ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = this.ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);
  }

  imageLoaded() { /* show cropper */ }

  loadImageFailed() { /* show message */ }

  fileChangeEvent(event: any, template: TemplateRef<any>): void {
    this.imageChangedEvent = event;
    this.bsModalRef = this.modalService.show(template);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], 'uploaded_file.jpg', { type: contentType, lastModified: Date.now() });
  }

  submitcroppedImage() {
    this.bsModalRef.hide();
    const fd = new FormData();

    //Show image preview

    this.formData.append('image', this.file);


    // this.commonService.callApi(this.callAPIConstants.AdminFileUpload, fd, 'post', true, true).then((success) => {
    //   (success.status === 1) ? this.admin.photo = success.data.filePath : this.showErrorService.popToast('error', success.message);
    // });
  }

  openfile(event: any) {
    event.preventDefault();
    const element = document.getElementById('profile');
    element.click();
  }

  removePhoto() {

  }



}
