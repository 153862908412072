import { ViewComponent } from './view/view.component';
import { EditComponent } from './edit/edit.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductsComponent } from './products.component';
import { ListComponent } from './list/list.component';
import { VproductComponent } from './vproduct/vproduct.component';

const routes: Routes = [
  { 
    path: 'list',
    component: ListComponent 
  },
  {
    path: 'add',
    //canActivate: [CanCreateActivate],
    component:VproductComponent ,
    pathMatch: 'full',
  },
  {
    path: 'update/:id',
     component: VproductComponent ,
      pathMatch: 'full',
  },
  {
    path:'view/:id',
   component:ViewComponent
 },
  {
    path: '', redirectTo: 'list', pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
