<div class="main-content-area">
  <div class="main-content-block">

    <!-- filter bar buttons end -->
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="content-header-title">Earnings</li>
          <li class="breadcrumb-item">
            <a [routerLink]="[URLConstants.DASHBOARD]"><em class="bx bx-home-alt"></em></a>
          </li>
          <li class="breadcrumb-item active">Earnings</li>
          <!-- <li class="breadcrumb-item active" aria-current="page">
            List
          </li> -->
        </ol>
      </nav>
    </div>


    <!-- <div><p>Total Earning: {{'xyz'}}</p></div> -->
    <!-- <div>
          <div>
          <ng-select [items]="filterStatus" bindLabel="name" bindValue="value"
          [searchable]='true' [multiple]="false"
            [clearable]='false'  #filterStatus="ngModel"
            name="foodType" 
            id="foodType"
            placeholder="filterStatus"  placeholder="filterStatus"
            [(ngModel)]="EarningList.filterStatus" required
            >
            </ng-select>
          </div>
        </div> -->

    <div class="content-wrapper mb-4">
      <div class="row select-month border-bottom align-items-center pb-4 mb-4">

        <div class="col-md-6 calender-blog">
          <h5 class="total-heading mb-0"> Total Earnings : {{totalEarnings | currency: CUURENCY_CODE}}</h5>
        </div>

        <div class="col-md-6">
          <div class="row text-right">
            <div class="col-md-6 calender-blog">
              <ng-select class="ng-select-line" [items]="staticData" bindValue="value" bindLabel="name"
                placeholder="Month" [clearable]='false' [multiple]="false" [searchable]="false"
                [dropdownPosition]="'auto'" [closeOnSelect]="true" [(ngModel)]="chartData.revenueMonths" required
                (change)="onMonthSelection()">
                <ng-template ng-option-tmp let-item="item">
                  <span>{{item?.name}} </span>
                </ng-template>
              </ng-select>
            </div>

            <!-- <div class="col-md-4 calender-blog">
           
              <input class="form-control" #drp="bsDaterangepicker" bsDaterangepicker [maxDate]="currentDate"
                [(ngModel)]="chartData.revenueMonth" 
                (bsValueChange)="dateRangeCreated($event)" [bsConfig]="{containerClass:'theme-red'}" (click)="onActivated($event)">
      
            </div> -->
            <div class="col-md-6 card-choose">
              <ng-select class="ng-select-line" [items]="filterStatuss" bindLabel="name" bindValue="name"
                [searchable]='false' [multiple]="false" [clearable]='false' #filterStatus="ngModel"
                [dropdownPosition]="'auto'" name="foodType" (change)="dateRangeSelection($event)" id="foodType"
                placeholder="Choose Card" [(ngModel)]="EarningList.filterStatus" required>
              </ng-select>
            </div>
          </div>
        </div>

      </div>
      <div class="card-chart">
        <div id="orderChart"></div>
      </div>

      <div class="chart-pagination mt-4">
        <div *ngIf="chartData.revenueMonths === 'Monthly'" style="text-align: center;">
          <button class="btn btn-link clearfix p-0 mr-3" (click)="goPreviousMonth()"><em class='bx bx-chevron-left'
              aria-hidden="true"></em></button><span class="padination-value">{{startDate | date: DATE_PATTERN}} to
            {{endDate | date: DATE_PATTERN}}</span><button class="btn btn-link clearfix p-0 ml-3"
            (click)="goNextMonth()"><em class='bx bx-chevron-right' aria-hidden="true"></em></button>
        </div>

        <div *ngIf="chartData.revenueMonths === 'Weekly'" style="text-align: center;">
          <button class="btn btn-link clearfix p-0 mr-3" (click)="goPreviousWeek()"><em class='bx bx-chevron-left'
              aria-hidden="true"></em></button><span class="padination-value">{{startWeekDate | date: DATE_PATTERN}} to
            {{endWeekDate | date: DATE_PATTERN}}</span><button class="btn btn-link clearfix p-0 ml-3"
            (click)="goNextWeek()"><em class='bx bx-chevron-right' aria-hidden="true"></em></button>
        </div>

        <div *ngIf="chartData.revenueMonths === 'Yearly'" style="text-align: center;">
          <button class="btn btn-link clearfix p-0 mr-3" (click)="goPreviousYear()"><em class='bx bx-chevron-left'
              aria-hidden="true"></em></button><span class="padination-value">{{startYearDate | date: DATE_PATTERN}} to
            {{endYearDate | date: DATE_PATTERN}}</span><button class="btn btn-link clearfix p-0 ml-3"
            (click)="goNextYear()"><em class='bx bx-chevron-right' aria-hidden="true"></em></button>
        </div>
      </div>

    </div>
    <div class="m-content animated fadeIn">
      <!-- BEGIN: Responsive Data Table  -->
      <app-responsive-data-table [tableSetupData]="tableSetupData" [timeSelectionData]="timeSelection"
        #responsiveDataTable>
      </app-responsive-data-table>
      <!-- END: Responsive Data Table  -->
    </div>
  </div>
</div>