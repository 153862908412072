import { SharedModule } from './../../shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgmMapRoutingModule } from './agm-map-routing.module';
import { AgmMapComponent } from './agm-map.component';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [AgmMapComponent],
  imports: [
    CommonModule,
    AgmMapRoutingModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCiWXQmF7JoDEed5eI9Zr9945b3t83wVbI',
    })
  ],
  exports:[AgmMapComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class AgmMapModule { }
