import { Component, OnInit, ViewChild } from '@angular/core';

// interfaces
import { IBreadcrumbs, ITableSetupData } from '../../../common/interfaces/interfaces';

import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  prodCategoryList : any;
  URLConstants = URLConstants;
  public accessPermission: any;
  callAPIConstants = callAPIConstants;
  tabs: any = {};

  grid = true;
  items = ["item 1", "item 2", "item 3", "item 4", "item 5"];
  constructor(private getPermissionsService: GetPermissionsService, 
    private commonService: CommonService) {
    this.setBreadcrumbs();
  }
  ngOnInit() { 
    this.setDTableInitialData();
    this.tabs.CategoryList = true;
    this.getProductCategoryList();
 }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  getProductCategoryList(){
    let data = {"page":1,"pagesize":10};
      this.commonService.callApi(this.callAPIConstants.ProductCategoryList,data, 'post',false,false).then((res)=> {
        this.prodCategoryList = res.data;
      })
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'Category', url: null},
   
    ];
    this.subHeaderData = {
      title: 'Menu Category',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };
  setDTableInitialData() {
    const tempData = [
      { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: false },
      { type: 'image', colName: 'Image', colFieldname: 'image', isVisible: true,columnVisibility: true },
      { type: 'text', colName: 'Name', colFieldname: 'name', isVisible: true, sort: true, columnVisibility: true, filter: true }, 
      { type: 'text', colName: 'Item', colFieldname: 'productCount', isVisible: true, sort: true, columnVisibility: true, filter: true }, 
      { type: 'switch', colName: 'Status', colFieldname: 'status', isVisible: true, columnVisibility: true, filter: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },
    ];
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type ='categoryList';
    this.tableSetupData.actions = [
      {
        id: 1, buttonTitle: 'view', tooltip: 'view', class: 'bx bx-show-alt',
        type: 'icon', 
      }
    ];
    this.tableSetupData.params = { deleteParams: '_id', statusParams: '_id' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      showButton: { routerLink: this.URLConstants.MENU_CATEGORY_ADDUPDATE, buttonName: 'Add Category' },
    };
  }
  /*********************************************************************************************/
  setDTableInitialData1() {
    const tempData = [
      { type: 'text', colName: 'name', colFieldname: 'name', isVisible: true, sort: true, columnVisibility: true, filter: true },
      
    ];
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type ='categoryProductList';
    this.tableSetupData.actions = [
      {
        id: 1, buttonTitle: 'view', tooltip: 'Edit', class: 'bx bx-show-alt',
        type: 'icon', 
      },
      {
        id: 3, buttonTitle: 'delete', tooltip: 'Delete', class:"bx bx-trash-alt text-danger",
        type: 'icon',
      }
    ];
    this.tableSetupData.params = { deleteParams: 'id', statusParams: 'id' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: true,
      showFilterExport: false,
      showSaveFilter: false,
    };
  }
  /*********************************************************************************************/

 
  /*************************************************************
 @Purpose :To Select Faq List And FaqCategory Tabs.
 /*************************************************************/
 onSelect(event) {
  if (event == 'CategoryList') {
    this.tabs.CategoryList = true;
    this.tabs.CategoryProductList = false;
    this.setDTableInitialData();
  }
  else if (event == 'CategoryProductList') {
    this.tabs.CategoryList = false;
    this.tabs.CategoryProductList = true;
    this.setDTableInitialData1();
  }
}
/***************************************************************** */

}
