import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EarningComponent } from './earning.component';
import { ListComponent } from './list/list.component';
import { WithdrawlistComponent } from './withdrawlist/withdrawlist.component';

const routes: Routes = [
  { 
    path: 'list',
    component: ListComponent 
  },
  {
    path: 'Withdrawals',
    component : WithdrawlistComponent
  },
  {
    path:'',redirectTo:'list',pathMatch:'full'
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EarningRoutingModule { }
