<!-- BEGIN: Sub Header  -->

<!-- END: Sub Header  -->
<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <div class="card-title">
                <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-xl-2 col-sm-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-lg-2 col-sm-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-content-area" [hidden]="commonService.showSkeletonLoader">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">Category</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
                </li>
                <li [routerLink]="[URLConstants.PRODUCT_CATEGORY_LIST]" class="breadcrumb-item">Menu-Category</li>
                <li class="breadcrumb-item active" aria-current="page">
                    {{this.CategoryValue}}
                </li>
            </ol>
        </nav>
        <div class="card notification-card heading-card p-0">

            <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                <div class="icon d-flex align-items-center justify-content-center mr-2"><em class='bx bx-message-alt-edit' aria-hidden="true"></em>
                </div>
                <div class="text">
                    <h5 class="mb-0 text Uppercase">{{CategoryValue}}</h5>
                </div>
            </div>
            <div class="content-wrapper heading-card-body" [hidden]="commonService.showSkeletonLoader">
                <form [formGroup]="myForm" (ngSubmit)="submit()" novalidate>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name">Name<sup class="bx bxs-star danger"></sup></label>
                                <input formControlName="name" id="name" type="text" class="form-control"
                                    placeholder="Enter Name">
                                <div *ngIf="submitted && f.name.errors" class="error-block">
                                    <div *ngIf="f.name.errors.required">Please enter name</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name">File</label>
                                <div class="upload-btn-wrapper">
                                    <div class="profile-upload" *ngIf="!this.categoryID">
                                        <img [src]="ImageURL?ImageURL:'../../../assets/images/default-upload.png'"
                                            class="default-img" alt='' />
                                    </div>
                                    <div class="profile-upload" *ngIf="this.categoryID">
                                        <img [src]="this.Image?ImageURL||Image:'../../../assets/images/default-upload.png'"
                                            class="default-img" alt=''/>
                                    </div>

                                    <button class="btn">Upload an image</button>

                                    <input formControlName="image" id="image" type="file" class="form-control"
                                        (change)="fileChangeEvent($event,template)" accept="image/png, image/jpeg">

                                    <!-- <div *ngIf="submitted && f.image.errors" class="error-block">
                                        <div *ngIf="f.image.errors.required">Please upload image</div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right submit-btn-wrap mt-3 mb-4">
                        <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        <button type="button" class="btn btn-outline-primary"
                            [routerLink]="[URLConstants.PRODUCT_CATEGORY_LIST]">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">
            Crop
            <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                <em class="la la-close"></em>
            </a>
        </h4>

    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
            [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>