import { messages } from './../../../common/constants/errorMessagesData';
import { MessageServiceService } from './../../../common/service/message-service.service';
import { Component, DoCheck, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';

// interfaces
import { IBreadcrumbs, ITableSetupData } from '../../../common/interfaces/interfaces';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
// constants
import { DATE_PATTERN, CUURENCY_CODE } from '../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import * as moment from 'moment';
// services
import { CommonService } from '../../../common/service/common.service';

import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import { ResponsiveDataTableComponent } from '../../../shared/components/responsive-data-table/responsive-data-table.component'
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [DatePipe]
})
export class ListComponent implements OnInit, AfterViewInit {
  DATE_PATTERN = DATE_PATTERN;
  CUURENCY_CODE = CUURENCY_CODE;
  Display = '';
  staticData = [{ name: 'Month', value: 'Monthly' }, { name: 'Week', value: 'Weekly' }, { name: 'Year', value: 'Yearly' }];
  timeSelection = { page: 1, pagesize: 10 };
  xCategories = [];
  ycategories = [];
  dateRangeSelectionStartDate;
  dateRangeSelectionEndDate;
  totalEarnings: number;

  startDate: string = moment().startOf('month').format('YYYY-MM-DD');
  endDate: string = moment().endOf('month').format('YYYY-MM-DD');

  startYearDate: string = moment().startOf('year').format('YYYY-MM-DD');
  endYearDate: string = moment().endOf('year').format('YYYY-MM-DD');

  startWeekDate: string = moment().startOf('week').format('YYYY-MM-DD');
  endWeekDate: string = moment().endOf('week').format('YYYY-MM-DD');



  // highcharts = Highcharts;
  public highCharts = Highcharts;
  public currentDate = new Date();
  filterStatuss = ['Debit', 'Credit'];
  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  @ViewChild('filterStatus') filterStatus: any;
  @ViewChild('drp')
  drp: ElementRef;
  URLConstants = URLConstants;
  public accessPermission: any;
  callAPIConstants = callAPIConstants;
  tabs: any = {};
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  minDate = new Date();

  constructor(private getPermissionsService: GetPermissionsService, private datePipe: DatePipe,
    private commonService: CommonService, private mesService: MessageServiceService) {
    this.setBreadcrumbs();
    this.setDTableInitialData();
    this.chartData = {
      month: new Date(), months: 1,
      revenueMonths: 'Monthly',
      // revenueMonths: 1,
    };
    this.minDate.setDate(this.minDate.getDate() - 1);
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue, this.maxDate];
  }
  ngOnInit() {
    this.setDTableInitialData();
    this.onMonthSelection();

    this.tabs.EarningList = true;
    this.Display = 'Earning';
    this.highCharts.chart('orderChart', this.chartOptions);
  }
  ngAfterViewInit() {
  }
  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'Earning & WithDraw', url: null },

    ];
    this.subHeaderData = {
      title: 'Earning & WithDraw',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };
  setDTableInitialData() {
    const tempData = [
      { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: false },
      { type: 'text', colName: '#Oder', colFieldname: 'orderUniqueId', isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'amount', colName: 'Amount', colFieldname: 'amount', isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'text', colName: 'Type', colFieldname: 'type', isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'textForStatus', colName: 'Status', colFieldname: 'requestStatus', isVisible: true, sort: true, columnVisibility: true, filter: true },

      {
        type: 'text', colName: 'Created Date', colFieldname: 'createdAt', isDate: true, isVisible: true, columnVisibility: true,
        filter: true
      },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: false },
    ];
    // if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'earningListing';
    this.tableSetupData.actions = [
      {
        id: 1, buttonTitle: 'view', tooltip: 'view', class: 'bx bx-show-alt',
        type: 'icon',
      },
      {
        id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'bx bx-edit',
        type: 'icon',
      },
      {
        id: 3, buttonTitle: 'delete', tooltip: 'Delete', class: "bx bx-trash-alt text-danger",
        type: 'icon',
      }
    ];
    this.tableSetupData.params = { deleteParams: '_id', statusParams: '_id' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: true,
      showButton: { routerLink: this.URLConstants.WITHDRAWALS, buttonName: 'Withdrawals' },
    };
  }
  //******************************************* */

  Earning: any;
  EarningList: any = {
    page: 1,
    filterStatus: null,
    fromDate: "",
    toDate: ""
  }
  /*********************************************************************************************/




  /*************************************************************
  @Purpose : based on selection calling API
  /*************************************************************/

  //  dateRange selection**
  dateRangeSelection(event) {
    if (event) {
      this.timeSelection['filterStatus'] = event;
      // this.onMonthSelection();
      this.updateEarningChart();
    }
  }

  // Monthy/Yearly date selection dynamically 
  onMonthSelection() {
    if (this.chartData.revenueMonths === 'Monthly') {
      this.timeSelection['IsYearly'] = false;
      this.timeSelection['fromDate'] = this.startDate;
      this.timeSelection['toDate'] = this.endDate;
    }
    if (this.chartData.revenueMonths === 'Weekly') {
      this.timeSelection['IsYearly'] = false;
      this.timeSelection['fromDate'] = this.startWeekDate;
      this.timeSelection['toDate'] = this.endWeekDate;
    }
    if (this.chartData.revenueMonths === 'Yearly') {
      this.timeSelection['IsYearly'] = true;
      this.timeSelection['fromDate'] = this.startYearDate;
      this.timeSelection['toDate'] = this.endYearDate;
    }
    this.updateEarningChart();
  }

  updateEarningChart() {
    this.commonService.callApi(this.callAPIConstants.earningListingVendor, this.timeSelection, 'post', false, false, false).then((success) => {
      if (success.status === 1 && success.data.earningData) {
        this.totalEarnings = success.data.totalEarnings;
        this.chartOptions.xAxis.categories = success.data.resultObj.categories;
        this.chartOptions.series[0].data = success.data.resultObj.series[0].values;
        this.highCharts.chart('orderChart', this.chartOptions);
      }
    });
  }

  goPreviousMonth() {
    this.startDate = moment(this.startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    this.endDate = moment(this.endDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    this.onMonthSelection();
  }

  goNextMonth() {
    this.startDate = moment(this.startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
    this.endDate = moment(this.endDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
    this.onMonthSelection();
  }

  goPreviousYear() {
    this.startYearDate = moment(this.startYearDate).subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
    this.endYearDate = moment(this.endYearDate).subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
    this.onMonthSelection();
  }

  goNextYear() {
    this.startYearDate = moment(this.startYearDate).add(1, 'year').startOf('year').format('YYYY-MM-DD');
    this.endYearDate = moment(this.endYearDate).add(1, 'year').endOf('year').format('YYYY-MM-DD');
    this.onMonthSelection();
  }

  goPreviousWeek() {
    this.startWeekDate = moment(this.startWeekDate).subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
    this.endWeekDate = moment(this.endWeekDate).subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
    this.onMonthSelection();
  }

  goNextWeek() {
    this.startWeekDate = moment(this.startWeekDate).add(1, 'week').startOf('week').format('YYYY-MM-DD');
    this.endWeekDate = moment(this.endWeekDate).add(1, 'week').endOf('week').format('YYYY-MM-DD');
    this.onMonthSelection();
  }
  /*********************************************************************************************/


  openMonthYearViewCalender(container) {
    container.monthSelectHandler = (event: any) => {
      const data = container._store.dispatch
        (container._actions.select(event.date));
    };
    const data = container.setViewMode('month');
  }

  /*************************************************************
   @Purpose :chart.
   /*************************************************************/
  chart = {
    type: 'column'
  };
  chartData: any = {};

  chartOptions: any = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Earning Report'
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: [],
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Rainfall (mm)'
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: [
      {
        name: 'Earnings',
        data: []
      }
    ]
  };

  // charts = new Chart(this.myIncomeOptions)
  charts2 = new Chart(this.chartOptions)
  ///
  onActivated(event) {
    this.mesService.subjects.next(event);
  }
  /***************************************************************** */

}
