import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// routing
import { AppRoutingModule } from './app-routing.module';


// Common
import { CommonService } from './common/service/common.service';
import { ErrorMessages } from './common/service/errorMessages';
import { CanLoginActivate, CanAuthActivate, isProfileCompleted } from './common/service/auth-guard';

// plugins
import { AgmCoreModule } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { ChartModule } from 'angular-highcharts';

// component
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AdminResetPasswordComponent } from './public/admin-reset-password/admin-reset-password.component';
import { CheckPasswordDirective } from '.././app/common/service/check-password.directive';

@NgModule({
  bootstrap: [AppComponent],

  declarations: [
    AppComponent,
    AdminResetPasswordComponent,  
    CheckPasswordDirective,
    AdminResetPasswordComponent,
    CheckPasswordDirective,
  ],
  imports: [
    SharedModule,
    FormsModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCc7PVGmsadb2ZoiaROparpua5ARrCjSZE',
      libraries: ['places']
    }),
    ChartModule,
  ],

  exports: [],
  providers: [
    CanLoginActivate,
    isProfileCompleted,
    CommonService,
    CanAuthActivate,
    BsModalRef,
    ErrorMessages
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }


