<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <div class="card-title">
                <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-xl-2 col-sm-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-lg-2 col-sm-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END : Show Skeleton Loader -->

<div class="main-content-area" [hidden]="commonService.showSkeletonLoader">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">Cuisine</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
                </li>
                <li class="breadcrumb-item" [routerLink]="[URLConstants.CUISINE]" aria-current="page">Cuisines</li>

                <li class="breadcrumb-item active" aria-current="page">
                    {{cuisineValue|titlecase}}
                </li>
            </ol>
        </nav>
        <div class="card notification-card heading-card p-0">

            <!-- <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                <div class="icon d-flex align-items-center justify-content-center mr-2"><i class='bx bx-mail-send'></i>
                </div>
                <div class="text">
                    <h5 class="mb-0 text Uppercase">{{cuisineValue}}</h5>
                </div>
            </div> -->
            <div class="content-wrapper heading-card-body" [hidden]="commonService.showSkeletonLoader">
                <form #form="ngForm" (ngSubmit)="addCuisine(form,cuisineData)" novalidate>
                    <div class="row form-group">
                        <div class="col-lg-6">
                            <label for="">Name<sup class="bx bxs-star danger"></sup></label>
                            <input placeholder="Enter Name" type="text" class="form-control" [(ngModel)]="cuisineData.name" name="name" id="name"
                                #name="ngModel" minlength="3" pattern="^[a-zA-Z ]*$" required>
                            <div *ngIf="submitted && name.errors" class="error-block">
                                    <p>Please enter name</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="name">Image</label>
                                <div class="upload-btn-wrapper">
                                    <div class="profile-upload" *ngIf="!this.cuisineID">
                                        <img [src]="this.ImageURL?this.ImageURL:'../../../assets/images/default-upload.png'"
                                            [alt]="myForm.value.name">
                                    </div>
                                    <div class="profile-upload" *ngIf="this.cuisineID">
                                        <img [src]="cuisineData.image?ImageURL||cuisineData.image:'../../../assets/images/default-upload.png'"
                                            [alt]="myForm.value.name">
                                    </div>
                                    <!-- <div class="profile-upload">
                                       <img [src]="imageUrl?imageUrl:'../../../assets/images/default-upload.png'" class="default-img" />
                                     </div>               -->
                                    <button class="btn">Upload a file</button>
                                    <input [(ngModel)]="cuisineData.image" id="image" accept="image/png,image/jpeg" type="file" name="image"
                                        class="form-control" #image="ngModel"
                                        (change)="fileChangeEvent($event,template)" required>

                                        <!-- <div *ngIf="submitted && image.errors" class="error-block">
                                            <p>Please add cuisine image</p>
                                    </div> -->
                                    <!-- <input type="file" (change)="handleFileInput($event.target.files)" name="myfile" /> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right submit-btn-wrap mt-3 mb-4">
                        <button type="submit" class="btn btn-primary mr-2"
                            >Submit</button>
                        <button type="submit" class="btn btn btn-outline-primary"
                            [routerLink]="[URLConstants.CUISINE]">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">
            Crop
            <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                <em class="la la-close"></em>
            </a>
        </h4>

    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
            [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>