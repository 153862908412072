import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';

// interface
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';

// constants
import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent implements OnInit, DoCheck {


  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  URLConstants = URLConstants;
  public accessPermission: any;
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };

  constructor(private getPermissionsService: GetPermissionsService,
    private commonService: CommonService) {
    // START: Role Permission //
    this.accessPermission = this.getPermissionsService.getRolePermissions('adminUser');
    // END: Role Permission //
  }

  ngOnInit() {
    this.setDTableInitialData();
  }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                   sort: Boolean ( To enable Sort for that Column )
                   isVisible: Boolean ( Show that Column Statically )
                   columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                   filter: Boolean ( To enable Filter for that Column )
 /*********************************************************************************************/
  setDTableInitialData() {
    const tempData = [
      { type: 'text', colName: '#Order', colFieldname: 'orderId', isVisible: true, sort: true, columnVisibility: true, filter: false },
      {
        type: 'routerLinkText', colName: 'User', colFieldname: 'userInfo', isObj: 'fullName', isVisible: true, sort: true,
        columnVisibility: true, filter: false, routerLink: URLConstants.USERS_DETAIL
      },
      {
        type: 'routerLinkText', colName: 'Vendor', colFieldname: 'vendorInfo', isObj: 'businessName', isVisible: true, sort: true,
        columnVisibility: true, filter: false, routerLink: URLConstants.USERS_DETAIL
      },
      { type: 'amount', colName: 'Amount', colFieldname: 'grandTotal', isVisible: true, columnVisibility: true, filter: false, sort: true },
      { type: 'orderStatus', colName: 'Status', colFieldname: 'status', isVisible: true, columnVisibility: true, filter: true },
      {
        type: 'text', colName: 'Created Date', colFieldname: 'createdAt', isVisible: true, columnVisibility: true,
        filter: false, isDate: true
      },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },
    ];
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'adminorderlist';
    this.tableSetupData.actions = [
      {
        id: 1, buttonTitle: 'view', tooltip: 'View', class: 'bx bx-show-alt',
        type: 'icon',
      },
    ];
    this.tableSetupData.params = { deleteParams: 'orderId', statusParams: 'orderId' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: true,
      showColumnHide: true,
      showFilter: false,
      showFilterExport: true,
      showSaveFilter: false,
      search: false,
    };
  }
  /********************************************************************************************************/
}
