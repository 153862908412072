import { Component, OnInit } from '@angular/core';

// constants
import { callAPIConstants } from '../../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../../common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowErrorService } from 'src/app/common/service/show-error.service';

@Component({
  selector: 'app-edit-details',
  templateUrl: './add-edit-faq-category.component.html',
  styleUrls: ['./add-edit-faq-category.component.scss']
})

export class EditDetailsComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public submitted = false;
  categorylist: any;
  CategoryId: any;
  saveCategoryFaqsFormData = { faqCategoryName: '', faqCategoryId: '' };

  constructor(private commonService: CommonService, public router: Router, private localStorageService: LocalStorageService,
    private showErrorService: ShowErrorService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params) => { this.CategoryId = params.id; });
    (this.CategoryId === undefined) ? null : this.getFaqsCategory();

  }

  ngOnInit(): void { }

  /*********************************************************************************************
  @Purpose     : Get faq category details
  @Parameter   : NA
  @Return      : NA
  /*********************************************************************************************/
  getFaqsCategory() {
    this.commonService.callApi(this.callAPIConstants.FaqsCategoryDetails + this.CategoryId, '', 'get', false, false, false)
      .then((success) => {
        if (success.status === 1) {
          this.saveCategoryFaqsFormData.faqCategoryId = success.data._id;
          this.saveCategoryFaqsFormData.faqCategoryName = success.data.faqCategoryName;
        }
      });
  }
  /*********************************************************************************************/

  /*********************************************************************************************
  @Purpose     : add / update faq category
  @Parameter   : form, formData
  @Return      : NA
  /*********************************************************************************************/
  saveFaqsCategory(form, formData): void {
    this.saveCategoryFaqsFormData.faqCategoryName = formData.faqCategoryName;
    if (formData.faqCategoryId) { this.saveCategoryFaqsFormData.faqCategoryId = formData.faqCategoryId; }
    this.submitted = true;
    if (form.valid) {
      this.commonService.callApi(this.callAPIConstants.AddUpdateFaqsCategory, this.saveCategoryFaqsFormData, 'post', false, false)
        .then((resposnseData) => {
          if (resposnseData.status === 1) {
            this.showErrorService.popToast('success', 'Updated Successfully!');
          } else { this.showErrorService.popToast('error', resposnseData.message); }
        });
    }
  }
  /*********************************************************************************************/
}
