<!-- Begin: skeleton loader -->
<div class="main-content-area" [hidden]="!commonService.showSkeletonLoader">
    <div class="main-content-block">
        <ngx-skeleton-loader count="1" [theme]="{ 'width': '50%' }"></ngx-skeleton-loader>
        <div class="card notification-card heading-card p-0">
            <div class="notification-title text-uppercase mb-md-3 mb-2 mt-3">
                <ngx-skeleton-loader count="1" [theme]="{ 'width': '30%' }"></ngx-skeleton-loader>
            </div>
            <div class="content-wrapper heading-card-body">
                <div class="address-info">
                    <div class="p-0 mb-3">
                        <div class="animated fadeIn">
                            <div class="row">
                                <!--Begin: Order Information -->
                                <div class="col-md-6">
                                    <div class="card-box">
                                        <h5 class="card-title mb-4">
                                            <ngx-skeleton-loader count="1" [theme]="{ 'width': '50%' }">
                                            </ngx-skeleton-loader>
                                        </h5>
                                        <div class="order-details-blog">
                                            <ul>
                                                <li>
                                                    <p>
                                                        <ngx-skeleton-loader count="1" [theme]="{ 'width': '80%' }">
                                                        </ngx-skeleton-loader>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <ngx-skeleton-loader count="1" [theme]="{ 'width': '80%' }">
                                                        </ngx-skeleton-loader>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <ngx-skeleton-loader count="1" [theme]="{ 'width': '80%' }">
                                                        </ngx-skeleton-loader>
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!--End: Order Information -->

                                <!--Begin: Account Information -->
                                <div class="col-md-6">
                                    <div class="card-box">
                                        <h5 class="card-title mb-4">
                                            <ngx-skeleton-loader count="1" [theme]="{ 'width': '50%' }">
                                            </ngx-skeleton-loader>
                                        </h5>
                                        <div class="order-details-blog">
                                            <ul>
                                                <li>
                                                    <p>
                                                        <ngx-skeleton-loader count="1" [theme]="{ 'width': '80%' }">
                                                        </ngx-skeleton-loader>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <ngx-skeleton-loader count="1" [theme]="{ 'width': '80%' }">
                                                        </ngx-skeleton-loader>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <ngx-skeleton-loader count="1" [theme]="{ 'width': '80%' }">
                                                        </ngx-skeleton-loader>
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!--End: Account Information -->
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Begin: Order status -->
                <div class="mt-5 address-info">
                    <div class="p-0">
                        <div class="animated fadeIn">
                            <div class="card-box">
                                <div class="p-0 mb-3">
                                    <h5 class="card-title mb-4">
                                        <ngx-skeleton-loader count="1" [theme]="{ 'width': '50%' }">
                                        </ngx-skeleton-loader>
                                    </h5>
                                </div>
                                <div class="mx-auto">
                                    <div class="d-flex justify-content-center">
                                        <div class="col-12">
                                            <ngx-skeleton-loader count="1"
                                                [theme]="{ 'width': '100%', padding:'40px' }">
                                            </ngx-skeleton-loader>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End: Order status -->

                <!-- Begin: Address -->
                <div class="mt-5 address-info">
                    <div class="animated fadeIn">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-box">
                                    <h5 class="card-title mb-4">
                                        <ngx-skeleton-loader count="1" [theme]="{ 'width': '50%' }">
                                        </ngx-skeleton-loader>
                                    </h5>
                                    <p>
                                        <ngx-skeleton-loader count="1" [theme]="{ 'width': '100%', padding:'30px' }">
                                        </ngx-skeleton-loader>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End: Address -->
            </div>
        </div>
    </div>
</div>
<!-- End: skeleton loader -->

<div class="main-content-area" [hidden]="commonService.showSkeletonLoader">
    <div class="main-content-block">

        <!--Begin: Breadcrumbs -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">View Order</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                    <a href="javascript;;"
                        [routerLink]="router.url.includes(URLConstants.ADMIN_ORDER_VIEW) ? URLConstants.ADMIN_ORDER_LIST : URLConstants.ORDER">Orders</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Order Details
                </li>
            </ol>
        </nav>
        <!--End: Breadcrumbs -->

        <div class="card notification-card heading-card p-0">
            <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                <div class="icon d-flex align-items-center justify-content-center mr-2"><em class='bx bx-file-find'></em>
                </div>
                <div class="text">
                    <h5 class="mb-0 text Uppercase">Order Details</h5>
                </div>
            </div>
            <div class="content-wrapper heading-card-body">
                <ng-container *ngIf="orderStatus === 'ongoing' && !router.url.includes(URLConstants.ADMIN_ORDER_VIEW)">
                    <div class="d-flex justify-content-end mb-4">
                        <button type="button" class="btn btn-primary mx-0" (click)="orderStatusChange('Delivery')">Ready
                            for Pickup</button>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="orderStatus === 'prepared' && !orderDelivery && !router.url.includes(URLConstants.ADMIN_ORDER_VIEW) ">
                    <div class="d-flex justify-content-end mb-4">
                        <button type="button" class="btn btn-primary mx-0 ml-2"
                            (click)="orderStatusChange('Pickup')">Picked</button>
                    </div>
                </ng-container>
                <div class="address-info">
                    <div class="p-0 mb-3">
                        <div class="animated fadeIn">
                            <div class="row">
                                <!--Begin: Order Information -->
                                <div class="col-md-6">
                                    <div class="card-box">
                                        <h5 class="card-title mb-4">Order Information</h5>
                                        <div class="order-details-blog">
                                            <ul>
                                                <li>
                                                    <p><strong><span class="order-date">Order ID:
                                                            </span></strong><span>{{orderDetail?.orderId}}</span></p>
                                                </li>
                                                <li>
                                                    <p><strong><span class="order-date">Order On:
                                                            </span></strong><span>{{orderDetail?.createdAt |
                                                            date:DATE_PATTERN}}</span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p><strong><span class="order-date">Total items :
                                                            </span></strong><span>{{orderDetail?.totalItems}}</span>
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!--End: Order Information -->

                                <!--Begin: Account Information -->
                                <div class="col-md-6">
                                    <div class="card-box">
                                        <h5 class="card-title mb-4">Account Information</h5>
                                        <div class="d-flex align-items-top">
                                            <div class="user-img mr-3">
                                                <figure class="img-circle mb-0">
                                                    <img class="img-fluid"
                                                        [src]="orderDetail?.userInfo?.photo ? this.uploadImageUrl + orderDetail?.userInfo?.photo : 'assets/images/NoProfile.png'"
                                                        width="100" height="100" alt="no img" />
                                                </figure>
                                            </div>
                                            <div class="order-details-blog">
                                                <ul>
                                                    <li>
                                                        <p><strong><span class="order-date">Customer Name:
                                                                </span></strong><span>{{orderDetail?.userInfo?.fullName}}</span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p><strong><span class="order-date">Email:
                                                                </span></strong><span>{{orderDetail?.userInfo?.emailId}}<a></a></span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p><strong><span class="order-date">Phone no:
                                                                </span></strong><span>{{orderDetail?.userInfo?.countryCode}}
                                                                &nbsp; {{orderDetail?.userInfo?.mobile ?
                                                                orderDetail?.userInfo?.mobile : '-'}}<a></a></span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--End: Account Information -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="address-info">
                    <div class="p-0 mb-3">
                        <div class="animated fadeIn">
                            <div class="row">

                                <!--Begin: Vendor Information -->
                                <div class="col-md-12" *ngIf="router.url.includes(URLConstants.ADMIN_ORDER_VIEW)">
                                    <div class="card-box">
                                        <h5 class="card-title mb-4">Vendor Information</h5>
                                        <div class="d-flex align-items-top">
                                            <div class="user-img mr-3">
                                                <figure class="img-circle mb-0">
                                                    <img class="img-fluid"
                                                        [src]="orderDetail?.vendorInfo?.photo ? this.uploadImageUrl + orderDetail?.vendorInfo?.photo : 'assets/images/NoProfile.png'"
                                                        width="100" height="100" alt="no img" />
                                                </figure>
                                            </div>
                                            <div class="order-details-blog">
                                                <ul>
                                                    <li>
                                                        <p><strong><span class="order-date">Full Name:
                                                                </span></strong><span>{{orderDetail?.vendorInfo?.fullName
                                                                ? orderDetail?.vendorInfo?.fullName : '-'}}</span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p><strong><span class="order-date">Business Name:
                                                                </span></strong><span>{{orderDetail?.vendorInfo?.businessName
                                                                ? orderDetail?.vendorInfo?.businessName : '-'}}</span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p><strong><span class="order-date">Email:
                                                                </span></strong><span>{{orderDetail?.vendorInfo?.emailId}}<a></a></span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p><strong><span class="order-date">Phone no:
                                                                </span></strong><span>{{orderDetail?.vendorInfo?.countryCode}}
                                                                &nbsp; {{orderDetail?.vendorInfo?.mobile ?
                                                                orderDetail?.vendorInfo?.mobile : '-'}}<a></a></span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--End: Vendor Information -->
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Begin: Order status -->
                <div class="mt-5 address-info">
                    <div class="p-0">
                        <div class="animated fadeIn">
                            <div class="card-box">
                                <div class="p-0 mb-3">
                                    <h5 class="card-title mb-4">Order Status</h5>
                                </div>
                                <div class="mx-auto">
                                    <div class="d-flex justify-content-center">
                                        <div class="col-12">
                                            <ul id="progressbar" class="text-center">
                                                <li class="step0"
                                                    [ngClass]="orderStatus =='delivered' ||orderStatus =='ongoing' ||orderStatus =='prepared' || orderStatus =='picked' ? 'active step0' : 'step0' ">
                                                    <div
                                                        class="d-flex justify-content-center mt-4 flex-column progressbar-text">
                                                        <p class="font-weight-bold"><em
                                                                class='bx bxs-bell-ring'></em>Order Request</p>
                                                                <!-- ||orderStatus =='pending' -->
                                                    </div>
                                                </li>
                                                <li class="step0"
                                                    [ngClass]="orderStatus =='delivered' ||orderStatus =='ongoing' ||orderStatus =='prepared' || orderStatus =='picked'? 'active step0' : 'step0' ">
                                                    <div
                                                        class="d-flex justify-content-center mt-4 flex-column progressbar-text">
                                                        <p class="font-weight-bold"><em
                                                                class='bx bx-restaurant'></em>Under Preparation</p>
                                                    </div>
                                                </li>
                                                <li class="step0"
                                                    [ngClass]="orderStatus =='delivered' ||orderStatus =='prepared' || orderStatus =='picked'? 'active step0' : 'step0' ">
                                                    <div
                                                        class="d-flex justify-content-center mt-4 flex-column progressbar-text">
                                                        <p class="font-weight-bold"><em class='bx bx-dish'></em>Order
                                                            Prepare</p>
                                                    </div>
                                                </li>
                                                <li class="step0"
                                                    [ngClass]="orderStatus =='delivered' || orderStatus =='picked' ? 'active step0' : 'step0'">
                                                    <div
                                                        class="d-flex justify-content-center mt-4 flex-column progressbar-text">
                                                        <p class="font-weight-bold"><em class='bx bxs-package'></em>Order
                                                            Picked Up</p>
                                                    </div>
                                                </li>
                                                <li class="step0"
                                                    [ngClass]="orderStatus =='delivered' ? 'active step0' : 'step0'">
                                                    <div
                                                        class="d-flex justify-content-center mt-4 flex-column progressbar-text">
                                                        <p class="font-weight-bold"><em class='bx bx-home'></em>Delivered
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End: Order status -->

                <!-- Begin: Address -->
                <div class="mt-5 address-info">
                    <div class="animated fadeIn">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-box">
                                    <h5 class="card-title mb-4">Address</h5>
                                    <p>{{orderDetail?.deliveryAddress?.address ? orderDetail?.deliveryAddress?.address :
                                        'Pick up order' }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End: Address -->

                <!-- Begin: Items Summary -->
                <div class="mt-5 address-info">
                    <div class="row">
                        <div class="col-md-12 item-order">
                            <div class="card-box mb-3">
                                <div class="p-0">
                                    <h5 class="card-title mb-4">Items Summary</h5>
                                </div>
                                <ng-container>
                                    <table aria-describedby="tableContent" class="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Product Name</th>
                                                <th scope="col">Unit Price</th>
                                                <th scope="col">Order Quantity</th>
                                                <th scope="col">Items</th>
                                                <th scope="col"
                                                    *ngIf="orderStatus == 'ongoing' && !router.url.includes(URLConstants.ADMIN_ORDER_VIEW)">
                                                    Prepare</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let val of cartData; let i=index">
                                             <!-- ****** Start For productInfo ****** -->
                                                <ng-container *ngIf="cartData[i].productInfo">
                                                <td>
                                                    <div class="thumb-img">
                                                        <span class="caption-image">
                                                            <img
                                                                [src]="cartData[i].productInfo.image ? this.uploadImageUrl + cartData[i].productInfo.image  : 'assets/images/NoProfile.png'" alt="no img">
                                                        </span> 
                                                    </div>
                                                </td>
                                                <td scope="row">{{cartData[i].productInfo.name}}</td>

                                                <!-- <td scope="row">{{finalValues[i]}}</td> -->
                                                <td>{{val.totalPrice ? (val.totalPrice | currency: CUURENCY_CODE) :
                                                    '0'}}</td>
                                                <td>{{val?.quantity}}</td>
                                                <td>
                                                    <ng-container *ngFor="let attributeItem of val.attribute">
                                                        <p>{{attributeItem?.name ? attributeItem?.name : 'N/A'}}</p>
                                                    </ng-container>

                                                    <!-- <ng-container *ngFor=" let categoryItems of val.categoryProducts">
                                                        <ng-container *ngFor=" let items of categoryItems.products">
                                                            <p>{{items?.name ? items?.name : 'N/A'}}</p>
                                                        </ng-container>
                                                    </ng-container> -->
                                                </td>

                                                <td
                                                    *ngIf="orderStatus == 'ongoing' && !router.url.includes(URLConstants.ADMIN_ORDER_VIEW)">
                                                    <input type="checkbox" (click)="prepareStatus(val)">
                                                </td>
                                            </ng-container>
                                           <!-- ****** End For productInfo ****** -->


                                            <!-- ****** Start For meal deals ****** -->
                                            <ng-container *ngIf="cartData[i].mealDealInfo">
                                                <td>
                                                    <div class="thumb-img">
                                                        <span class="caption-image">
                                                            <img
                                                                [src]="cartData[i].mealDealInfo.image ? this.uploadImageUrl + cartData[i].mealDealInfo.image  : 'assets/images/NoProfile.png'" alt="no img">
                                                        </span> 
                                                    </div>
                                                </td>
                                                <td scope="row">{{cartData[i].mealDealInfo.name}}</td>

                                                <!-- <td scope="row">{{finalValues[i]}}</td> -->
                                                <td>{{val.totalPrice ? (val.totalPrice | currency: CUURENCY_CODE) :
                                                    '0'}}</td>
                                                <td>{{val?.quantity}}</td>
                                                <td>
                                                    <ng-container *ngFor=" let categoryItems of val.categoryProducts">
                                                        <ng-container *ngFor=" let items of categoryItems.products">
                                                            <p>{{items?.name ? items?.name : 'N/A'}}</p>
                                                        </ng-container>
                                                    </ng-container>
                                                </td>

                                                <td
                                                    *ngIf="orderStatus == 'ongoing' && !router.url.includes(URLConstants.ADMIN_ORDER_VIEW)">
                                                    <input type="checkbox" (click)="prepareStatus(val)">
                                                </td>
                                            </ng-container>
                                             <!-- ****** End For meal deals ****** -->


                                            </tr>
                                        </tbody>
                                    </table>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End: Items Summary -->

                <div class="mt-5 address-info mb-5">
                    <div class="card-box mt-4">
                        <div class="row">
                            <!-- Begin: Driver Information -->
                            <div class="col-md-6" *ngIf="driverInfo && driverInfo != null">
                                <div class="card-box">
                                    <h5 class="card-title mb-4">Driver Information</h5>
                                    <div class="d-flex align-items-center">
                                        <div class="user-img mr-3">
                                            <figure class="img-circle mb-0">
                                                <img class="img-fluid"
                                                    [src]="driverInfo?.photo ? this.uploadImageUrl + driverInfo?.photo : 'assets/images/NoProfile.png'"
                                                    width="100" height="100" alt="no img" />
                                            </figure>
                                        </div>
                                        <div class="order-details-blog">
                                            <ul>
                                                <li>
                                                    <p><strong><span class="order-date">Driver Name:
                                                            </span></strong><span>{{driverInfo?.fullName}}</span>
                                                    </p>
                                                </li>
                                                <!-- <li>
                                                    <p><strong><span class="order-date">Email:
                                                            </span></strong><span>{{orderDetail?.userInfo?.emailId}}<a
                                                                ></a></span></p>
                                                </li> -->
                                                <li>
                                                    <p><strong><span class="order-date">Phone no:
                                                            </span></strong><span>{{driverInfo?.countryCode}} &nbsp;
                                                            {{driverInfo?.mobile ? driverInfo?.mobile :
                                                            'N/A'}}<a></a></span></p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- End: Driver Information -->

                            <!-- start: Virtual Drive Information -->
                            <div class="col-md-6" *ngIf="virtualDriveInfo && virtualDriveInfo != null">
                                <div class="card-box">
                                    <h5 class="card-title mb-4">Virtual Drive Information</h5>
                                    <div class="d-flex align-items-center">
                                        <div class="user-img mr-3">
                                            <figure class="img-circle mb-0">
                                                <img class="img-fluid"
                                                [src]="orderDetail?.userInfo?.photo ? this.uploadImageUrl + orderDetail?.userInfo?.photo : 'assets/images/NoProfile.png'"
                                                    width="100" height="100" alt="no img" />
                                            </figure>
                                        </div>
                                        <div class="order-details-blog">
                                            <ul>
                                                <li>
                                                    <p><strong><span class="order-date">Car Name:
                                                            </span></strong><span>{{virtualDriveInfo?.carName}}</span>
                                                    </p>
                                                </li>
                                                <!-- <li>
                                                    <p><strong><span class="order-date">Email:
                                                            </span></strong><span>{{orderDetail?.userInfo?.emailId}}<a
                                                                ></a></span></p>
                                                </li> -->
                                                <li>
                                                    <p><strong><span class="order-date">Car No:
                                                            </span></strong><span>{{virtualDriveInfo?.carNumber}} &nbsp;
                                                            {{virtualDriveInfo?.carNumber ? virtualDriveInfo?.carNumber :
                                                            'N/A'}}<a></a></span></p>
                                                </li>
                                                <li>
                                                    <p><strong><span class="order-date">Car Color:
                                                            </span></strong><span>{{virtualDriveInfo?.color}}</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- End: Virtual Drive Information -->





                            <!-- Begin: Order Totals -->
                            <div class="col-md-6">
                                <h5 class="card-title mb-4">Order Totals</h5>
                                <!-- <label><h4 class="sub-title">Order Totals</h4></label> -->
                                <table aria-describedby="tableContent" class="table dataTable row-border hover custom-table pb-4 border-0">
                                    <tbody>
                                        <tr class="animated fadeIn">
                                            <td>Subtotal</td>
                                            <td class="text-right">{{orderDetail?.subTotal ? (orderDetail?.subTotal |
                                                currency: CUURENCY_CODE) : '0'}}</td>
                                        </tr>
                                        <tr class="animated fadeIn">
                                            <td>Tax</td>
                                            <td class="text-right">
                                                {{orderDetail?.tax ? (orderDetail?.tax | currency: CUURENCY_CODE) :
                                                '0'}}</td>
                                        </tr>
                                        <tr class="animated fadeIn">
                                            <td>Discount</td>
                                            <td class="text-right">
                                                {{orderDetail?.discount ? (orderDetail?.discount | currency:
                                                CUURENCY_CODE) : '0'}}</td>
                                        </tr>
                                        <tr class="animated fadeIn">
                                            <td>Tip</td>
                                            <td class="text-right">
                                                {{orderDetail?.tip ? (orderDetail?.tip | currency: CUURENCY_CODE) :
                                                '0'}}</td>
                                        </tr>
                                        <tr class=" animated fadeIn">
                                            <td>Delivery Fees</td>
                                            <td class="text-right">
                                                {{orderDetail?.deliveryCharges ? (orderDetail?.deliveryCharges |
                                                currency: CUURENCY_CODE) : '0'}}</td>
                                        </tr>

                                        <tr class="animated fadeIn">
                                            <td><strong>Grand Total</strong></td>
                                            <td class="text-right">
                                                <strong>{{orderDetail?.grandTotal ? (orderDetail?.grandTotal | currency:
                                                    CUURENCY_CODE) : '0'}}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- End: Order Totals -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>