import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// component
import { EditCategoryComponent } from './add-edit-faq/add-edit-faq.component';
import { EditDetailsComponent } from './add-edit-faq-category/add-edit-faq-category.component';
import { ListComponent } from './list/list.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'list', pathMatch: 'full',
  },
  {
    path: 'list', component: ListComponent, pathMatch: 'full',
  },
  {
    path: 'edit-category/:id',
    component: EditDetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'add-faq-category',
    component: EditDetailsComponent,
    pathMatch: 'full',
  }, {
    path: 'editfaq/:id',
    component: EditCategoryComponent,
    pathMatch: 'full'
  },
  {
    path: 'addfaqs',
    component: EditCategoryComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule { }
