import { SharedModule } from './../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// shared
// import { SharedModule } from '../../shared/shared/shared.module';

// components
import { DashboardComponent } from './dashboard.component';

const dashboardRoutes: Routes = [
  {
    path: '', component: DashboardComponent, pathMatch: 'full',
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
   RouterModule.forChild(dashboardRoutes),
  ],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
