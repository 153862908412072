<div class="main-content-area">
  <div class="main-content-block">
    <!-- Begin: breadcrumbs -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="content-header-title">{{router.url.includes(URLConstants.ADD_FAQ_CATEGORY) ? 'Add FAQ Category' :
          'Edit FAQ Category'}}</li>
        <li class="breadcrumb-item">
          <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><em class="bx bx-home-alt"></em></a>
        </li>
        <li class="breadcrumb-item" [routerLink]="[URLConstants.FAQS]">Contents</li>
        <li class="breadcrumb-item active">
          <a [routerLink]="[URLConstants.FAQS]" href="javascript:;">FAQ Category</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{router.url.includes(URLConstants.ADD_FAQ_CATEGORY) ? 'Add FAQ Category' : 'Edit FAQ Category'}}
        </li>
      </ol>
    </nav>
    <!-- End: breadcrumbs -->

    <!-- Begin: Add/update faq category  -->
    <div class="card notification-card tabs-block p-0">
      <form (ngSubmit)="saveFaqsForm.form.valid && 
                      saveFaqsCategory(saveFaqsForm, saveCategoryFaqsFormData)" #saveFaqsForm="ngForm">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group align-items-center mb-md-5 mb-3 user-details">
                <label>FAQ Category Name<sup class="bx bxs-star danger"></sup></label>
                <input class="form-control" type="text" name="faqCategoryName"
                  [(ngModel)]="saveCategoryFaqsFormData.faqCategoryName" placeholder="Enter Faq Name"
                  id="faqCategoryName" #faqCategoryName="ngModel" autocomplete="on" required />
                <div
                  *ngIf="((submitted || faqCategoryName.touched || faqCategoryName.dirty) && faqCategoryName.invalid)"
                  class="alert alert-danger">
                  <div [hidden]="!faqCategoryName.errors.required">
                    fat is required
                  </div>
                  <div [hidden]="!faqCategoryName.errors.pattern">
                    Only Number allowed
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-blocks d-flex align-items-center justify-content-end mb-md-0 mb-2">
            <button class="btn btn-primary"
              [disabled]="!saveFaqsForm.form.valid">{{router.url.includes(URLConstants.ADD_FAQ_CATEGORY) ? 'Add' :
              'Update'}}</button>
          </div>
        </div>
      </form>
    </div>
    <!-- End: Add/update faq category  -->
  </div>
</div>