<div class="theme-setting-block" [ngClass]="{'open': isSettingOpen}">
    <a class="theme-setting-link" (click)="openSettingBox()">
        <em class="bx bx-cog bx-flip-horizontal" [ngClass]="{'bx-spin': !isSettingOpen}"></em>
    </a>
    <div class="d-flex align-items-center">
        <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
        <div class="custom-control custom-switch theme-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitchTheme">
            <label class="custom-control-label" for="customSwitchTheme"></label>
        </div>
        <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
    </div>
</div>

<!--Start Login Wrapper-->
<div class="login-wrapper" style="background:url(assets/images/login-bg.jpg) no-repeat center center; background-size: cover;">
	<div class="login-body">
		<section class="login-container row m-0">
				<div class="col-xl-8 col-11">
					<div class="card bg-login-card">
						<div class="row m-0">
							<div class="col-md-6 col-12 pl-0 pr-0">
								<div class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
									<div class="card-header">
										<div class="card-title text-center mb-4">
											<h4 class="mb-2">Welcome Back</h4>
										</div>
									</div>
									<div class="card-content">
										<div class="card-body">
											<form action="#">
                                                <div class="form-group mb-3">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-prepend">
                                                                <select name="" id="cssselect" class="custom-select selectpicker form-control">
                                                                    <option class="dropdown-item" value="">+ 91</option>
                                                                    <option class="dropdown-item" value="">+ 1</option>
                                                                    <option class="dropdown-item" value="">+ 2</option>
                                                                    <option class="dropdown-item" value="">+ 345</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <input type="number" class="form-control" aria-label="Text input with dropdown button" placeholder="99112234567">
                                                    </div>
                                                </div>
												<div class="form-group d-flex flex-md-row flex-column justify-content-between align-items-center mb-3">
													<div class="text-left">
														<div class="custom-checkbox sm">
															<label for="check1" class="mb-0">
																<input type="checkbox" name="csscheckbox" id="check1" autocomplete="off">
																<span></span>Keep me logged in
															</label>
														</div>
													</div>
												</div>
												<button type="button" class="btn btn-primary glow position-relative btn-block" routerLink="/enter-otp">
													Send OTP<em class="icon-arrow bx bx-right-arrow-alt"></em>
												</button>
											</form>
											<div class="text-center common-small-text mt-3 mb-4"><small>Don’t have an account? <a href="sign-up.html">Sign up</a></small></div>
											<div class="divider">
												<div class="divider-text text-uppercase text-muted"><small>or login with</small>
												</div>
											</div>
											<div class="d-flex flex-md-row flex-column justify-content-around">
												<a href="https://mail.google.com/" class="btn btn-social btn-google btn-block mr-0 mr-md-3 mb-md-0 mb-2">
													<em class="bx bxl-google btn-icon"></em><span class="pl-50 d-block text-center">Google</span></a>
												<a href="https://www.facebook.com/" class="btn btn-social btn-block mt-0 btn-facebook">
													<em class="bx bxl-facebook-square btn-icon"></em><span class="pl-50 d-block text-center">Facebook</span></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
								<div class="card-content">
									<picture>
										<source srcset="assets/images/login.webp" type="image/webp">
										<source srcset="assets/images/login.png" type="image/png"> 
										<img class="img-fluid" src="assets/images/login.png" alt="login">
									</picture>
								</div>
							</div>
						</div>
					</div>
				</div>
		</section>
	</div>
</div>
<!--End Login Wrapper-->
