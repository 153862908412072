import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRoute,
} from '@angular/router';

// constants
import { URLConstants } from '../constants/routerLink-constants';

// service
import { LocalStorageService } from './local-storage.service';

/****************************************************************************
@PURPOSE      : Dont allow public pages to get accessed. (After Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanLoginActivate implements CanActivate {
  URLConstants = URLConstants;
  constructor(public localStorageService: LocalStorageService, public router: Router) { }
  canActivate() {
    if (!this.localStorageService.getToken('accessToken')) {
      return true;
    }
    this.router.navigate([this.URLConstants.DASHBOARD]);
    return false;
  }
}

/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanAuthActivate implements CanActivate {
  URLConstants = URLConstants;
  constructor(public localStorageService: LocalStorageService, public router: Router) { }
  canActivate() {
    if (this.localStorageService.getToken('accessToken')) {
      return true;
    }
    this.router.navigate([this.URLConstants.LOGIN]);
    return false;
  }
}

//profileComplete check Here 
@Injectable()
export class isProfileCompleted implements CanActivate {
  URLConstants = URLConstants;
  constructor(public localStorageService: LocalStorageService,
    public router: Router, public activatedRoute: ActivatedRoute) { }
  canActivate() {
    const roleAccesor = this.localStorageService.getToken('role');
    const isProfileComplete = JSON.parse(this.localStorageService.getToken('profileComplete'));

    if (roleAccesor === 'vendor' && !isProfileComplete) {
      this.router.navigate(['/v-profile']);
      return false;
    }
    return true;
  }

}
/****************************************************************************/
@Injectable()
export class CanViewListActivate implements CanActivate {
  URLConstants = URLConstants;
  constructor(public localStorageService: LocalStorageService,
    public router: Router) { }
  canActivate() {
    if (true) {
      return true;
    }
    this.router.navigate([this.URLConstants.LOGIN]);
    return false;
  }
}







