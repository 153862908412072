import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MessageServiceService {
  subjects = new Subject<any>();
  scopeChanged$ = new EventEmitter<any>(true);
  sendMessage(message: string) {
      this.subjects.next({ text: message });
  }

  clearMessages() {
      this.subjects.next();
  }

  onMessage(): Observable<any> {
      return this.subjects.asObservable();
  }
}
