<!-- BEGIN : Show Skeleton Loader -->

<!-- END : Show Skeleton Loader -->
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!commonService.showSkeletonLoader">
    <div class="card animated fadeIn">
        <div class="card-header">
            <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-2">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
                <div class="col-md-2">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row mt-2">
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
            <ngx-skeleton-loader count="1" [theme]="{ 'padding': '80px' }"></ngx-skeleton-loader>
        </div>

    </div>
</div>
<!-- END : Show Skeleton Loader -->
<div class="main-content-area">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                
                <li class="content-header-title">Roles & Permissions</li>
                <li class="breadcrumb-item"><a href="dashboard.html"><em class="bx bx-home-alt"></em></a></li>
                <li class="breadcrumb-item">Roles & Permissions</li>
                <li class="breadcrumb-item active" aria-current="page">Add Role</li>
            </ol>
        </nav>
        <form #form="ngForm" (ngSubmit)="createUpdateRole(form)">
            <div class="card role-card pb-0 mb-1">
                <div class="form-group mb-md-4 mb-3">
                    <label for="role">Name<span class="error">*</span></label>
                    <input class="form-control" type="text" id="role" name="role" placeholder="Enter Role"
                        [(ngModel)]="addOrEditRole.role" #roleName="ngModel" required autocomplete="off" />
                    <div class="error-block text-left" style="color:red"
                        *ngIf="((submitted || roleName.touched) && roleName.invalid)">
                        {{showErrorService.showError('roleName', roleName.errors)}}
                    </div>
                </div>
                <!-- <div class="form-group mb-0">
                    <label for="Description">Description<span style="color:red">*</span></label>
                    <textarea rows="5" class="form-control" type="text" placeholder="Enter Description" name="description" [(ngModel)]="role.description" #description="ngModel" autocomplete="off" required></textarea>
                    <div class="error-block text-left" style="color:red"
                        *ngIf="((submitted || description.touched) && description.invalid)">
                        {{showErrorService.showError('description', description.errors)}}
                    </div>
                </div> -->
            </div>
            <div class="card profile-card role-card p-0">
                <div class="table-responsive">
                    <table aria-describedby="tableData" class="role-table w-100 m-4">
                        <thead>
                            <th id="tableModule">Module Permission</th>
                            <th id="tableModule" *ngFor="let cat of tableData">
                                <div class="custom-checkbox">
                                    <label [for]="cat">
                                        <input type="checkbox" [name]="cat" [id]="cat" [checked]=""
                                            (change)="multipleSelection(cat,$event.target.checked)" autocomplete="off">
                                        <span></span>{{ role }}
                                    </label>
                                </div>
                            </th>
                        </thead>
                        <tr *ngFor="let cat of tableData ">
                            <td class="pt-2">
                                {{cat.category}}
                            </td>
                            <td *ngFor="let per of cat.permissions" class="pt-2">

                                <div class="custom-checkbox">
                                    <label>
                                        <!-- <input *ngIf="!rolePermissions.length" type="checkbox" [name]="category.key+'_'+item" [id]="category.key+'_'+item" (change)="selectRole(category.key,item,$event.target.checked)" autocomplete="off"> -->
                                        <input type="checkbox" [name]="per.permission" [id]="per._id"
                                            (change)="selectRole(per._id,cat.categoryId,$event.target.checked)"
                                            [checked]="per.isSelected" autocomplete="off">
                                        <span></span>
                                        {{per.permission}}
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="text-right submit-btn-wrap">
                <button class="btn btn-primary mr-2">Save</button>
                <button type="button" class="btn btn-outline-primary"
                    [routerLink]="[URLConstants.CMS]">Cancel</button>

            </div>
        </form>
    </div>
</div>