import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{ListComponent} from './list/list.component';
import{ EditDetailsComponent} from './edit-details/edit-details.component';
import{ ViewDetailsComponent} from './view-details/view-details.component';

const routes: Routes = [
  { 
    path: 'list',
    component: ListComponent 
  },
  {
    path: 'addUpdateCategory',
    //canActivate: [CanCreateActivate],
    component: EditDetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'edit/:id',
     component: EditDetailsComponent,
      pathMatch: 'full',
  },
  {
    path: 'view/:id',
     component: ViewDetailsComponent,
      pathMatch: 'full',
  },
  {
    path:'',redirectTo:'list',pathMatch:'full'
  }
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CategoryRoutingModule { }
