import { MessageServiceService } from './../../../common/service/message-service.service';
import {
  AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild,
  NgZone
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
// environment
import { environment } from 'src/environments/environment';

// plugins
import { Subject, Subscription, concat, Observable, of, } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { catchError, debounceTime, distinctUntilChanged, map, merge, switchMap } from 'rxjs/operators';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { CUURENCY_CODE, DATE_PATTERN } from '../../../common/constants/categoryType-constants';

// Services
import { CommonService } from '../../../common/service/common.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';

// componnent
import { AssignModalComponent } from '../modals/assign-modal/assign-modal.component';
import { CusineDelModalComponent } from '../modals/cusine-del-modal/cusine-del-modal.component';

@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
  styles: [],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }],
})

export class ResponsiveDataTableComponent implements OnInit, AfterViewInit, OnDestroy {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  CUURENCY_CODE = CUURENCY_CODE;
  DATE_PATTERN = DATE_PATTERN;
  @ViewChild('filterForm', { static: false }) filterForm;
  public IstatusTypes = {};
  withdrawlData: any = {};
  public errortext = '';
  public showError = false;
  private activesubscription: Subscription;
  public uploadImageUrl = environment.uploadImgUrl;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  APIparameters: any = {};
  options: any = {};
  total: any;
  public type: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public columnVisibilityOptions = false;
  public filterColumnOption = false;
  public params: any;
  public conditions: any;
  @Input() searchText: any;
  search: any;
  public color: any = '';
  public filterColor: any = '#4184EF';
  public tempSettingsData: any = [];
  showSkeletonLoader1 = true;
  @Input() columns: Array<any>;
  @Input() accessPermission: any;
  @Input() tableSetupData: any;
  public tempData: any = [];
  public tableConfig: any = {};
  tableData = {
    cols: ['id', 'author', 'title'],
    data: [],
    rowSelected: 0,
    value: false,
    type: '',
  };
  public downloadUrl: any = environment.uploadImgUrl + '/public/';
  @ViewChild('customPagination', { static: false }) customPagination: any;
  public downloadColumnsArr: any = [];
  public columnVisibilityOptions2 = false;
  public columnKey: any;
  public columnsAction: any = false;
  downloadColumns: string;
  toggleDownloadButton: boolean;
  toggleFilterButton = false;
  getDowloadTemplates: string;
  downloadTemplatesArr: any = [];
  downloadUserFile: string;
  link: string;
  cols: any = [];
  configs: any = {};
  VendorId: any;
  MAX_RATING = 5;
  ticketStatusList: any = ['accepted', 'canceledByVendor', 'pending', 'ongoing', 'delivered'];
  EarningLists: any = {
    page: 1,
    filterStatus: ['credit', 'debit'],
    fromDate: '',
    toDate: '',
  };
  roleList: any = [{ name: 'Vendor', value: 'vendor' }, { name: 'Driver', value: 'driver' }, { name: 'User', value: 'user' }];
  faqCategoryList: any = ['Vendor', 'Driver', 'User'];
  public colSettingCols: Array<any> = [];
  status = false;
  statusDownload = false;
  statusColorPicker = false;
  statusFilterColorPicker = false;
  serverName: any;
  colors = [
    {
      hex: '#00cfdd',
      name: 'blue'
    },
    {
      hex: '#b3c0ce',
      name: 'gray'
    },
    {
      hex: '#FF5B5C',
      name: 'rose'
    },
    {
      hex: '#b87ee5',
      name: 'purple'
    },
    {
      hex: '#39DA8A',
      name: 'green'
    },
    {
      hex: '#FDAC41',
      name: 'yellow'
    },
  ];
  active = false;
  selectedColorName: '';
  selectedColor: '';
  EarningList: { page: number; filterStatus: string; fromDate: string; toDate: string; };
  filterContentList = [
    { name: 'Contains', value: 'contains' },
    // { name: 'Greater Than', value: 'greaterThan' },
    // { name: 'Less Than', value: 'lessThan' },
    // { name: 'Date', value: 'date' },
  ];
  public savedFilters: any = [];
  public condition: any = '$and';
  public filteredArr: any = [];
  public filterSaveSubmitted = false;
  public filterId: any;
  public filterCols: any = [];
  public counter = 0;
  BlogDetails: string;
  public getMethodName: any;
  public CSV: any;
  public EXCEL: any;
  public DeleteUser: any;
  public ChangeStatus: any;
  public getFilterlist: any;
  public colVisible: any;
  public saveFilter: any;
  public getFilter: any;
  public deleteFilter: any;
  public deleteDownloadTemplate: any;
  public deleteCuisine: any;
  public itemsPerPageList = [2, 5, 10, 25, 100];
  public itemsPerPage: number = this.localStorageService.getToken('itemsPerPage') ?
    parseInt(this.localStorageService.getToken('itemsPerPage')) : 10;
  currentPage = this.localStorageService.getToken('currentPage') ?
    parseInt(this.localStorageService.getToken('currentPage')) : 1;
  public dataOfSaveFilter: any;
  public disableResetFilter = true;
  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker'
  ];
  public columnVisibleArr: any[] = [];
  public statusFormData = { status: '' };
  public statusList: any[];
  public count: any;
  public errorMsg = '';
  public paramsId = '';
  public vendorFilterlist: Observable<any>;
  public vendorTypeAheadSource = new Subject<string>();
  public userFilterlist: Observable<any>;
  public userTypeAheadSource = new Subject<string>();
  vendorLoader = false;
  userLoader = false;
  orderListArr = [
    { statusName: 'On-Going', value: 'ongoing' },
    { statusName: 'Pending', value: 'pending' },
    { statusName: 'Delivered', value: 'delivered' },
    { statusName: 'Rejected By Vendor', value: 'canceledByVendor' },
    { statusName: 'Cancelled By Customer', value: 'canceledByCustomer' },
    { statusName: 'Prepared', value: 'prepared' },
    { statusName: 'Picked UP', value: 'picked' },
  ];

  constructor(public commonService: CommonService, public showErrorService: ShowErrorService, private router: Router,
    public bsModalRef: BsModalRef, private activatedRoute: ActivatedRoute, private modalService: BsModalService,
    private localStorageService: LocalStorageService, public restrictKeyPressService: RestrictKeyPressService, public zone: NgZone) {
    this.APIparameters = { page: 1, pagesize: 10 };
    this.VendorId = this.localStorageService.getToken('VendorId');
    this.activatedRoute.params.subscribe((params) => { this.paramsId = params.id; });
  }

  ngOnInit() {
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: { details: { renderer: Responsive.renderer.listHiddenNodes(), }, },
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 1, targets: -1 },
        { responsivePriority: 1, targets: -2 },
      ],
    };

    this.addObjForFilterArray();

    this.type = this.tableSetupData.type;
    this.params = this.tableSetupData.params;
    this.conditions = this.tableSetupData.conditions;
    this.tableConfig.cols = this.tableSetupData.cols;
    this.tableConfig.actions = this.tableSetupData.actions;
    this.tableConfig.cols.forEach(element => {
      if (element.drag) { this.colSettingCols.push(element); }
    });

    this.IstatusTypes = {
      ongoing: 'On-Going',
      canceledByVendor: (this.type === 'adminorderlist' || this.type === 'userDetailAdminorderlist') ?
        'Rejected By Vendor' : 'Rejected By You',
      canceledByCustomer: 'Cancelled By Customer',
      delivered: 'Delivered',
      prepared: 'Prepared',
      picked: 'Picked UP',
      pending: 'Pending',
      reject: 'Rejected',
      approve: 'Approved',
    };
    this.statusList = ['Active', 'Inactive', 'Delete'];
    if (this.type === 'adminorderlist') { this.userLoadTypehead(); this.vendorLoadTypehead(); }
  }

  ngAfterViewInit() {
    this.callApiToGetData();
    // options for datatable
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: {
        details: {
          renderer: Responsive.renderer.listHiddenNodes(),
        },
      },
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 1, targets: -1 },
        { responsivePriority: 1, targets: -2 },
      ],
    };
  }

  ngOnDestroy() {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    if (this.activesubscription) { this.activesubscription.unsubscribe(); }
  }

  /*************************************************************
  @Purpose    : Api for List
  @parameters : NA
  @returns    : NA
  /*************************************************************/
  // tslint:disable-next-line: cognitive-complexity
  async callApiToGetData() {
    if (this.type) {
      if (this.type === 'userlist') {
        this.getMethodName = this.callAPIConstants.UserList;
        this.CSV = this.callAPIConstants.UserCSVforDataTable;
        this.EXCEL = this.callAPIConstants.UserEXCELforDataTable;
        this.DeleteUser = this.callAPIConstants.UserDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.UserChangeStatus;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getFilterlist = this.callAPIConstants.UserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.APIparameters.searchText = this.searchText;
        this.colVisible = this.callAPIConstants.CommonColumnVisibleSettings;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadUserFile;
      } else if (this.type === 'adminUserlist') {
        this.getMethodName = this.callAPIConstants.AdminList;
        this.DeleteUser = this.callAPIConstants.AdminDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.AdminChangeStatus;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getDowloadTemplates = this.callAPIConstants.getDownloadTemplate;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadAdminUserFile;
      } else if (this.type === 'emailTemplateList') {
        this.getMethodName = this.callAPIConstants.EmailTemplateList;
        this.getFilterlist = this.callAPIConstants.EmailColumnData;
        this.DeleteUser = this.callAPIConstants.DeleteEmailTemplate;
        this.ChangeStatus = this.callAPIConstants.EmailChangeStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'cmsListing') {
        this.getMethodName = this.callAPIConstants.cmsListing;
        this.DeleteUser = this.callAPIConstants.CmsDelete;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'earningListing') {
        this.getMethodName = this.callAPIConstants.earningListingVendor;
      } else if (this.type === 'withDrawList') {
        this.getMethodName = this.callAPIConstants.withdrawList;
      } else if (this.type === 'mealdealist') {
        this.getMethodName = this.callAPIConstants.mealdealList;
        this.DeleteUser = this.callAPIConstants.mealdealDelete;
        this.ChangeStatus = this.callAPIConstants.mealdealStatus;
      } else if (this.type === 'vendorcuisineList') {
        this.getMethodName = this.callAPIConstants.getCuisine;
        this.APIparameters.searchQuery = this.searchText;
      } else if (this.type === 'cuisineList') {
        this.getMethodName = this.callAPIConstants.getCuisine_Admin;
        this.DeleteUser = this.callAPIConstants.DeleteCuisine;
        this.ChangeStatus = this.callAPIConstants.changeCuisineStatus;
        this.APIparameters.searchQuery = this.searchText;
      } else if (this.type === 'roleList') {
        this.getMethodName = this.callAPIConstants.RoleList;
        this.ChangeStatus = this.callAPIConstants.RoleChangeStatus;
        this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'adminAttributeListing') {
        this.APIparameters.pageSize = 10;
        this.getMethodName = this.callAPIConstants.AdminAttributeListing;
        this.DeleteUser = this.callAPIConstants.DeleteAdminAttribute;
        this.ChangeStatus = this.callAPIConstants.AttributeChangeStatus;
      } else if (this.type === 'AdminVariantListing') {
        this.APIparameters.pageSize = 10;
        this.getMethodName = this.callAPIConstants.AdminVariantListing;
        this.DeleteUser = this.callAPIConstants.DeleteAdminVariant;
        this.ChangeStatus = this.callAPIConstants.VariantChangeStatus;
      }
      else if (this.type === 'vendorFaq') {
        this.getMethodName = this.callAPIConstants.VendorFaqsListing;
        this.APIparameters.category = 'Vendor';
      } else if (this.type === 'faqsListing') {
        this.getMethodName = this.callAPIConstants.FaqsListing;
        this.ChangeStatus = this.callAPIConstants.FaqsStatusChange;
        this.DeleteUser = this.callAPIConstants.DeleteFaqs;
      } else if (this.type === 'faqsCategoryListing') {
        this.getMethodName = this.callAPIConstants.FaqsCategoryListing;
        this.ChangeStatus = this.callAPIConstants.FaqsCategoryStatus;
        this.DeleteUser = this.callAPIConstants.FaqsCategoryDelete;
      } else if (this.type === 'StaticPageList') {
        this.getMethodName = this.callAPIConstants.Listing;
        this.ChangeStatus = this.callAPIConstants.ChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteBlog;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.BlogDetails = this.callAPIConstants.GetBlogDetails;
      } else if (this.type === 'WastedMilesList') {
        this.getMethodName = this.callAPIConstants.WastedMilesList;
      } else if (this.type === 'notificationList') {
        this.getMethodName = this.callAPIConstants.getNotifications;
      } else if (this.type === 'productlist') {
        this.getMethodName = this.callAPIConstants.productList;
        this.APIparameters.searchQuery = this.searchText;
        this.ChangeStatus = this.callAPIConstants.Product_Status;
        this.DeleteUser = this.callAPIConstants.Delete_Product;
      } else if (this.type === 'coupanlist') {
        this.getMethodName = this.callAPIConstants.CoupanList;
        this.ChangeStatus = this.callAPIConstants.CoupanChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteCoupan;
      } 
      // else if (this.type === 'attributeList') {
      //   this.getMethodName = this.callAPIConstants.getAttribute;
      //   this.ChangeStatus = this.callAPIConstants.changeAttributeStatus;
      // }
       else if (this.type === 'categoryList') {
        this.getMethodName = this.callAPIConstants.categoryList;
        this.DeleteUser = this.callAPIConstants.DeleteCategory;
        this.ChangeStatus = this.callAPIConstants.changeCategorystatus;
      } else if (this.type === 'attributeList') {
        this.getMethodName = this.callAPIConstants.getAttribute;
        this.ChangeStatus = this.callAPIConstants.changeAttributeStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteAttribute;
      } else if (this.type === 'vendorlist') {
        this.getMethodName = this.callAPIConstants.vendorTicketList;
      } else if (this.type === 'adminTicketlist') {
        this.getMethodName = this.callAPIConstants.adminTicketList;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'orderlist') {
        this.getMethodName = this.callAPIConstants.Orderlist;
        this.ChangeStatus = this.callAPIConstants.OrderStatus;
      } else if (this.type === 'reviewlist') {
        this.APIparameters._id = this.VendorId;
        this.getMethodName = this.callAPIConstants.Reviewlist;
      } else if (this.type === 'adminorderlist') {
        this.downloadUserFile = this.callAPIConstants.DownloadOrders;
        this.APIparameters.isOngoingOrder = true;
        this.getMethodName = this.callAPIConstants.AdminOrderlist;
      } else if (this.type === 'userDetailAdminorderlist') {
        this.APIparameters.userIds = this.paramsId;
        this.getMethodName = this.callAPIConstants.UserDetailsAdminOrderlist;
      } else if (this.type === 'payoutVendorList') {
        this.downloadUserFile = this.callAPIConstants.DownloadPayout;
        this.APIparameters.role = 'vendor';
        this.getMethodName = this.callAPIConstants.PayoutVendorDriver;
      } else if (this.type === 'payoutDriverList') {
        this.downloadUserFile = this.callAPIConstants.DownloadPayout;
        this.APIparameters.role = 'driver';
        this.getMethodName = this.callAPIConstants.PayoutVendorDriver;
      }
      else if (this.type === 'walkinCustomersView') {
        this.APIparameters.vendorUserId = this.paramsId;
        this.getMethodName = this.callAPIConstants.walkinCustomersView;
      }
      else if (this.type === 'walkinCustomers') {
        this.getMethodName = this.callAPIConstants.walkinListing;
      }
    }
    if (this.getMethodName) {
      await this.commonService.callApi(this.getMethodName, this.APIparameters, 'post', false, false, false).then((success) => {
        if (success.status === 1) {

          this.showSkeletonLoader1 = false;
          this.tableData.data = [];
          if (success.data.listing !== undefined) {
            this.tableData.data = success.data.listing;
            this.total = success.total;
          } else if (success.data.earningData !== undefined) {

            this.tableData.data = success.data.earningData;
            this.total = success.meta.total;

          } else if (success.meta !== undefined) {
            this.tableData.data = success.data;
            this.total = success.meta.total;
          } else {
            this.tableData.data = success.data;
            this.total = success.total;
          }
          this.tempSettingsData = success.data.templateSettings;
          if (success.data.templateSettings && success.data.templateSettings.length) {

            var hash = this.colors.find(a => a.name == 'green').hex;
            document.getElementById('downloadDropdownColor').style.backgroundColor = hash;
          }

          if (success.data.latestColumns && success.data.latestColumns.length) {
            this.colSettingCols = [];
            let arr = [];
            success.data.latestColumns.forEach((e, lindex) => {
              if (e.drag) {
                this.colSettingCols.push(e);
              }
              if (e.filter) {
                this.filterCols.push(e);
              }
              this.tableSetupData.cols.forEach((c, colIndex) => {
                if (c.colName === e.colName) {
                  arr = this.arraymove(this.tableSetupData.cols, colIndex, lindex);
                } else if (c.colName === 'Select') {
                  this.tableSetupData.cols.splice(colIndex, 1);
                }
              });
            });
            this.tableSetupData.cols = arr;
            setTimeout(() => {
              this.tableConfig.cols = [];
              this.tableSetupData.cols.unshift({ type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true },);
              this.tableConfig.cols = this.tableSetupData.cols;
              this.colSettingCols = this.colSettingCols;
              this.tableData.data = success.data;
              this.tableConfig.cols.forEach(async (element) => {
                if (success.data.columnSettings && this.type !== 'adminUserlist1') {
                  success.data.columnSettings.forEach((dataApi) => {
                    if (element.colFieldname === dataApi.key) {
                      element.isVisible = dataApi.status;
                    }
                  });
                }
                else {
                  if (this.columns && this.columns.length) {
                    this.columns.forEach((dataApi) => {
                      if (element.colFieldname === dataApi.key) {
                        element.isVisible = dataApi.status;
                      }
                    });
                  }
                }
              });
              const index = this.tableConfig.cols.findIndex((o) => o.type === 'switch');
              if (this.tableConfig.cols[index]) {
                this.tableConfig.cols[index].isVisible = false;
                this.tableConfig.cols[index].filter = false;
                if (this.tableConfig.cols[index].columnVisibility) { this.tableConfig.cols[index].columnVisibility = false; }
              }
            }, 0);
          }
          else {
            this.getLatestData(success);
          }

          this.savedFilters = success.data.filterSettings;
        } else {
          this.showErrorService.popToast('error', 'Something Went Wrong!');
        }
      });
    }


  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Sorting
  @parameters : event
  @returns    : NA
  /*************************************************************/
  onSorted(event) {
    this.APIparameters.sort = {};
    event.sortDirection === 'desc' ? event.sortDirection = -1 : event.sortDirection = 1;
    const sortIndex = this.tableConfig.cols.findIndex((e) => e.colFieldname == event.sortColumn.colFieldname);
    this.tableConfig.cols[sortIndex].columnSorted = true;
    if (event.sortColumn.isObj) { this.APIparameters.sort[event.sortColumn.isObj] = event.sortDirection; } else {
      this.APIparameters.sort[event.sortColumn.colFieldname] = event.sortDirection;
    }
    if (event.sortColumn.isObj) {
      const fieldName = [event.sortColumn.colFieldname] + '.' + [event.sortColumn.isObj];
      this.APIparameters.sort = { [fieldName]: event.sortDirection }
    }
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
  @Purpose    : Pagination
  @parameters : event
  @returns    : NA
  /***************************************************************/
  pagination(event) {
    this.localStorageService.setToken('currentPage', event.page);
    this.localStorageService.setToken('itemsPerPage', event.itemsPerPage);
    this.deselectMultipleUser();
    this.APIparameters.page = event.page;
    this.itemsPerPage = event.itemsPerPage;
    this.currentPage = event.page;
    this.columnVisibilityOptions2 = false;
    this.selectedUserList = [];
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
  @Purpose    : Data Per page selection
  @parameters : event
  @returns    : NA
  /***************************************************************/
  selectItemPerPage(event) {
    this.customPagination._page = 1;
    this.APIparameters.pagesize = event;
    this.APIparameters.page = 1;
    this.columnVisibilityOptions2 = false;
    this.selectedUserList = [];
    this.callApiToGetData();
    this.deselectMultipleUser();
  }
  /***************************************************************/

  /*************************************************************
  @Purpose :  Rerendering Datatable
  @parameters : event
  @returns    : NA
  /*************************************************************/
  async rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
        // Destroy the table first
        await dtInstance.destroy();
        // Call the dtTrigger to rerender again
        await this.dtTrigger.next();
      });
    } else { await this.dtTrigger.next(); }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : open filter button
  @parameters : NA
  @returns    : NA
  /*************************************************************/
  openFilter() { this.status = !this.status; }
  /*************************************************************/

  /*************************************************************
  @Purpose    : open color dropdown
  @parameters : NA
  @returns    : NA
  /*************************************************************/
  openCloseColorDropdown() { this.statusColorPicker = !this.statusColorPicker; }
  /*************************************************************/

  /*************************************************************
  @Purpose    : add Obj For Filter Array
  @parameters : NA
  @returns    : NA
  /*************************************************************/
  addObjForFilterArray() {
    this.filteredArr.push({
      condition: this.condition
    });
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : input change
  @parameters : event
  @returns    : NA
  /*************************************************************/
  OnInput(event: any) {
    this.serverName = event.target.value;
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : array move
  @parameters : arr, fromIndex, toIndex
  @returns    : arr
  /*************************************************************/
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : get latest data in list
  @parameters : tableColumnSettings
  @returns    : NA
  /*************************************************************/
  getLatestData(success) {
    this.tableConfig = this.tableSetupData;
    this.configs = this.tableSetupData;
    this.cols = [];
    this.filterCols = [];
    this.tableConfig.cols.forEach(element => {
      if (element.drag) {
        this.cols.push(element);
      }
      if (element.filter) {
        this.filterCols.push(element);
      }
    });

    this.tableConfig.cols.forEach(async (element) => {
      if (success.data.columnSettings && this.type !== 'adminUserlist1') {
        success.data.columnSettings.forEach((dataApi) => {
          if (element.colFieldname === dataApi.key) {
            element.isVisible = dataApi.status;
          }
        });
      }
      else {
        if (this.columns && this.columns.length) {
          this.columns.forEach((dataApi) => {
            if (element.colFieldname === dataApi.key) {
              element.isVisible = dataApi.status;
            }
          });
        }

      }
    });
    //if (!this.accessPermission.statusUpdate) {
    // const index = this.tableConfig.cols.findIndex((o) => o.type === 'switch');
    // if (this.tableConfig.cols[index]) {
    //   this.tableConfig.cols[index].isVisible = false;
    //   this.tableConfig.cols[index].filter = false;
    //   if (this.tableConfig.cols[index].columnVisibility) { this.tableConfig.cols[index].columnVisibility = false; }
    // }
    //}
    //this.tableData.data = success.data.listing;
    this.rerenderDataTable();
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : select Columns positions
  @parameters : tableColumnSettings
  @returns    : NA
  /*************************************************************/
  saveColumnSettings(tableColumnSettings) {
    this.CallAPIchangeColumnVisibility(tableColumnSettings);
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : select Columns for download
  @parameters : tableConfigCols
  @returns    : NA
  /*************************************************************/
  selectColumns(tableConfigCols) {
    this.count = tableConfigCols.some(ele => {
      if (ele.isSelected) {
        return true;
      }
    });
    if (!this.count) {
      delete this.options.templateName;
    }
    else {
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : toggle Download
  @parameters : NA
  @returns    : NA
  /*************************************************************/
  toggleDownload() {
    this.toggleDownloadButton = !this.toggleDownloadButton;
    this.downloadColumnsArr = [];
    this.tableConfig.cols.forEach(ele => {
      ele.isSelected = false;
    });
    delete this.options.templateName;
    this.count = false;
    if (this.toggleDownloadButton) {
      this.CallAPIgetDownloadTemplates();
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : save Download Template
  @parameters : tableConfigCols
  @returns    : NA
  /*************************************************************/
  saveDownloadTemplate(tableConfigCols) {
    this.submitted = true;
    this.downloadColumnsArr = [];
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.downloadColumnsArr.push({ key: element.colFieldname, status: element.isSelected });
      }
    });
    const data: any = { columns: this.downloadColumnsArr, color: this.color, description: this.options.templateName };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
    }
    if (this.options._id) {
      data.templateId = this.options._id;
    }
    if (this.options.templateName) {
      this.commonService.callApi(this.downloadColumns, data, 'post').then((success) => {
        if (success.status === 1) {
          this.CallAPIgetDownloadTemplates();
          this.toggleDownload();
          this.showErrorService.popToast('success', 'template saved successfully');
          this.callApiToGetData();
        }
        else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
    else {
      this.showErrorService.popToast('error', 'please enter template Name');
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Call API delete Download Template
  @parameters : i
  @returns    : NA
  /*************************************************************/
  CallAPIdeleteDownloadTemplate(i) {
    this.showErrorService.confirmpopToast('warning', 'Are you sure,You Want To Delete ?').then((result) => {
      if (result.value === true) {
        this.commonService.callApi(this.deleteDownloadTemplate + i._id, '', 'delete', false, false).then((success) => {
          if (success.status === 1) {

            this.showErrorService.popToast('success', success.message);
            this.callApiToGetData();
            this.CallAPIgetDownloadTemplates();
            this.toggleDownload();
          } else { this.showErrorService.popToast('error', success.message); }
        });
      }

    });
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : download Template
  @parameters : i
  @returns    : NA
  /*************************************************************/
  downloadTemplate(key) {
    let templateData: any = { filteredFields: [] };
    key == 'print' ? templateData.type = 'pdf' : templateData.type = key;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility) {
        if (element.isSelected) {
          templateData.filteredFields.push(element.colFieldname);
        }
      }
    });
    if (this.type == 'payoutVendorList') {
      templateData['searchText'] = 'vendor';
    }
    if (this.type == 'payoutDriverList') {
      templateData['searchText'] = 'user';
    }
    this.commonService.callApi(this.downloadUserFile, templateData, 'post').then(res => {
      if (res.status == 1) {
        if (key == 'print') {
          window.open(this.downloadUrl + 'pdf/' + res.data.filePathAndName, '_blank');
        }
        else {
          this.link = this.downloadUrl + key + '/' + res.data.filePathAndName;
          fetch(this.link).then(res1 => res1.blob()) // Gets the response and returns it as a blob
            .then((blob: any) => {
              var link: any = document.createElement('a');
              let objectURL = URL.createObjectURL(blob);
              link.href = objectURL;
              link.target = '_blank';
              link.download = res.data.filePathAndName;
              link.click();
              link.remove();
            });
        }
      }
      else {
        this.showErrorService.popToast('errror', res.message);
      }
    });
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : edit Template
  @parameters : i
  @returns    : NA
  /*************************************************************/
  editTemplate(i) {
    this.options = i;
    this.options.templateName = i.description;
    this.color = i.color;
    var hash = this.colors.find(a => a.name == i.color).hex;
    document.getElementById('downloadDropdownColor').style.backgroundColor = hash;
    this.count = true;
    this.tableConfig.cols.forEach(ele => {
      i.columns.forEach(ele1 => {
        if (ele1.status && ele1.key == ele.colFieldname) {
          ele.isSelected = true;
        }
        else {
          ele.isSelected = false;
        }
      });

    });

  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : remove Obj From Filter Array
  @parameters : index
  @returns    : NA
  /*************************************************************/
  removeObjFromFilterArray(index) {
    this.filteredArr.splice(index, 1);
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : filter Condition Change (And/Or)
  @parameters : key
  @returns    : NA
  /*************************************************************/
  filterConditionChange(key) {
    this.condition = key;
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : apply Filter / apply Saved Filter
  @parameters : form?, item?
  @returns    : NA
  /*************************************************************/
  applySavedFilter(form?, item?) {
    this.submitted = true;
    if (form) {
      this.APIparameters = {
        page: 1,
        pagesize: 10,
        columnKey: this.APIparameters.columnKey,
        filter: this.filteredArr,
        //condition: this.condition
      };
      this.clearFilter();
      // this.callApiToGetData();
    } else {
      this.APIparameters = {
        page: 1,
        pagesize: 10,
        columnKey: this.APIparameters.columnKey,
        filter: item.filter,
        condition: item.condition
      };
      this.clearFilter();
      this.callApiToGetData();
    }
    this.openFilter();
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : select multiple users
  @parameters : data
  @returns    : NA
  /*************************************************************/
  selectMultipleUser(data) {
    if (data.checkbox) {
      if (this.selectedUserList.length) {
        const x = this.selectedUserList.findIndex((o) => o === data._id);
        if (x === -1) {
          this.selectedUserList.push(data._id);
        }
      } else {
        this.selectedUserList.push(data._id);
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.checkbox = false;
      });
      const x = this.selectedUserList.findIndex((o) => o === data._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Call API to save change on Column poistion
  @parameters : tableConfigCols
  @returns    : NA
  /*************************************************************/
  CallAPIchangeColumnVisibility(tableConfigCols) {
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.columnVisibleArr.push({ key: element.colFieldname, status: element.isVisible });
      }
    });
    const data: any = { columns: this.columnVisibleArr };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
      data.latestColumns = this.colSettingCols;
    }
    this.commonService.callApi(this.colVisible, data, 'post', false, false).then((success) => {
      success.status === 1 ?
        this.showErrorService.popToast('success', 'Updated Successfully') : this.showErrorService.popToast('error', success.message);
      this.callApiToGetData();
    });
    this.columnVisibleArr = [];
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : Api for change Status and Delete
  @parameters : callAPi ==> name of Api to be called
                data ==> data to be send
  @returns    : NA
  /*************************************************************/
  CallAPIchangeStatusORDelete(callAPI, data) {
    if (this.type === 'mealdealist') {
      this.commonService.callApi(callAPIConstants.mealdealStatus, data,
        'post', false, false, false).then((success) => {
          if (success.status === 1) {
            this.showErrorService.popToast('success', success.message);
            setTimeout(() => {
              this.callApiToGetData();
            });
          }
        });
      this.selectedUserList = [];
    }
    // else if (this.type == 'cuisineList') {
    //   this.commonService.callApi(callAPI + data.cuisineId[0], data,
    //     'delete', false, false, false).then((success) => {
    //       if (success.status === 1) {
    //         this.showErrorService.popToast('success', success.message);
    //         setTimeout(() => {
    //           this.callApiToGetData();
    //         });

    //         this.selectedUserList = [];
    //       } else {
    //         this.showErrorService.popToast('error', 'Something Went Wrong!');
    //       }
    //     });
    // } 
    else {

      this.commonService.callApi(callAPI, data, 'post', false, false, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          setTimeout(() => {
            this.callApiToGetData();
          });
          this.tableConfig.cols.forEach((element) => {
            element.checkbox = false;
          });
          this.selectedUserList = [];
        } else {
          this.showErrorService.popToast('error', 'Something Went Wrong!');
        }
      });
    }
  }
  // *************************************************************//

  /*************************************************************
  @Purpose    : Perform Action: VIEW, EDIT, DELETE And SWITCH
  @parameters : k, i
  @returns    : NA
  /**************************************************************/
  performAction(k, i) {
    // VIEW action
    if (k.id === 1) {      
      if (this.type === 'userlist') {
        this.router.navigate([this.URLConstants.USERS_DETAIL, i._id]);
      } else if (this.type === 'StaticPageList') {
        this.router.navigate([this.URLConstants.VIEW_STATIC_DETAILS, i._id]);
      } else if (this.type === 'cuisineList') {
        this.router.navigate([this.URLConstants.CUISINE_DETAILS, i._id]);
      } else if (this.type === 'driverListing') {
        this.router.navigate([this.URLConstants.DRIVERS, i._id]);
      } else if (this.type === 'cmsListing') {
        this.router.navigate([this.URLConstants.CMS_DETAIL, i._id]);
      } else if (this.type === 'categoryList') {
        this.router.navigate([this.URLConstants.CATEGORY_DETAILS, i._id]);
      } else if (this.type === 'productlist') {
        this.router.navigate([this.URLConstants.VIEW_PRODUCT, i._id]);
      } else if (this.type === 'mealdealist') {
        this.router.navigate([this.URLConstants.DETAIL_MEAL_DEALS, i._id]);
      } else if (this.type === 'coupanlist') {
        this.router.navigate([this.URLConstants.ViEW_COUPAN, i._id]);
      } else if (this.type === 'attributeList') {
        this.router.navigate([this.URLConstants.ATTRIBUTE_DETAILS, i._id]);
      } else if (this.type === 'orderlist') {
        this.router.navigate([this.URLConstants.ORDER_VIEW, i._id]);
      } else if (this.type === 'adminorderlist' || this.type === 'userDetailAdminorderlist') {
        this.router.navigate([this.URLConstants.ADMIN_ORDER_VIEW, i._id]);
      } else if (this.type === 'walkinCustomers') {
        this.router.navigate([this.URLConstants.WALKIN_CUSTOMER_VIEW, i._id]);
      } 

    } else if (k.id === 2) {
      // Edit Action
      if (this.type === 'mealdealist') {
        this.router.navigate([this.URLConstants.EDIT_MEAL_DEALS, i._id]);
      } else if (this.type === 'productlist') {
        this.router.navigate([this.URLConstants.EDIT_PRODUCT, i._id]);
      } else if (this.type === 'cuisineList') {
        this.router.navigate([this.URLConstants.ADD_UPDATE_CUISINE, i._id]);
      } else if (this.type === 'faqsCategoryListing') {
        this.router.navigate([this.URLConstants.ADD_FAQSCATEGORY, i._id]);
      } else if (this.type === 'faqsListing') {
        this.router.navigate([this.URLConstants.UPDATE_FAQ, i._id]);
      } else if (this.type === 'adminUserlist') {
        //this.router.navigate([this.URLConstants.ADMINUSER_DETAIL, i._id]);
      } else if (this.type === 'emailTemplateList') {
        this.router.navigate([this.URLConstants.EMAIL_TEMPLATE_detail, i._id]);
      } else if (this.type === 'coupanlist') {
        this.router.navigate([this.URLConstants.EDIT_COUPAN, i._id]);
      }
      else if (this.type === 'cmsListing') {
        this.router.navigate([this.URLConstants.CMS_EDIT, i._id]);
      } else if (this.type === 'roleList') {
        this.router.navigate([this.URLConstants.EDIT_ROLE, i._id]);
      } else if (this.type === 'userlist') {
        this.router.navigate([this.URLConstants.ADDUPDATEUSER, i._id, 'edit']);
      }
      else if (this.type === 'adminTicketlist') {
        if (i.status === 'open') { this.router.navigate([this.URLConstants.ADMIN_ANSWER_TICKET, i._id]); }
      }
      else if (this.type === 'StaticPageList') {
        this.router.navigate([this.URLConstants.EDIT_STATIC_PAGE, i._id]);
      }
      else if (this.type === 'categoryList') {
        this.router.navigate([this.URLConstants.CATEGORY_EDIT, i._id]);

      } else if (this.type === 'attributeList') {
        this.router.navigate([this.URLConstants.ATTRIBUTE_EDIT, i._id]);
      }
      else if (this.type === 'AdminVariantListing') {
        this.router.navigate([this.URLConstants.UpdateVariant, i._id]);
      }
      else if (this.type === 'adminAttributeListing') {
        this.router.navigate([this.URLConstants.UpdateAttribute, i._id]);
      }
    } else if (k.id === 3) {
      // Delete Action
      if(this.type === 'productlist'){
        this.showErrorService
        .confirmpopToast('warning', 'Do you want to delete?')
        .then((result) => {
          if (result.value === true) {
            const dataObj = {
              [this.params.deleteParams]: i._id,
            };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
          }
        });
      }
      else{
      this.showErrorService
        .confirmpopToast('warning', 'Do you want to delete?')
        .then((result) => {
          if (result.value === true) {
            const dataObj = {
              [this.params.deleteParams]: [i._id],
            };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
          }
        });
      }

    } else if (k.id === 4) {
      this.openDeleteCuisneModal('edit', i);
    }
    else if (k.id === 5) {
      this.openAssignModal('edit', i);
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : changes on switch type in data table(change status)
  @parameters : d, e?
  @returns    : NA
  /**************************************************************/
  changeStatus(d, e?) {
    let dataObj: any;
    if (this.type === 'productlist') {
      dataObj = {
        [this.params.statusParams]: d._id,
        status: !d.status,
      };
    } else if (this.type === 'orderlist') {
      dataObj = {
        [this.params.statusParams]: d._id,
        orderStatus: e,
      };
    } else {
      dataObj = {
        [this.params.statusParams]: [d._id],
        status: !d.status,
      };
    }
    this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : open Delete Cuisne Modal
  @parameters : e, i
  @returns    : NA
  /**************************************************************/
  openDeleteCuisneModal(e, i) {
    if (e === 'edit') {
      const Data = Object.assign({}, i);
      this.bsModalRef = this.modalService.show(CusineDelModalComponent, {
        initialState: {
          title: 'Edit',
          data: Data,
          type: this.type
        },
        class: 'modal-dialog-centered'
      });
    }
    else {
      this.bsModalRef = this.modalService.show(CusineDelModalComponent, {
        initialState: {
          title: 'Add',
          data: {},
          type: this.type

        },
        class: 'modal-dialog-centered'
      });
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : open Assign Modal
  @parameters : e, i
  @returns    : NA
  /**************************************************************/
  openAssignModal(e, i) {
    if (e === 'edit') {
      const Data = Object.assign({}, i);
      this.bsModalRef = this.modalService.show(AssignModalComponent, {
        initialState: {
          title: 'Edit',
          data: Data,
          type: this.type
        },
        class: 'modal-dialog-centered'
      });
    }
    else {
      this.bsModalRef = this.modalService.show(AssignModalComponent, {
        initialState: {
          title: 'Add',
          data: {},
          type: this.type

        },
        class: 'modal-dialog-centered'
      });
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : open modal for header button
  @parameters : template
  @returns    : NA
  /**************************************************************/
  openModalForHeaderButton(template?) {
    if (this.type === 'withDrawList') {
      this.withdrawlData.amount = '';
      this.submitted = false;
      this.bsModalRef = this.modalService.show(template, {
        animated: true,
        backdrop: 'static',
        class: 'modal-dialog-centered'
      });
    }
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : column position changing through cdk
  @parameters : event
  @returns    : NA
  /**************************************************************/
  cdkDropListDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.colSettingCols, event.previousIndex, event.currentIndex);
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Select all the user
  @parameters : i
  @returns    : NA
  /**************************************************************/
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.tableData.data.forEach((element) => {
        element.checkbox = true;
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o) => o === element._id);
          if (x === -1) {
            this.selectedUserList.push(element._id);
          }
        } else {
          this.selectedUserList.push(element._id);
        }
      });
    } else {
      this.deselectMultipleUser();
    }
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : Deselect all selected users
  @parameters : NA
  @returns    : NA
  /**************************************************************/
  deselectMultipleUser() {
    this.tableConfig.cols.forEach((element) => {
      element.checkbox = false;
    });
    this.tableData.data.forEach((element) => {
      element.checkbox = false;
      const x = this.selectedUserList.findIndex((o) => o === element._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Api call from download template
  @parameters : NA
  @returns    : NA
  /**************************************************************/
  CallAPIgetDownloadTemplates() {
    if (this.getDowloadTemplates) {
      this.commonService.callApi(`${this.getDowloadTemplates}/${this.tableSetupData.saveColumnKey}`, '', 'get', false, false)
        .then((success) => {
          if (success.status === 1) {
            this.downloadTemplatesArr = success.data;
          }
        });
    }
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : Delete saved filters
  @parameters : i
  @returns    : NA
  /**************************************************************/
  CallAPIdeleteSaveFilter(i) {
    this.disableResetFilter = true;
    this.commonService.callApi(this.deleteFilter + i._id, '', 'delete', false, false).then((success) => {
      if (success.status && success.status === 1) {
        this.showErrorService.popToast('success', success.message);
      } else {
        this.showErrorService.popToast('error', success.message);
      }
      this.callApiToGetData();
    });
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : Search
  @parameters : event
  @returns    : NA
  /**************************************************************/
  searchData(event) {
    if (event.target.value) {
      this.APIparameters.page = 1;
      if (event.target && event.target.value) {
        this.APIparameters.searchText = event.target.value;
      } else {
        delete this.APIparameters.searchText;
      }
      this.callApiToGetData();
    }
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : Filter Date Range Selection
  @parameters : e, index
  @returns    : NA
  /**************************************************************/
  filterDateRangeSelection(e, index) {
    if (e) {
      const value = {
        startDate: e[0].toLocaleDateString(),
        endDate: e[1].toLocaleDateString()
      };
      this.filteredArr[index].value = value;
    }
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : Clear Filter
  @parameters : allClear?
  @returns    : NA
  /**************************************************************/
  clearFilter(allClear?) {
    this.filteredArr = [];
    this.addObjForFilterArray();
    this.filterColor = '#4184EF';
    if (allClear) { this.APIparameters = { page: 1, pagesize: 10, columnKey: this.columnKey }; }
    this.callApiToGetData();
    this.filterForm.reset();
    this.options.description1 = '';
    this.submitted = false;
    this.filterSaveSubmitted = false;
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : filter Contains List Changes the value
  @parameters : item
  @returns    : NA
  /**************************************************************/
  filterContentListChanges(item) {
    delete (item.selectedDate);
    delete (item.value);
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : Save filters
  @parameters : form, filterName, color
  @returns    : NA
  /**************************************************************/
  toSaveFilter(form, filterName, color) {
    this.filterSaveSubmitted = true;
    this.submitted = true;
    if (form && form.valid) {
      this.APIparameters = {
        key: this.APIparameters.columnKey,
        condition: this.condition,
        description: filterName,
        color: color,
        filter: this.filteredArr,
        filterId: this.filterId
      };
      this.commonService.callApi(this.saveFilter, this.APIparameters, 'post').then((success) => {
        if (success.status && success.status === 1) {
          this.APIparameters = {
            page: 1,
            pagesize: 10,
            columnKey: this.APIparameters.columnKey,
            filter: this.filteredArr,
            condition: this.condition
          };
          this.showErrorService.popToast('success', 'Filter saved successfully');
          this.callApiToGetData();
          this.clearFilter();
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : Edit saved filters
  @parameters : e
  @returns    : NA
  /**************************************************************/
  editSavedFilter(e) {
    this.filteredArr = e.filter;
    this.options.description1 = e.description;
    this.filterColor = e.color;
    this.condition = e.condition;
    this.filterId = e._id;
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : remove search from list
  @parameters : NA
  @returns    : NA
  /**************************************************************/
  removeSearch() {
    delete this.searchText;
    delete this.APIparameters.searchText;
    this.callApiToGetData();
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : Earning Report and withdraw search filter
  @parameters : form, formsdata
  @returns    : NA
  /**************************************************************/
  submits(form, formsdata) {
    this.APIparameters = [];
    this.EarningList.filterStatus = formsdata.filterStatus;
    this.EarningList.page = 1;
    this.EarningList.fromDate = formsdata.fromDate;
    this.EarningList.toDate = formsdata.toDate;
    this.APIparameters = this.EarningLists;
    this.callApiToGetData();
  }
  /**************************************************************/

  /*************************************************************
  @Purpose    : Request for withdrawal amount
  @parameters : NA
  @returns    : NA
  /*************************************************************/
  requestWithdrawl() {
    this.submitted = true;
    if (this.withdrawlData.amount) {
      this.withdrawlData.amount = Number(this.withdrawlData.amount);
      this.commonService.callApi(this.callAPIConstants.SendWithdrawalRequest, this.withdrawlData, 'post', false, true, false)
        .then((success) => {
          if (success.status === 1) {
            this.showErrorService.popToast('success', success.message);
            this.bsModalRef.hide();
            this.callApiToGetData();
            this.withdrawlData.amount = '';
          }
        }, (error) => {
          if (error) {
            this.showError = true;
            this.errortext = error.error.error.message;
          }
        });
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : withdrwal input Change Detection
  @parameters : NA
  @returns    : NA
  /*************************************************************/
  withdrawlChangeDetection() { this.showError = false; }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Status colors for different types
  @parameters : status
  @returns    : class
  /*************************************************************/
  getColor(status) {
    switch (status) {
      case 'approve':
        return 'badge badge-pill badge-light-success ';
      case 'delivered':
        return 'badge badge-pill badge-light-success ';
      case 'ongoing':
        return 'badge badge-pill badge-light-dark';
      case 'reject':
        return 'badge badge-pill badge-light-dark';
      case 'canceledByVendor':
        return 'badge badge-pill badge-light-danger';
      case 'canceledByCustomer':
        return 'badge badge-pill badge-light-danger';
      case 'pending':
        return 'badge badge-pill badge-light-danger';
      case 'prepared':
        return 'badge badge-pill badge-light-warning';
      case 'picked':
        return 'badge badge-pill badge-light-info';
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Approve / Reject Orders/Withdrawal requests
  @parameters : clickedType(accept/reject), id
  @returns    : NA
  /*************************************************************/
  acceptRejectOrder(clickedType, id) {
    let params;
    let getMethod;
    if (this.type === 'orderlist') {
      params = { orderId: id, orderStatus: (clickedType === 'accept') ? 'accepted' : 'reject' };
      getMethod = this.callAPIConstants.CHANGE_ORDER_STATUS;
    }
    if (this.type === 'payoutDriverList' || this.type === 'payoutVendorList') {
      params = { _id: id, isAccept: (clickedType === 'accept') ? true : false };
      getMethod = this.callAPIConstants.Payout_Vendor_Driver_Withdrawal_Request;
    }
    this.commonService.callApi(getMethod, params, 'post', false, true, false).then((success) => {
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        this.callApiToGetData();
      }
    });
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : Api for change Status and in bulk
  @parameters : type (delete/status)
  @returns    : NA
  /*************************************************************/
  bulkChangeStatusAndDelete(type) {
    if (type === 'delete') {
      if (this.selectedUserList.length) {
        this.showErrorService.confirmpopToast('warning', 'Do you want to delete?').then((result) => {
          if (result.value === true) {
            const data = { [this.params.deleteParams]: this.selectedUserList };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
          } else {
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
            this.tableConfig.cols[index].checkbox = false;
            this.deselectMultipleUser();
          }
        });
      }
    }
    if (type === 'status') {
      if (this.selectedUserList.length) {
        const data = { [this.params.statusParams]: this.selectedUserList, status: false };
        this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
      }
    }
  }
  /*************************************************************/


  /*************************************************************
  @Purpose    : search On Selection Of Faq Category
  @parameters : event
  @returns    : NA
  /*************************************************************/
  searchOnSelectionOfFaqCategory(event) {
    if (event) {
      this.APIparameters.category = event;
      this.callApiToGetData();
    } else {
      this.APIparameters.filter = null;
      this.APIparameters.category = null;
      this.callApiToGetData();
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : select color from dropdown for filter and download
  @parameters : colorCode, colorName
  @returns    : NA
  /*************************************************************/
  selectColor(colorCode, colorName) {
    this.color = colorName;
    document.getElementById('downloadDropdownColor').style.backgroundColor = colorCode;
    this.openCloseColorDropdown();
  }
  /*************************************************************/

  /*************************************************************
  @Purpose    : search On Basis Role Selected
  @parameters : event
  @returns    : NA
  /*************************************************************/
  searchOnRoleSelected(event) {
    if (event) {
      this.APIparameters.filter = [];
      const filter = {
        key: 'role',
        value: event.value
      };
      this.APIparameters.filter.push(filter);
      this.callApiToGetData();
    } else {
      this.APIparameters.filter = null;
      this.callApiToGetData();
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Load TypeAhead Initially for vendor for order list
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  private userLoadTypehead() {
    this.userFilterlist = concat(
      of([]),
      this.userTypeAheadSource.pipe(
        debounceTime(1000),
        // merge(this.focus$),
        distinctUntilChanged(),
        switchMap((term) => this.getUserListOnSearch(term)),
        map((response) => {
          return response;
        }),
      ),
    );
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : Get List of user on Search for order list
  @Parameter   : value
  @Return      : items
  /*************************************************************/
  getUserListOnSearch(value: string = null): Observable<any> {
    this.userLoader = true;
    const dataParams = {
      columnKey: 'userListing',
      filter: [{ key: 'role', value: 'user' }],
      page: 1,
      pagesize: 10000,
      searchText: value
    };
    let items = [];
    return this.commonService.callApiObservable(this.callAPIConstants.UserList, dataParams).pipe(
      catchError(() => of(({ items: [] }))),
      map((success) => {
        if (success['status'] == 1) {
          if (value) {
            items = success['data']['listing'];
          } else { items = []; }
          this.userLoader = false;
        }
        return (items) ? items : [];
      }),
    );
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : on Select User On Search for order list
  @Parameter   : $event
  @Return      : NA
  /*************************************************************/
  onSelectUserOnSearch(event) {
    if (event) {
      this.APIparameters.userId = event._id;
    } else {
      delete this.APIparameters.userId;
    }
    this.callApiToGetData();
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : Load TypeAhead Initially for vendor search for order list
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  private vendorLoadTypehead() {
    this.vendorFilterlist = concat(
      of([]),
      this.vendorTypeAheadSource.pipe(
        debounceTime(1000),
        // merge(this.focus$),
        distinctUntilChanged(),
        switchMap((term) => this.getvendorListOnSearch(term)),
        map((response) => {
          return response;
        }),
      ),
    );
  }
  /***************************************************************/

  /***************************************************************
    @Purpose : Resend order details email
  /***************************************************************/
  resendEmail(resendEmailInfo){
    this.commonService.callApi(this.callAPIConstants.resendEmail + resendEmailInfo._id ,'','get',false,false,false).then((success) => {
      if(success.status === 1){
        this.showErrorService.popToast('success', success.message);
      }
    })
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : Get List of vendor on Search for order list
  @Parameter   : value
  @Return      : items
  /*************************************************************/
  getvendorListOnSearch(value: string = null): Observable<any> {
    this.vendorLoader = true;
    const dataParams = {
      columnKey: 'userListing',
      filter: [{ key: 'role', value: 'vendor' }],
      page: 1,
      pagesize: 10000,
      searchText: value
    };
    let items = [];
    return this.commonService.callApiObservable(this.callAPIConstants.UserList, dataParams).pipe(
      catchError(() => of(({ items: [] }))),
      map((success) => {
        if (success['status'] == 1) {
          if (value) {
            items = success['data']['listing'];
          } else { items = []; }
          this.vendorLoader = false;
        }
        return (items) ? items : [];
      }),
    );
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : on Select vendor On Search for order list
  @Parameter   : $event
  @Return      : NA
  /*************************************************************/
  onSelectvendorOnSearch(event) {
    if (event) {
      this.APIparameters.vendorId = event._id;
    } else {
      delete this.APIparameters.vendorId;
    }
    this.callApiToGetData();
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : on Select vendor On Search for order list
  @Parameter   : $event
  @Return      : NA
  /*************************************************************/
  onSelectStatusInOrderListing(event) {
    if (event) {
      this.APIparameters.filter = {};
      this.APIparameters.filter.status = event.value;
    } else {
      delete this.APIparameters.filter.status;
    }
    this.callApiToGetData();
  }
  /*************************************************************/
}
