<div class="container m-5 p-5">
  <div class="mt-5 p-5">
        <agm-map 
        [latitude]="lat"
        [longitude]="lng"
        [zoom]="zoom"
        [disableDefaultUI]="false"
        [zoomControl]="false"
        (mapClick)="mapClicked($event)">
      
        <agm-marker 
            *ngFor="let m of markers; let i = index"
            (markerClick)="clickedMarker(m.label, i)"
            [latitude]="m.lat"
            [longitude]="m.lng"
            [label]="m.label"
            [markerDraggable]="m.draggable"
            (dragEnd)="markerDragEnd(m, $event)">
            
          <agm-info-window>
            <strong>InfoWindow content</strong>
          </agm-info-window>
          
        </agm-marker>
        
        <agm-circle [latitude]="lat + 0.3" [longitude]="lng" 
            [radius]="5000"
            [fillColor]="'red'"
            [circleDraggable]="true"
            [editable]="true">
        </agm-circle>
      
      </agm-map>
    </div>
  </div>