<div class="main-content-area">
  <div class="main-content-block">

    <!-- filter bar buttons end -->
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="content-header-title">Withdrawals</li>
          <li class="breadcrumb-item">
            <a [routerLink]="[URLConstants.DASHBOARD]"><em class="bx bx-home-alt"></em></a>
          </li>
          <li class="breadcrumb-item" [routerLink]="[URLConstants.EARNING]">Earnings</li>
          <li class="breadcrumb-item active" aria-current="page">
            Withdrawals
          </li>
        </ol>
      </nav>
    </div>
    <div class="col-md-6 calender-blog withdrawal">
      <h5 class="total-heading mb-0"> Withdrawal balance : {{totalWithdrawal | currency: CUURENCY_CODE}}</h5>
    </div>

    <div class="m-content animated fadeIn">
      <!-- BEGIN: Responsive Data Table  -->
      <app-responsive-data-table [tableSetupData]="tableSetupData" #responsiveDataTable>
      </app-responsive-data-table>
      <!-- END: Responsive Data Table  -->
    </div>
  </div>
</div>